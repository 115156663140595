<template>
    <div class="row">
        <div class="col-lg-12">
            
            <div class="row">
                <div class="col-lg-8 ">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="card-title">
                                Welcome to message-deck developers dashboard
                            </h4>
                        </div>
                    </div>
                    <card type="primary"
                    class="card-primary h-95">
                        <template slot="header">
                            <h2 class="card-title">
                                
                                {{ 
                                    currentHour < 12 ? 'Good morning' : 
                                    currentHour >= 12 && currentHour < 17  ? 'Good afternoon' : 
                                    currentHour > 17 ? 'Good evening' : 'Good evening'
                                }}, {{user.user_first_name != "null" && user.user_first_name != null ? user.user_first_name : ""}}
                            </h2>
                        </template>
                        <br>
                        <template slot="default">
        
                            <p class="card-text">
                                Your dashboard is customized with resources, how-to's, and recommendations to <br>
                                help you get started with Message-deck's Dashboard.
                            </p> 
                            <div class="row h-25 d-flex align-items-end">
                                <div class="col-lg-7">
                                    <router-link class="btn btn-primary btn-simple btn-sm" 
                                    to="documentation" tag="button">  
                                        Explore our API documentation
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
                <div class="col-lg-4 hide-on-mobile">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="card-title">
                                Helpful tips
                            </h4>
                        </div>
                    </div>
                    <div class="row h-50" @mouseover="hoverOverTokens = true" @mouseleave="hoverOverTokens = false">
                        <div class="col-lg-12" >
                            <router-link 
                                to="settings/api-access-tokens">
                                <card type="primary"
                                    class="card-primary h-90">
                                    <h4 class="card-title " 
                                        :style="hoverOverTokens ? 'color:#FFFF00' : 'color:#b2b200'">
                                        <mdicon  name="lightbulb-on" size="18" />   
                                    </h4>
                                    <h4 class="card-title" :style="hoverOverTokens ? 'text-decoration: underline;' : ''">
                                        Create an API access token
                                    </h4>
                                
                                    <p class="card-text text-muted">
                                        With an access token you can start sending SMS from your servers.
                                    </p>
                                </card> 
                            </router-link>
                        </div>
                    </div>
                    <div class="row h-50" >
                        <div class="col-lg-12" @mouseover="hoverOverTeams = true"
                            @mouseleave="hoverOverTeams = false">
                            <router-link to="settings/users">
                                <card type="primary" class="card-primary h-90">
                                    <h4 class="card-title " :style="hoverOverTeams ? 'color:#FFFF00' : 'color:#b2b200'">
                                        <mdicon  name="lightbulb-on" size="18" />   
                                    </h4>
                                    <h4 class="card-title" :style="hoverOverTeams ? 'text-decoration: underline;' : ''">
                                        Invite team members.
                                    </h4>
                        
                                    <p class="card-text text-muted">
                                        You can add team members to your account.
                                    </p>
                                </card> 
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row mt-5 mobile-space">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="card-title">
                                Popular Dashboard Resources
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <card type="primary"
                                class="card-primary h-90">
                                <h4 class="card-title h-20">
                                    <mdicon name="wallet-outline" size="18" /> Billing  
                                </h4>
                    
                                <p class="card-text h-30">
                                    Balance, add funds, payment history
                                </p>

                                <div class="row h-50 d-flex align-items-end pb-2">
                                    <div class="col-lg-10">
                                        <router-link 
                                        class="btn btn-primary btn-simple btn-sm" to="billing/pricing" tag="a">
                                            Pricing
                                        </router-link>
                                        <router-link 
                                        class="btn btn-primary btn-simple btn-sm" to="billing/add-funds" tag="a">
                                            Add funds 
                                        </router-link>
                                    </div>
                                </div>
                            </card>
                        </div>
                        <div class="col-lg-4">
                            <card  type="primary"
                                    class="card-primary h-90">
                                <h4 class="card-title h-20">
                                    <mdicon name="send-check-outline" size="18" /> Delify  
                                </h4>
                    
                                <p class="card-text h-30">
                                    Delivery verify with over 1500 real devices.
                                    <badge type="success">
                                        <span slot="badgeContent">BETA</span>
                                    </badge>
                                </p>

                                <div class="row h-50 d-flex align-items-end pb-2">
                                    <div class="col-lg-12">
                                        <router-link 
                                        class="btn btn-primary btn-simple btn-sm" to="sms/delify" tag="a">
                                        Delify
                                        </router-link>
                                        
                                    </div>
                                </div>
                            
                            </card> 
                        </div>
                        <div class="col-lg-4">
                            <card 
                                type="primary"
                                class="card-primary h-90">
                                <h4 class="card-title h-20">
                                    <mdicon name="message-text-outline" size="18" /> SMS  
                                </h4>
            
                                <p class="card-text h-30">
                                    View analytics and usage.
                                </p>

                                <div class="row h-50 d-flex align-items-end pb-2">
                                    <div class="col-lg-10">
                                        <router-link class="btn btn-primary btn-simple btn-sm" to="sms/analytics" tag="a">                                    
                                            Analytics
                                        </router-link>
                                        <router-link class="btn btn-primary btn-simple btn-sm" to="billing/usage" tag="a">  
                                            Usage
                                        </router-link>
                                        <router-link class="btn btn-primary btn-simple btn-sm" to="campaigns/list" tag="a">  
                                            Campaigns
                                        </router-link>
                                    </div>
                                </div>
                                
                                
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div> 
</template>

<script>
import { Badge } from 'src/components'
export default {
    components: {
        Badge
    },
    props: ['user'],
    data() {
        return {
            hoverOverTokens: false,
            hoverOverTeams:false,
            currentHour: null
        }
    },
    mounted(){
        const date = new Date();
        this.currentHour = date.getHours();
    },
    methods: {
    }
}
</script>

<style>

</style>