<template>
  <div>
    <TimeLine type="simple" >

        <TimeLineItem
            inverted
            state-type="complete"
            badge-type="success"
            :badge-icon-size="32">

            <span slot="header" class="badge badge-success">
                Account created
            </span>
            <p slot="content">
                Congratulations!<br> You have created an account.
            </p>
            <h6 slot="footer">
                Account id: {{user.user_account_uuid}}
            </h6>
        </TimeLineItem>

        <TimeLineItem
            inverted
            :state-type="tokens ? 'complete': 'primary'"
            :badge-type="tokens ? 'success': 'info'"
            :badge-icon-size="32">

            <span slot="header" :class="`badge badge-${tokens ? 'success' : 'primary' }`">
                API access tokens
            </span>
            <p slot="content">
                <span v-if="tokens">
                    Great Job!<br> You are ready to send SMS.<br>
                </span>
                <span v-else>
                    
                    <router-link class="btn btn-primary btn-simple btn-sm" to="settings/api-access-tokens" tag="button">  
                        Create an API token
                    </router-link>
                </span>
                
            </p>
            <h6 slot="footer">
                <span v-if="tokens">
                    You have created an api token
                </span>
                <span v-else>
                    You have yet to create an API token.
                    
                </span>
            </h6>
        </TimeLineItem>


        <TimeLineItem
            inverted
            :state-type="activity ? 'complete': 'primary'"
            :badge-type="activity ? 'success': 'info'"
            :badge-icon-size="32">

            <span slot="header" :class="`badge badge-${activity ? 'success' : 'primary' }`">
                Send SMS
            </span>
            <p slot="content">
                <span v-if="activity">
                   You have succesuflly sent an SMS.
                </span>
                <span v-else>
                    Use your API tokens to send SMS.
                </span>
                
            </p>
            <h6 slot="footer">
                <span v-if="activity">
                    You have sent an sms
                </span>
                <span v-else>
                    You have yet to send an SMS.                    
                </span>
            </h6>
        </TimeLineItem>



        <TimeLineItem
            v-if="activity"
            inverted
            state-type="complete"
            badge-type="success"
            :badge-icon-size="32">

            <span slot="header" :class="`badge badge-${activity ? 'success' : 'primary' }`">
                Whats next?
            </span>
            <p slot="content">
                Check out SMS <br/>
                <router-link class="btn btn-primary btn-simple btn-sm" to="sms/analytics" tag="button">  
                    Analytics
                </router-link>
                
                <router-link class="btn btn-primary btn-simple btn-sm" to="billing/usage" tag="button">  
                    Usage
                </router-link>
            </p>
            <h6 slot="footer">
                you are on a roll
            </h6>
        </TimeLineItem>

        <TimeLineItem
            v-if="!activity"
            inverted
            state-type="primary"
            badge-type="info"
            :badge-icon-size="32">

            <span slot="header" :class="`badge badge-${activity ? 'success' : 'primary' }`">
                Whats next?
            </span>
            <p slot="content">
                <span v-if="tokens">
                    <router-link class="btn btn-primary btn-simple btn-sm" to="documentation" tag="button">  
                        API Docs
                    </router-link>
                </span>
                <span v-else>
                    <router-link class="btn btn-primary btn-simple btn-sm" to="settings/api-access-tokens" tag="button">  
                        Create an API token
                    </router-link>
                </span>
                
            </p>
            <h6 slot="footer">
                <span v-if="tokens">
                    use our API to start sending
                </span>
                <span>
                    create an API token
                </span>
            </h6>
        </TimeLineItem>
       

    </TimeLine>
    
  </div>
</template>

<script>
import {
  TimeLine,
  TimeLineItem
} from 'src/components';
//import BaseButton from '../../../components/BaseButton.vue';
import {Badge, BaseAlert} from 'src/components'
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
export default {
    components:{
        TabPane,
        Tabs,
        Collapse,
        CollapseItem,
        Badge,
        BaseAlert,
        TimeLine,
        TimeLineItem,
        //BaseButton
    },
    //props: ['user','userHasApiToken','userSentSms'],
    //props: ['user','userHasApiToken','userSentSms'],
    //props: ['timLineItems'],
    props: ['user', 'tokens', 'activity'],
    data() {
        return {
        }
    }
}
</script>

<style>
.card-timeline .card-body {
    padding-top:0 !important;
}
.card.card-timeline .timeline{
    padding: 2px 0 0px
}

.card.card-timeline .timeline > li {
    margin-bottom: 5px;
}

.card.card-timeline .timeline > li > .timeline-panel {
    padding: 14px;
}
</style>