<template>
    <div class="row">
    <div class="col-lg-12">
    <card>
        <template slot="header">
            <h5 class="card-category">Group</h5>
            <h3 class="card-title">Members of {{ currentGroup }} contacts</h3>
        </template>
        <div class="d-flex justify-content-center">
          <h5>
            Found {{totalRecords}} results
          </h5>
        </div> 
        <div class="d-flex justify-content-center">
            <div class="input-group">
                <!--
              <download-csv
                :labels="labelsCsv"
                name = "messages.csv"
                :data = "groupMembersArray">

                  <base-button type="primary" class="ml-3">
                    Download visible data
                  </base-button>

              </download-csv>
              -->
                <base-button :loading="submitInProccess" @click="reset" type="info" class="ml-3">
                    Reset search
                </base-button>
                <base-button :loading="submitInProccess" @click="downloadAll" type="primary" class="ml-3">
                    Download
                </base-button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
          <h5>To search with text filters, enter your query and press enter</h5>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <div class="row">
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Custom1"
                        v-model="search.contact_custom1"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Custom2"
                        v-model="search.contact_custom2"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Custom3"
                        v-model="search.contact_custom3"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Custom4"
                        v-model="search.contact_custom4"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Custom5"
                        v-model="search.contact_custom5"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                <div class="col">
                    <ValidationObserver v-slot="{  }">
                        <ValidationProvider
                        rules="digits_between:3,15"
                        v-slot="{ passed, failed, errors }">
                        <base-input
                            type="number"
                            :class="{ 
                            'has-success': !errors[0] && search.contact_msisdn, 
                            'has-danger': errors[0]
                            }"
                            placeholder="Recipient"
                            v-model="search.contact_msisdn"
                            @keyup.enter="filterColumn(search ,errors[0])">
                        </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Country"
                        v-model="search.contact_country_name"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
                
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Operator"
                        v-model="search.contact_operator_name"
                        @keyup.enter="filterColumn(search)">
                    </base-input>
                </div>
            </div>
            
        </div>
        <div class="d-flex justify-content-center">
          
          <base-pagination 
            type="info"
            :pageCount="pageCount" 
            :perPage="perPage"
            :total="totalRecords"
            :value="page"
            :pagesToDisplay=10
            @input="pagination"></base-pagination>
        </div>
        <base-table 
            :data="groupMembersArray" 
            thead-classes="text-primary">

                <template slot="columns" slot-scope="{ columns }">
                    <th></th>
                    <th>Group</th>
                    <th>Custom1</th>
                    <th>Custom2</th>
                    <th>Custom3</th>
                    <th>Custom4</th>
                    <th>Custom5</th>
                    <th>Number</th>
                    <th>Country</th>
                    <th>Operator</th>
                    
                    <!--
                        <th>Sent Times</th>
                        <th>Recent Status history</th>
                    -->
                    <th>Created 
                        &nbsp; 
                        <span @click="sortme('desc')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                            <mdicon name="sort-ascending" size="14"/>
                        </span>
                        
                        <span style="cursor:pointer" @click="sortme('asc')" v-if="sort.direction=='desc'" >
                            <mdicon name="sort-descending" size="14"/>
                        </span>
                    </th>
                    <th>Last status</th>
                </template>

                <template slot-scope="{ row, index }">
                <td><country-flag :country='row.contact_country_code' size='normal'/></td>
                <td>{{ row.contact_list_name }}</td>
                <td>{{ row.contact_custom1 }}</td>
                <td>{{ row.contact_custom2 }}</td>
                <td>{{ row.contact_custom3 }}</td>
                <td>{{ row.contact_custom4 }}</td>
                <td>{{ row.contact_custom5 }}</td>
                <td>{{ row.contact_msisdn }}</td>
                <td>{{ row.contact_country_name }}</td>
                <td>{{ row.contact_operator_name }}</td>
                
                <!--
                <td>{{ row['contact_history_current_dlr_mask_code'] ? row['contact_history_current_dlr_mask_code'].split(',').length : "" }}</td>
                <td>
                    <span 
                        v-for="status in (row['contact_history_current_dlr_mask_code'] ? row['contact_history_current_dlr_mask_code'].split(',').slice(-4) : []) ">
                        <badge :type="status == 1 ? 'success' : status == 8 ? 'warning' : 'danger'">
                            <template slot="badgeContent">
                                {{ statusMap[status] }} 
                            </template>
                        </badge>
                        &nbsp;
                    </span>
                </td>
                -->
                <td>{{ row.contact_add_date }}</td>
                <td>{{ row.contact_updated_date }}</td>

                </template>
            </base-table>
    </card>
  </div>
</div>
</template>

<script>

import { BaseTable, BaseProgress, Badge, BasePagination} from '@/components';
import CountryFlag from 'vue-country-flag'
import DownloadCsv from 'vue-json-csv'
import { extend } from "vee-validate";
import { required,min,max,numeric, digits } from "vee-validate/dist/rules";
extend("min", min);
extend("max", max);
extend("numeric", numeric);

extend('digits_between', {
  async validate(value, { min, max }) {
        return value.match(/^[0-9]{3,15}$/);
  },
  params: ['min', 'max'],
  message: 'The {_field_} must be between {min} and {max} digits'
});


export default {
    components: {
        BaseTable,
        BaseProgress,
        CountryFlag,
        Badge,
        BasePagination,
        DownloadCsv
    },
    props:[ 
        'currentGroup',
        'groupMembersArray',
        'totalRecords',
        'page',
        'perPage',
        'pageCount',
        'sort',
        'submitInProccess'
    ],
    methods:{
        reset(){
            this.search = {}
            this.$emit('filterColumn', this.search);
        },
        downloadAll(){
            
            this.$emit('download', true);
        },
        filterColumn(searchParams, error){
            if(error){
                console.log(error)
            }else{
                this.$emit('filterColumn', searchParams);
            }
            
        },
        sortme(direction){
            this.$emit('sortme', direction);
        },
        pagination(page){  
            this.$emit('pagination', page);
        },
    },
    data() {
        return {
            search:{
                
            },
            statusMap: {
                1: 'Delivered',
                2: 'Failed',
                8: 'Proccessing',
                16: 'Rejected'
            },
            table: [],
            labelsCsv:{
                contact_msisdn: 'Phone',
                contact_list_name: 'List name',
                contact_country_name: 'Country',
                contact_country_code: 'Country code',
                contact_operator_name: 'Operator',
                contact_history_current_dlr_mask_code: 'Last known codes desc',
                contact_mcc: 'Operator mcc',
                contact_mnc: 'Operator mnc',
                contact_add_date: 'Contact created date',
                contact_updated_date: 'Last status date'
            },

        }
    }

}
</script>

<style>

</style>