<template>
  <card style="height:435px">
    <template slot="header">
      <h4 class="card-title">Top 5 operators by volume</h4>
      <p class="card-category">Total per operator</p>
    </template>
    <div class="row">
      
      <div class="col-md-12 ml-auto mr-auto">
        <div class="table-responsive">
          <table class="table">
            
            <tbody>
              <tr>
                <td>Operator</td>
                <td class="text-right">Total for operator</td>
                <td class="text-right">% of total for period</td>
              </tr>
              <tr  v-for="(item, name, index) in FiltertilesData.operator" :key="index">
                <td style="width:60%;text-align:left !important; padding-left:15px" class="text-right">{{name}}</td>
                <td class="text-right">{{item ? item.toLocaleString('en') : 0}}</td>
                <td class="text-right">{{ 
                  Number.parseFloat(item/FiltertilesData.total * 100).toFixed(0) 
                }}%</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </card>
</template>
<script>

export default {
  components: {
  },
  props:['FiltertilesData'],
};
</script>
<style></style>
