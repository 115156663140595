<template>
   <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-8">
                    <h4 class="card-title">Welcome to message-deck marketing dashboard</h4>
                </div>
                <div class="col-lg-4">
                    <h4 class="card-title">Helpful tips</h4>
                </div>
            </div>
            <div class="row" style="height:300px;">
                <div class="col-lg-8">

                    
                    <div class="row h-100" >
                        <div class="col-lg-12">
                            <card type="primary"
                            class="card-primary" style="height: 96% !important">
                                <template slot="header">
                                    <h2 class="card-title">
                                        <span v-if="user">
                                        {{ 
                                            currentHour < 12 ? 'Good morning' : 
                                            currentHour >= 12 && currentHour < 17  ? 'Good afternoon' : 
                                            currentHour > 17 ? 'Good evening' : 'Good evening'
                                        }}, {{user.user_first_name != "null" && user.user_first_name != null ? user.user_first_name : ""}}
                                        </span>
                                    </h2>
                                </template>
                                <br>
                                <template slot="default">
                                    <p class="card-text">
                                        You can start sending SMS to your audiences right away. 
                                    </p> 
                                    <div class="row h-25 d-flex align-items-end">
                                        <div class="col-lg-7">
                                            <router-link class="btn btn-primary btn-simple" 
                                            to="campaigns/new" tag="button">  
                                                Create an SMS campaign
                                            </router-link>
                                        </div>
                                    </div>
                                </template>
                            </card>    
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 d-none d-sm-block">
                    
                    <div class="row ">
                        <div class="col-lg-12" @mouseover="hoverOverContacts = true"
                            @mouseleave="hoverOverContacts = false">
                            <router-link to="contacts">
                                <card type="primary" class="card-primary" style="height: 92% !important">
                                    <h4 class="card-title " :style="hoverOverContacts ? 'color:#FFFF00' : 'color:#b2b200'">
                                        <mdicon  name="lightbulb-on" size="18" />   
                                    </h4>
                                    <h4 class="card-title" :style="hoverOverContacts ? 'text-decoration: underline;' : ''">
                                        Add contacts.
                                    </h4>
                        
                                    <p class="card-text text-muted">
                                        Add and manage your contacts.
                                    </p>
                                </card> 
                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" @mouseover="hoverOverTeams = true"
                            @mouseleave="hoverOverTeams = false">
                            <router-link to="settings/users">
                                <card type="primary" class="card-primary" style="height: 92% !important">
                                    <h4 class="card-title " :style="hoverOverTeams ? 'color:#FFFF00' : 'color:#b2b200'">
                                        <mdicon  name="lightbulb-on" size="18" />   
                                    </h4>
                                    <h4 class="card-title" :style="hoverOverTeams ? 'text-decoration: underline;' : ''">
                                        Invite team members.
                                    </h4>
                        
                                    <p class="card-text text-muted">
                                        You can add team members to your account.
                                    </p>
                                </card> 
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-8">
                    <h4 class="card-title">Popular Dashboard Resources</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <card type="primary"
                        class="card-primary h-90">
                        <h4 class="card-title h-20">
                            <mdicon name="wallet-outline" size="18" /> Billing  
                        </h4>
            
                        <p class="card-text h-30">
                            Balance, add funds, payment history
                        </p>

                        <div class="row h-50 d-flex align-items-end pb-2">
                            <div class="col-lg-10">
                                <router-link 
                                class="btn btn-primary btn-simple btn-sm" to="billing/pricing" tag="a">
                                    Pricing
                                </router-link>
                                <router-link 
                                class="btn btn-primary btn-simple btn-sm" to="billing/add-funds" tag="a">
                                    Add funds 
                                </router-link>
                            </div>
                        </div>
                    </card>
                </div>
                <div class="col-lg-4">
                    <card  type="primary"
                            class="card-primary h-90">
                        <h4 class="card-title h-20">
                            <mdicon name="send-check-outline" size="18" /> Delify  
                        </h4>
            
                        <p class="card-text h-30">
                            Delivery verify with over 1500 real devices.
                            <badge type="success">
                                <span slot="badgeContent">BETA</span>
                            </badge>
                        </p>

                        <div class="row h-50 d-flex align-items-end pb-2">
                            <div class="col-lg-12">
                                <router-link 
                                class="btn btn-primary btn-simple btn-sm" to="sms/delify" tag="a">
                                Delify
                                </router-link>
                                
                            </div>
                        </div>
                    
                    </card> 
                </div>
                <div class="col-lg-4">
                    <card 
                        type="primary"
                        class="card-primary h-90">
                        <h4 class="card-title h-20">
                            <mdicon name="message-text-outline" size="18" /> SMS  
                        </h4>
    
                        <p class="card-text h-30">
                            View analytics and usage.
                        </p>

                        <div class="row h-50 d-flex align-items-end pb-2">
                            <div class="col-lg-10">
                                <router-link class="btn btn-primary btn-simple btn-sm" to="campaigns/list" tag="a">
                                    Campaigns
                                </router-link>
                                <router-link class="btn btn-primary btn-simple btn-sm" to="sms/analytics" tag="a">
                                    Analytics
                                </router-link>
                                <router-link class="btn btn-primary btn-simple btn-sm" to="billing/usage" tag="a">  
                                    Usage
                                </router-link>
                            </div>
                        </div>
                        
                        
                    </card>
                </div>
            </div>

        </div>
   </div>
</template>

<script>
import { Badge } from 'src/components'
export default {
    components: {
        Badge
    },
    props: ['user'],
    data() {
        return {
            hoverOverContacts: false,
            hoverOverTeams:false,
            currentHour: null
        }
    },
    mounted(){
        const date = new Date();
        this.currentHour = date.getHours();
    },
    methods: {
    }
}
</script>

<style>

</style>