<template>
  <div class="col-12 ml-auto mr-auto text-center">
    <h2 class="card-title">
      Landing Page Editor
    </h2>

    <div v-show="this.showForm">
      <div class="row">
        <div class="col-12 form-column">
          <h4 class="card-title">Audience and Goal</h4>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="age-min">Target audience bottom age:</label>
            <input id="age-min" class="form-control" type="text" v-model="customer.targetAudienceAgeMin" placeholder="Target audience bottom age">
          </div>

          <div class="form-row">
            <label class="form-label" for="age-max">Target audience upper age:</label>
            <input id="age-max" class="form-control" type="text"  v-model="customer.targetAudienceAgeMax" placeholder="Target audience upper age">
          </div>

          <div class="form-row">
            <label class="form-label" for="gender">Target Audience Gender:</label>
            <select id="gender" class="form-control" v-model="customer.targetAudienceGender">
              <option class="select-option">All</option>
              <option class="select-option">Male</option>
              <option class="select-option">Female</option>
            </select>
          </div>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="language">Language:</label>
            <select id="language" class="form-control" v-model="customer.language">
              <option class="select-option">English</option>
              <option class="select-option">German</option>
              <option class="select-option">Italian</option>
              <option class="select-option">Spanish</option>
            </select>
          </div>

          <div class="form-row">
            <label class="form-label" for="action-type">Call To Action type:</label>
            <select id="action-type" class="form-control" v-model="customer.callToActionType">
              <option disabled value="">Select action type</option>
              <option class="select-option">Download</option>
              <option class="select-option">Purchase</option>
              <option class="select-option">Register</option>
              <option class="select-option">Subscribe</option>
              <option class="select-option">Booking</option>
            </select>
          </div>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="goal">Goal:</label>
            <textarea id="goal" class="form-control" v-model="customer.goal" placeholder="(Optional) Describe the main goal of the landing page" />
          </div>

          <div class="form-row">
            <label class="form-label" for="audience">Audience:</label>
            <textarea id="audience" class="form-control" v-model="customer.businessAudience" placeholder="(Optional) Describe the audience you are targeting" />
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 form-column">
          <h4 class="card-title">Business, Products and Services</h4>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="business-type">Business Type:</label>
            <select id="business-type" class="form-control" v-model="customer.businessType">
              <option class="select-option">Company</option>
              <option class="select-option">Individual</option>
            </select>
          </div>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="business-country">Business Country:</label>
            <input id="business-country" class="form-control" type="text" v-model="customer.businessCountry">
          </div>

          <div class="form-row">
            <label class="form-label" for="business-currency">Business Currency:</label>
            <input id="business-currency" class="form-control" type="text" v-model="customer.businessCurrency">
          </div>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="business-products">Business Products:</label>
            <textarea
              id="business-products"
              class="form-control"
              v-model="customer.businessProduct"
              placeholder="(Optional) Describe your business products"
            />
          </div>

          <div class="form-row">
            <label class="form-label" for="business-services">Business Services:</label>
            <textarea
              id="business-services"
              class="form-control"
              v-model="customer.businessService"
              placeholder="(Optional) Describe your business services"
            />
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 form-column">
          <h4 class="card-title">The Brand</h4>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="brand-name">Brand Name:</label>
            <input id="brand-name" class="form-control" type="text" v-model="customer.brandName" placeholder="Enter brand / product name">
          </div>
        </div>

        <div class="col-4 form-column">
          <div class="form-row">
            <label class="form-label" for="brand-keywords">Brand Keywords:</label>
            <textarea id="brand-keywords" class="form-control" v-model="customer.brandKeywords" placeholder="(Optional) For example - friendly, competent, modern" />
          </div>
        </div>
      </div>

      <button class="btn btn-primary btn-simple" @click="generatePage">Generate Landing Page</button>
    </div>

    <div v-show="!this.showForm && !this.showSpinner">
      <button class="btn btn-primary btn-simple" @click="returnToForm">Return to Form</button>

      <div id="editor" width="100%"></div>
    </div>

    <div v-show="this.showSpinner">
      <!--
      <b-spinner variant="success" type="grow" label="Large Spinner"></b-spinner>
      -->
    </div>
  </div>
</template>

<script>

  export default {
    data () {
      return {
        showForm: true,
        showSpinner: false,
        customer: {
          language: 'English',
          callToActionType: '',
          goal: '',
          targetAudienceAgeMin: '20',
          targetAudienceAgeMax: '30',
          targetAudienceGender: 'All',
          businessType: 'Company',
          businessCountry: 'USA',
          businessCurrency: 'USD',
          businessProduct: '',
          businessService: '',
          businessAudience: '',
          brandName: '',
          brandKeywords: ''
        }
      }
    },
    mounted () {
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      
      //const res = await this.$http.get(`${api_endpoint}/routing/token/read`)
      //if(res && res.status == 200) {
      //    this.keys = res.data
      //}

      //MOVE TO BEFORE MOUNT
      //const gjs = document.createElement('script')
      //gjs.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/grapesjs/0.21.4/grapes.min.js')

      //document.head.appendChild(gjs)
//
      //const aiApi = document.createElement('script')
      //aiApi.setAttribute('src', '/ai/ai-service-api.js')
      //aiApi.setAttribute('src', `${api_endpoint}/ai/ai-service-api.js`)
      

      //const templateDefault = document.createElement('script')
      //templateDefault.setAttribute('src', '/ai/templates/default.js')
      //templateDefault.setAttribute('src', `${api_endpoint}/ai/templates/default.js`)

      //document.head.appendChild(aiApi)
      //document.head.appendChild(templateDefault)
      this.loadGrape()
    },
    methods: {
      appendScript(rawJs){
        const scriptElement = document.createElement('script')
        scriptElement.innerHTML = rawJs
        document.body.appendChild(scriptElement);
      },  
      async loadGrape(){
        const api_endpoint = process.env.VUE_APP_API_ENDPOINT
        const scripts = await Promise.all([
          await this.$http.get(`${api_endpoint}/ai/ai-service-api.js`),
          await this.$http.get(`${api_endpoint}/ai/templates/default.js`)
        ])

        for( const script of scripts ){
          //if(script.status && script.status == 200) this.appendScript(script.data, script.url.split(/\//).pop())
          if(script.status && script.status == 200) this.appendScript(script.data)
        }

        //const api_endpoint = process.env.VUE_APP_API_ENDPOINT
        //const res = await this.$http.get(`${api_endpoint}/ai/ai-service-api.js`)
        //if(res && res.status == 200 ) {
        //  const aiApi = document.createElement('script')
        //  aiApi.innerHTML = res.data
        //  document.body.appendChild(aiApi);
        //}

      },
      async generatePage () {
        this.showForm = false
        this.showSpinner = true

        const customer = this.customer
        customer.targetAudienceAge = `${this.customer.targetAudienceAgeMin}-${this.customer.targetAudienceAgeMax}`

        const templateId = 'default'
        const containerId = '#editor'

        const api = new AiServiceAPI({
          apiBasePath: process.env.VUE_APP_API_ENDPOINT,
          http: this.$http
        })
        
        const editor = new Editor({ // TODO: userId, projectId
          templateId: templateId,
          containerId: containerId,
          apiBasePath: process.env.VUE_APP_API_ENDPOINT,
          http: this.$http
        }) 

        const { css, html } = await api.generateLandingPage(templateId, customer)
        

        await editor.initialize()

        editor.setCSS(css)
        editor.setHTML(html)

        this.showSpinner = false
      },
      returnToForm () {
        this.showForm = true
      }
    }
  }
</script>

<style>
  @import 'https://cdnjs.cloudflare.com/ajax/libs/grapesjs/0.21.4/css/grapes.min.css';

  .form-label {
    margin-right: 5px;
  }

  .form-column {
    display: flex;
    flex-direction: column;
  }

  .form-row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }

  .form-button {
    margin-top: 10px;
  }

  .select-option {
    color: black;
  }
</style>
