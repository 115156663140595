<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row">
      <div class="col-lg-12">
        <FilterView @filter="filtered" :loading="loading"/>
      </div>
    </div>
    <tabs type="primary" square :clickHandler="paneChanged">
      <tab-pane label="Analytics">
        <div class="row">
          <div class="col-lg-12">
            <Chart :data="chartData" :groupBy="filter.group" :loading="loading"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <Tiles 
              :loading="loading" 
              :cities="cities"
              :platforms="platforms"
              :countries="countries"
              :total="total"
              :countryCodes="countryCodes"
            />
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-lg-12">
            <SummeryTiles :totalClicks="total" :totalSent="totalSent" :totalCost="totalCost"/>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-lg-12">
            <Table :rows="rows" @groupBy="groupBy" :loading="loading" :total="totalSent" :countryCodes="countryCodes"/>
          </div>
        </div>
      </tab-pane>
      <tab-pane label="Clicks">
        <HistoryTable 
          :perPage="historyPerPage"
          :rows="historyRows" 
          :length="historyLength" 
          @pagination="pagination" 
          @changePerPage="changePerPage"
        />
      </tab-pane>
    </tabs>
  </div>
</template>

<script>
import SummeryTiles from './Componenets/SummeryTiles.vue'
import HistoryTable from './Componenets/HistoryTable.vue'
import Tiles from './Componenets/Tiles.vue'
import Chart from './Componenets/Chart.vue'
import FilterView from './Componenets/Filter.vue'
import Table from './Componenets/Table.vue'
import countryByAbr from '../../../../json/iso-3166-2-country.json';
import { TabPane, Tabs } from 'src/components';

export default {
  components: {
    TabPane,
    Tabs,
    FilterView,
    Chart,
    Tiles,
    SummeryTiles,
    Table,
    HistoryTable
    
  },
  data(){
    return {
      countryCodes: countryByAbr,
      loading: false,
      chartData: [],
      historyRows: [],
      historyPage: 1,
      historyPerPage: 10,
      historyLength: 1,
      rows: [],
      countries: [],
      cities: [],
      platforms: [],
      total: 0,
      totalSent: 0,
      totalCost: 0,
      currentPane: 'analytics',
      tiles: {
        cities: {
          labels: [],
        }
      },
      filter: {
        group: 'date'
      }
    }
  },
  created(){

  },
  methods: {
    async changePerPage(perPage){
      this.historyPerPage = perPage
      //await this.loadHistory()
    },
    async pagination(page){
      this.historyPage = page
      //this.filter = {...this.filter, ...{ page: this.historyPage }}
      await this.loadHistory()
    },
    paneChanged(pane){
      this.currentPane = pane.toLowerCase()
    },
    parseResults(data){
      this.rows = []
      for (let key in data) {
        this.rows.push({
          id: key,
          sent: data[key].sent,
          clicks: data[key].clicks,
          sell: data[key].sell
        })
      }    
    },
    async filtered(filter){      
      
      this.filter = { ...this.filter, ...filter }
      if(this.currentPane == 'clicks'){
        await this.loadHistory()
      } else {
        await this.loadData()
      }
    },
    async loadHistory(){
      
      this.loading = !this.loading
      this.filter = {...this.filter, ...{ page: this.historyPage, perPage: this.historyPerPage }}
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/history`, this.filter)
      this.loading = !this.loading
      if(res && res.status == 200){
        this.historyRows = res.data.results
        this.historyLength = res.data.length
      }

    },
    async groupBy(groupBy){
      this.filter.group = groupBy
      await this.loadData()
    },
    async loadData(){
      const query = {...this.filter, ...{ groupBy: this.groupBy }}

      this.loading = !this.loading
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/read`, query)

      if(res && res.status == 200){
        this.loading = !this.loading
        this.total = res.data.total
        this.totalSent = res.data.totalSent
        this.totalCost = res.data.totalCost
        this.platforms = res.data.platform
        this.cities = res.data.cities
        this.countries = res.data.countries
        this.chartData = res.data[this.filter.group]
        this.parseResults(res.data[this.filter.group])
      }
      
    }
  }
}
</script>

<style>

</style>