<template>
  <card type="chart" style="padding:10px">
    <h4>
        Getting Started.
    </h4>
    <p>
        You're just a few steps away from launching your successful SMS campaign. Let's begin by setting up the SMS text and originator for your campaign.
    </p>
    <br>
    <h4>
        Step 1: Setup SMS Text and Originator, In this step, you will define the core elements of your SMS campaign
    </h4>
    <p>
        - SMS Text: Keep it concise, engaging, and relevant to your campaign's goal. You can use dynamic placeholders (Macros) to personalize your message.<br>
        - Example: "Hey there! Enjoy a 20% discount on your next purchase with us. Use code: DISCOUNT20. Valid until [DATE]." <br>
        - Originator: Choose a sender name or number for your SMS. The originator is what your recipients will see as the sender of the message. Make sure it's recognizable and trustworthy.<br>
        - Example: "YourCompany" or "+1234567890"

    </p>
    <br>
    <h4>
        Step 2: Define Recipients, In this step, you will specify the audience for your SMS campaign.
    </h4>
    <p>
        - You can upload a list of recipients, segment your audience, or target specific groups to ensure your message reaches the right people.
    </p>
    <br>
    <h4>
        Step 3: Set Schedule, it's time to schedule when your SMS campaign will be sent. 
    </h4>
    <p>
        - Choose the date and time that best suits your campaign's objectives and your recipients' preferences.
    </p>
    <br>
    <h4>
        Step 4: Tracking and Analytics
    </h4>
    <p>
        - Once your campaign is live, you'll want to monitor its performance. Our tracking and analytics tools provide insights into delivery rates, open rates, and click-through rates. You can assess the effectiveness of your campaign and make data-driven decisions for future campaigns.
        Remember, SMS campaigns are subject to regulations and compliance requirements. Ensure your message complies with all relevant laws, includes opt-out information, and respects your recipients' preferences.
        By following these steps, you'll create a successful SMS campaign that engages your audience and delivers results. 
    </p>
    <br>
    <base-button type="primary" @click="$emit('confirm', true)">
        Got It!
    </base-button>

  </card>
</template>

<script>
export default {

}
</script>

<style>

</style>