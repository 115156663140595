<template>
  <ValidationObserver ref="observer">
    <form ref="set-amount-form">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="info-text">Amount to add</h3>
        </div>
        <div class="col-sm-7">

          <ValidationProvider
            name="amount"
            rules="required|integer|amountValidator"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
          
            v-model="model.amount"
            label="Amount to add"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
         
        </div>
        
      </div>  
      
    </form>
  </ValidationObserver>
  
</template>
<script>
import { extend } from "vee-validate";
import { required, integer } from 'vee-validate/dist/rules'
extend('required', required)
extend('integer', integer)

//console.log(numeric)
//extend('required', required);
extend('integer', {
  message:`Please use round number`
});


extend("amountValidator", {
  params: ["someData"],
  validate: function (value){    
    return (value >= 100)
  },
  message:` `
});

export default {
  components: {
   
  },
  data() {
    return {
      amount: 0,
      model:{
        amount: null
      }
    };
  },
  props:['someData'],
  watch: {
    'someData' : function (nv) {
      if( nv && nv.method == 'bank'){
        //this.model.amount = 100
        this.$set(this.model,'amount',100)
      }
    }
    
  },
  created() {
  },
  mounted(){
  },
  beforeMount() {
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      //console.log(this.$refs.observer.validate())
      return this.$refs.observer.validate().then(res => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res, this.model);
        return res;        
      });
    }
  }
};
</script>
<style></style>
