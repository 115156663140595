<template>
  <div>
    
    <div class="row justify-content-center">

    
      <credit-card 
      v-if="billingMethod=='card'">
      </credit-card>

      <bank-transfer v-if="billingMethod=='bank'"
      :minAmount="100"></bank-transfer>
      <pay-pal v-if="billingMethod=='paypal'"
      :minAmount="10"></pay-pal>
      
    </div>
  </div>
</template>
<script>
import CreditCard from './Methods/CreditCard.vue';
import BankTransfer from './Methods/BankTransfer.vue';
import PayPal from './Methods/PayPal.vue';

export default {
  components: {
    CreditCard,
    BankTransfer,
    PayPal
  },
  props:['billingMethod'],
  data() {
    return {
      model: {
        cardInfo: {}
      }
    };
  },
  methods: {
      

    validate() {
      if(this.billingMethod == 'card'){

      }else{      
        this.$emit('on-validated', true, this.model);
        return Promise.resolve(true);
     }
    }
  }
};
</script>
<style></style>
