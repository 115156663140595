<template>
  <card style="height:435px">
    <template slot="header">
      <h4 class="card-title">Top 5 countries by volume</h4>
      <p class="card-category">Total per country</p>
    </template>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <div class="table-responsive">

          <table class="table">
            
            <tbody>
              <tr>
                <td style="width:5%"></td>
                <td>Country</td>
                <td class="text-right">Total for country</td>
                <td class="text-right">% of total for period</td>
              </tr>
              

              <tr  v-for="(item, name, index) in FiltertilesData.country" :key="index">
                <td style="width:5%">
                  <div class="flag">
                    <country-flag :country='name' size='normal'/>         
                    </div>
                </td>
                <td style="width:50%">{{countryCodes[name] ? countryCodes[name].name : name}}</td>

                <td class="text-right">{{item ? item.toLocaleString('en') : 0}}</td>
                <td class="text-right">{{ 
                  Number.parseFloat(item/FiltertilesData.total  * 100).toFixed(0)
                }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import CountryFlag from 'vue-country-flag'


export default {
  components: {
    CountryFlag
  },
  props:['FiltertilesData', 'countryCodes'],
};
</script>
<style></style>

