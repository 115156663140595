<template>
<div>
<h3 class="card-title" style="text-align:center;margin:10px 0">Use a wire transfer  - minimum amount {{minAmount}} EUR.</h3>
<p style="max-width:500px;">Our bank details will be displayed in the next step. The funds will be added to your account when received into our bank. This can take a few days depending on bank policies, type of transfer and bank location.</p>

</div>
</template>
<script>

export default {
  props:['minAmount'],
  data() {
    return {
        
    };
  },
  methods: {
    
  }
};
</script>
<style></style>
