var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Change password")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfilePassword)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|passwordValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{class:{ 
                    'has-success': !errors[0] && _vm.user.newPassword, 
                    'has-danger': errors[0] 
                },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","label":"Enter new password","placeholder":"Enter new password","error":errors[0]},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('base-input',{class:{ 
                    'has-success': !errors[0] && _vm.user.verifyNewPassword, 
                    'has-danger': errors[0] 
                },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","name":"password","label":"Verify new password","placeholder":"Verify new password","error":errors[0]},model:{value:(_vm.user.verifyNewPassword),callback:function ($$v) {_vm.$set(_vm.user, "verifyNewPassword", $$v)},expression:"user.verifyNewPassword"}})]}}],null,true)})],1)]),_c('base-button',{staticClass:"btn-fill",attrs:{"loading":_vm.submitInProccess,"disabled":!valid,"native-type":"submit","type":"primary"}},[_vm._v(" Update password ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }