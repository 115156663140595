<template>
  <div class="d-flex justify-content-center">
    <div class="row">
        <div class="col">
            <base-input
                type="text"
                placeholder="ID"
                v-model="search.campaign_id">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Type"
                v-model="search.campaign_type">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Date"
                v-model="search.campaign_date">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Time Zone"
                v-model="search.campaign_tz">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Hour:Minute"
                v-model="search.campaign_hr_mn">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Created"
                v-model="search.createdAt">
            </base-input>
        </div>
        <div class="col">
            <base-input
                type="text"
                placeholder="Updated"
                v-model="search.updatedAt">
            </base-input>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    watch: {
        'search' : {
            handler(newVal, oldVal){
                /*
                console.log(newVal)
                console.log(oldVal)
                */
                this.handleChange(newVal)
            },
            deep: true
        }
    },
    methods:{
        handleChange(search){
            this.$emit('search', search)
        }
    },
    data() {
        return {
            search: {
                campaign_id: null,
                campaign_type: null,
                campaign_date: null,
                campaign_tz: null,
                campaign_hr_mn: null,
                createdAt: null,
                updatedAt: null
            }
        }
    }
}
</script>

<style>

</style>
