<template>
    <div class="row">
      <div class="col-lg-3" >
        <stats-card
          :title="totalSent.toString()"
          subTitle="Total Volume"
          type="info"
          icon="email-fast">
          
          <div slot="footer" >
            Messages sent in period
          </div>
        </stats-card>
      </div>
      <div class="col-lg-3" >
        <stats-card
          :title="totalClicks.toString()"
          subTitle="Clicks"
          type="info"
          icon="mouse">
          
          <div slot="footer" >
            Amount of clicks for period
          </div>
        </stats-card>
      </div>
      <div class="col-lg-3" >
        <stats-card
          :title="(
                    (  
                      totalClicks && totalSent ? ( ( totalClicks / totalSent )  * 100 ).toFixed(2)  :
                      totalClicks ? 100 : 0
                    ) 
            ).toString() + '%'"
          subTitle="Average CTR%"
          type="info"
          icon="chart-line-variant">
          
          <div slot="footer" >
            Average CTR% for period
          </div>
        </stats-card>
      </div>
      <div class="col-lg-3" >
        <stats-card
          :title="totalCost.toFixed(2).toString()"
          subTitle="Spent"
          type="info"
          icon="cash">
          
          <div slot="footer" >
            Amount spent for period (&euro;)
          </div>
        </stats-card>
      </div>
    </div>
</template>

<script>
import {Tooltip} from 'element-ui'
import StatsCard from 'src/components/Cards/StatsCard';
export default {
components: {
    StatsCard,
    [Tooltip.name]: Tooltip
  },
  props:[ 'totalClicks', 'totalSent', 'totalCost'],
  data() {
    return {

    }
  }
}
</script>

<style>
.info-icon> .mdi {
  color:white !important;
  margin-top:15%;
}
</style>