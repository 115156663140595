var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Account Details")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateAccountDetailes)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|account_name:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:{ 
                 'has-success': !errors[0] && _vm.account.client_account_name, 
                 'has-danger': errors[0] || !_vm.account.client_account_name 
               },attrs:{"type":"text","label":"Account Name","placeholder":"Account Name"},model:{value:(_vm.account.client_account_name),callback:function ($$v) {_vm.$set(_vm.account, "client_account_name", $$v)},expression:"account.client_account_name"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('base-input',{attrs:{"type":"text","label":"Account UUID","disabled":true,"textLight":true,"placeholder":"Account UUID"},model:{value:(_vm.account.client_accounts_cliend_uuid),callback:function ($$v) {_vm.$set(_vm.account, "client_accounts_cliend_uuid", $$v)},expression:"account.client_accounts_cliend_uuid"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"type":"text","label":"Current Balace","disabled":true,"textLight":true,"placeholder":"Current Balace"},model:{value:(_vm.account.client_account_balance),callback:function ($$v) {_vm.$set(_vm.account, "client_account_balance", $$v)},expression:"account.client_account_balance"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"type":"text","label":"Credit","disabled":true,"textLight":true,"placeholder":"Credit"},model:{value:(_vm.account.client_allowed_balance),callback:function ($$v) {_vm.$set(_vm.account, "client_allowed_balance", $$v)},expression:"account.client_allowed_balance"}})],1),_c('div',{staticClass:"col-lg-4"},[_c('base-input',{attrs:{"type":"text","label":"Currency","disabled":true,"textLight":true,"placeholder":"Currency"},model:{value:(_vm.account.client_account_currency),callback:function ($$v) {_vm.$set(_vm.account, "client_account_currency", $$v)},expression:"account.client_account_currency"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|greaterThanZero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
               var passed = ref.passed;
               var failed = ref.failed;
               var errors = ref.errors;
return [_c('base-input',{class:{ 
                 'has-success': !errors[0] && _vm.account.client_low_balance_notification_limit, 
                 'has-danger': errors[0] || !_vm.account.client_low_balance_notification_limit 
               },attrs:{"type":"text","label":"Low balance limit notification","placeholder":"Low balance limit notification"},model:{value:(_vm.account.client_low_balance_notification_limit),callback:function ($$v) {_vm.$set(_vm.account, "client_low_balance_notification_limit", $$v)},expression:"account.client_low_balance_notification_limit"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('base-button',{staticClass:"btn-fill",attrs:{"loading":_vm.submitInProccess,"disabled":!valid,"native-type":"submit","type":"primary"}},[_vm._v(" Save account details ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }