<template>
  <div>
    <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Summery table</h5>
              <h3 class="card-title">Usage summary</h3>
            </div>
          </div>
        </template>
        <div style="padding:25px" class="chart-area">
<!--
          <base-alert type="default" icon="tim-icons icon-bell-55">
            Total {{FilterChartData.totalCost}}
          </base-alert>
-->
          <table class="table">
              <tbody>
                  <tr>
                      <td>Product</td>
                      <td>Quantity</td>
                      <td>Parts</td>
                      <td>Cost</td>
                  </tr>
                  
                  <tr>
                      <td style="width:80%">SMS - Outbound</td>
                      <td>{{FilterChartData.totalSent}}</td>
                      <td>{{FilterChartData.totalParts}}</td>
                      <td>{{FilterChartData.totalCost}}</td>
                  </tr>
                  <tr>
                      <td style="width:80%">SMS - Inbound</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                  </tr>
              </tbody>
          </table>
        </div>
        
    </card>
  </div>
</template>

<script>

import {  BaseAlert } from 'src/components';
export default {
    components: {
      BaseAlert
    },
    props:['FilterChartData'],
    computed: {
        enableRTL() {
            return this.$route.query.enableRTL;
        },
        isRTL() {
            return this.$rtl.isRTL;
        },
    }
}
</script>

<style>

</style>