<template>
    <div class="container" style="max-width: 1700px !important">
        <br>
        <div class="row">
            <div class="col-12">
                <h3>Keep track of your payment history</h3>
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-12">
                <card type="chart" style="padding:10px">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>INVOICE DATE</td>
                                <td>PROFORMA NUMBER</td>
                                <td>INVOICE NUMBER</td>
                                <td>INVOICE CURRENCY</td>
                                <td>AMOUNT TO ADD</td>
                                <td>TAX AMOUNT</td>
                                <td>TOTAL AMOUNT</td>
                                <td>STATUS</td>
                                <td>STATUS DATE</td>
                                <td>OPTIONS</td>
                                <td>COMMENTS</td>
                            </tr>
                            <tr  v-for="(item,index) in historyData" :key="index">
                                <td>{{item.payment_issue_date}}</td>
                                <td style="width:200px !important">
                                    <span style="display: inline-block;">
                                        {{item.payment_proforma_invoice_number}}
                                    </span>
                                </td>
                                <td style="width:200px !important">
                                    <span style="display: inline-block;">
                                        {{item.payment_invoice_number ? item.payment_invoice_number: item.payment_status}}
                                    </span>
                                </td>
                                <td>{{item.payment_currency}}</td>
                                <td>
                                    {{
                                        item.payment_usable_amount ? item.payment_usable_amount.toLocaleString() :
                                        item.proforma_invoice_sub_total ? item.proforma_invoice_sub_total.toLocaleString() : 
                                        0.00
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.payment_tax_1 + item.payment_tax_2
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.payment_total_amount ? item.payment_total_amount.toLocaleString() : 
                                        item.proforma_invoice_total ? item.proforma_invoice_total.toLocaleString() : 0.00
                                    }}
                                </td>
                                <td>{{item.payment_status.toUpperCase()}}</td>
                                <td>{{item.payment_payment_date}}</td>
                                <td>
                                    <base-button 
                                        v-if="item.payment_status.toUpperCase() == 'PROFORMA'"
                                        @click="downloadProforma(item)"
                                        style="display: inline-block;"
                                        round type="default" size="sm">
                                            <mdicon name="cloud-download-outline" size="18" />
                                    </base-button>
                                    <base-button 
                                        v-if="
                                            ( 
                                                ( item.payment_status.toUpperCase() == 'PAID' || item.payment_status.toUpperCase() == 'DRAFT' )  
                                                    && 
                                                item.proforma_invoice_wave_download_link 
                                            ) 
                                        "
                                        @click="downloadWaveInvoice(item)"
                                        style="display: inline-block;"
                                        round type="default" size="sm">
                                            <mdicon name="cloud-download-outline" size="18" />
                                    </base-button>
                                    &nbsp;
                                    <!--
                                    <base-button 
                                        @click="deleteInvoice(item, index)"
                                        v-if="item.payment_status.toUpperCase() == 'PROFORMA'"
                                        :loading="historyData[index].loading !=''"
                                    style="display: inline-block;"
                                    round type="default" size="sm">
                                        <mdicon v-if="!historyData[index].loading" name="delete" size="18" style="color:white"/>
                                    </base-button>
                                    -->
                                </td>
                                <td>
                                    <span v-if="item.payment_status.toUpperCase() == 'DRAFT'">
                                        Invoice is waiting for approval 
                                    </span>
                                    <span v-if="item.payment_status.toUpperCase() == 'PROFORMA'">
                                        Please add the following Payment reference: {{item.payment_proforma_invoice_number}} when you wire the amount.
                                    </span>
                                    <span v-if="item.payment_status.toUpperCase() == 'PAID'">
                                        Thank you for your payment
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="padding:25px" v-if="!historyData.length" class="chart-area">
                        <p>No data to show.</p>
                    </div>
                </card>
            </div>
        </div>
        
    </div>
</template>

<script>
var easyinvoice = require('easyinvoice');
export default {
    
    components: {},
    data(){
        return {
            totalTax:null,
            historyData: [],
            proformaInvoiceObject: {
                sender: {
                    company: 'test',
                    address: 'test',
                    zip: 'test',
                    city: 'test',
                    country: 'test',
                    custom1: 'test', // VAT
                    custom2: 'test' // REG
                },
                client: {
                    company: 'test',
                    address: 'test',
                    zip: 'test',
                    city: 'test',
                    country: 'test',
                    custom1: 'test', // VAT
                    custom2: 'test' // REG
                },
                information: {
                    number: null,
                    date: null,
                    "due-date": null
                },
                products: [],
                "bottom-notice": 'test',
                settings: {
                    currency: "EUR",
                    "tax-notation": "VAT",
                    "margin-top": 25,
                    "margin-right": 25,
                    "margin-left": 25,
                    "margin-bottom": 25
                }

            },
        }
    },
    mounted(){
        this.getData()
    },
    methods: {
        //      https://materialdesignicons.com/
        deleteInvoice: function(item, index) {
            // This should only delete proforma invoice!
            this.historyData[index].loading = true
            
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            Promise.all([
                this.$http.post(api_endpoint + '/billing/delete-proforma-invoice',item)
            ])
            .then(([result1]) => {
                
                //this.historyData[index].loading = false
                this.historyData.splice(index, 1)
                
            })
            .catch((err)=>{
                this.historyData[index].loading = false
                this.historyData[index].submitInProccess = false
                this.$toast.error("Error handling response data: " + err.message)
            });
            
        },
        downloadWaveInvoice: function(item) {
            window.open(item.proforma_invoice_wave_download_link, '_blank');
        },
        downloadProforma: async function(item) {
            
            
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            const res = await this.$http.post(api_endpoint + '/billing/billing/download-proforma-invoice', item)
            if(res && res.status == 200){
                
                var docUrl = document.createElement('a');
                docUrl.href=`data:application/pdf;base64,${res.data}`
                docUrl.setAttribute('download', item.payment_proforma_invoice_number + '.pdf');   
                console.log(docUrl)
                document.body.appendChild(docUrl);
                docUrl.click();
            }
            this.submitInProccess = !this.submitInProccess;
            
            /*
            this.$http.post(api_endpoint + '/billing/billing/download-proforma-invoice', item)
            .then( async (resp) => {
                var docUrl = document.createElement('a');
                docUrl.href=`data:application/pdf;base64,${resp.data}`
                docUrl.setAttribute('download', item.payment_proforma_invoice_number + '.pdf');   
                document.body.appendChild(docUrl);
                docUrl.click();
            })
            .catch( (error) => {
                this.submitInProccess = !this.submitInProccess;
            });
            */

            
          
        },
        getData(){
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            Promise.all([
                //this.$http.get(api_endpoint + '/billing/payment-history')
                this.$http.get(api_endpoint + '/billing/history/read/one')
            ])
            .then(([result1]) => {
                //console.log(result1.data)
                this.historyData = result1.data
            })
            .catch((err)=>{
                this.$toast.error("Error handling response data: " + err.message)
            });
        }
    }
}
</script>

<style>

</style>