<template>
  <card>
    <div id="smpp-apis" class="my-padding">
      <h4 class="card-title">SMPP API Credentials
        <badge type="success">
          <span slot="badgeContent">BETA</span>
        </badge>
      </h4>
      <hr>

      <base-button @click="toggleModal()" type="info">
        <mdicon name="key-plus" size="22" /> &nbsp;&nbsp; Add access key  
      </base-button>

      <el-table :data="tableData">
        <el-table-column type="index">
            <el-table-column type="index">
            </el-table-column>

            <el-table-column 
              :min-width="200"
              label="User ID">
              <template slot-scope="scope">
                <base-input
                disabled
                textLight
                  class="border-0"
                  type="text"
                  label=""
                  placeholder=""
                  v-model="scope.row.username"
                  value:scope.row.username>
                </base-input>
              </template>
            </el-table-column>

            <el-table-column 
              :min-width="200"
              label="Password">
              <template slot-scope="scope">
                <base-input
                  class="border-0"
                  toggleIcon
                  addonRightIcon="eye"
                  addonRightIconToggle="eye-off"
                  type="password"
                  label=""
                  placeholder=""
                  v-model="scope.row.value"
                  value:scope.row.value>
                </base-input>
              </template>
            </el-table-column>

            <el-table-column 
              :min-width="200"
              label="Accounting token">
              <template slot-scope="scope">
                <base-input
                disabled
                textLight
                  class="border-0"
                  type="text"
                  label=""
                  placeholder=""
                  v-model="scope.row.key_accounting_token"
                  value:scope.row.key_accounting_token>
                </base-input>
              </template>
            </el-table-column>
            <el-table-column 
              prop="key_tp"
              label="Throughput">
              <template slot-scope="scope">
                {{scope.row.key_tp}}/Sec
              </template>
            </el-table-column>
            <el-table-column 
              :min-width="50"
              prop="key_status"
              label="Status">
              <template slot-scope="scope">
                <i v-if="scope.row.key_status" class="fa fa-check-circle text-success"></i>
                <i v-else class="fa fa-times-circle text-danger"></i>
              </template>
            </el-table-column>
            <el-table-column 
              :min-width="50"
              prop="key_mode"
              label="Mode">
            </el-table-column>
            <el-table-column 
              :min-width="50"
              prop="key_type"
              label="Type">
            </el-table-column>
            <!--
            <el-table-column sortable
              prop="createdAt"
              label="Created">
            </el-table-column>
            -->

            <el-table-column :min-width="200" label="Description">
            
            <template slot-scope="scope">
              
               <base-input
                :disabled="( scope.row.http_api_jwt_type || 'N/A' ).toUpperCase() == 'CAMPAIGN'"
                :editiable="true"
                class="border-0"
                @editing-input-start="editRow(scope.$index)"
                @editing-input-end="saveRow(scope.$index)"
                toggleIcon
                addonRightIcon="floppy"
                addonRightIconToggle="square-edit-outline"
                :textLight="true"
                
                label=""
                placeholder=""
                v-model="scope.row.key_desc"
                value:scope.row.key_desc>
              </base-input>
              
            </template>
          </el-table-column>

        </el-table-column>
      </el-table>
      <SmppModalVue ref="addKeyModal" @create="create"/>
    </div>
  </card>
</template>

<script>
import {Table, TableColumn, Select, Option} from 'element-ui'
import { BaseSwitch } from '@/components';
import { Badge } from 'src/components'
import SmppModalVue from './SmppApiComponents/Modal.vue'

export default {
  components: {
    Badge,
    BaseSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    SmppModalVue
  },
  props: {
    //existingApiKeys: []
    tableData: []
  },
  data() {
    return {
      //tableData: [],
      showModal: false
    }
  },
  methods: {
    toggleModal() { this.$refs['addKeyModal'].showModal = true },
    async create(key) {
      this.submitInProccess = !this.submitInProccess;
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/key/keys/smpp/create`, key)
      if(res && res.status == 200){
        this.$emit('created', res.data)
      }
    },
    editRow(){
    },
    async saveRow(index){
      this.submitInProccess = !this.submitInProccess;
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/key/keys/smpp/update`, this.tableData[index])
      if(res && res.status == 200){
        this.$emit('updated', { ...{ index: index },...this.tableData[index] } )
      }
    }
  }
}
</script>


<style>

.no-background{
  background: none;
}

.my-padding{
  padding-left:30px;
  padding-right:30px;
}

hr{
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}


#smpp-apis .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  background:none !important;
  border:none !important;
}

#smpp-apis .el-table--border::after, .el-table--group::after, .el-table::before{
  background:none !important;
  border:none !important;
}

.el-table td.el-table__cell{
  background:none !important;
  border-bottom: rgba(255, 255, 255, 0.1) !important;
}

</style>