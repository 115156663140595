var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',[_c('h5',{staticClass:"info-text"},[_vm._v(" Let's start with the basic information (with validation) ")]),_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"First Name","addon-left-icon":"tim-icons icon-single-02","error":errors[0]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Email","addon-left-icon":"tim-icons icon-email-85","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Last Name","addon-left-icon":"tim-icons icon-caps-small","error":errors[0]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Phone","addon-left-icon":"tim-icons icon-mobile","error":errors[0]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1),_c('div',{staticClass:"col-sm-10"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Address","addon-left-icon":"tim-icons icon-square-pin","error":errors[0]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }