<template>
  <div>
    
      <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h5 class="card-category">Summery chart</h5>
              <h3 class="card-title">Performance</h3>
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
                <label
                  v-for="(option, index) in bigLineChartCategories"
                  :key="option.type"
                  class="btn btn-sm btn-primary btn-simple"
                  :class="{ active: bigLineChart.activeIndex === option.type }"
                  :id="index"
                >
                  <input
                    type="radio"
                    @click="initBigChart(option.type)"
                    name="options"
                    autocomplete="off"
                    :checked="bigLineChart.activeIndex === option.type"
                  />
                  <span class="d-none d-sm-block">{{ option.name }} </span>
                  <span class="d-block d-sm-none">
                    <i :class="option.icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </template>

        <div v-if="FilterChartData.data[bigLineChart.activeIndex].length" class="chart-area">
          <line-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="bigLineChart.chartData"
            :gradient-colors="bigLineChart.gradientColors"
            :gradient-stops="bigLineChart.gradientStops"
            :extra-options="bigLineChart.extraOptions"
          >
          </line-chart>
        </div>
        <div style="padding:25px" v-if="!FilterChartData.data[bigLineChart.activeIndex].length" class="chart-area">
        
            
            <p>No data to show. Consider expending your search dates</p>
        
        </div>
      </card>
    
  </div>
</template>

<script>

import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';

let bigChartData = [
  //[100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100]
  []
]
let bigChartLabels = []
//['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  components: {
    LineChart
  },
  props:['FilterChartData'],
  watch:{
    'FilterChartData.data': function (o,n) {
    
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: this.FilterChartData.data[this.bigLineChart.activeIndex]
        }],
        labels: this.FilterChartData.labels
      };
      
      this.bigLineChart.chartData = chartData;
      
      
    }
  },
  data() {
    return {
      bigLineChart: {
        activeIndex: 'total',
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: [  ]
          }],
          labels: [ ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
    }
  },
  methods: {
    initBigChart(index) {
      
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: this.FilterChartData.data[index]
        }],
        labels: this.FilterChartData.labels
      };
      //this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: 'Total', type:"total", icon: 'tim-icons icon-send' }, 
        { name: 'Delivered', type:"Delivered", icon: 'tim-icons icon-check-2' }, 
        { name: 'Failed', type:"Failed", icon: 'tim-icons icon-simple-remove' }, 
        { name: 'In progress', type:"Processing", icon: 'tim-icons icon-triangle-right-17' },
        { name: 'Queued', type:"Queued", icon: 'tim-icons icon-delivery-fast' },
        { name: 'No delivery receipt', type:"Other", icon: 'tim-icons icon-alert-circle-exc' }
    ];
    }
  },
}
</script>

<style>

</style>