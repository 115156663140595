<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <card class="card card-login  card-white">
    <template slot="header">
      <img class="card-img" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Login</h4>
    </template>
      <div>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            required
            v-model="email"
            type="email"
            label="Email address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>

         <ValidationProvider
           name="password"
           rules="required|min:5"
           v-slot="{ passed, failed, errors }"
         >

         <base-input
          toggleIcon
          addonRightIcon="eye"
          addonRightIconToggle="eye-off"
           v-model="password"
           type="password"
           label="Password"
            :error="errors[0]"
           :class="{ 
                'has-success': !errors[0] && user.newPassword, 
                'has-danger': errors[0] 
            }"
           >
         </base-input>

        </ValidationProvider>
        <div class="category form-category">* Required fields</div>
      </div>

      <template slot="footer">
        
        <base-button native-type="submit" type="primary" round block size="lg">
        Login
        </base-button>
        <div class="row">
          <div class="col-lg-5 d-flex justify-content-start">
            <a @click=Back() class="nav-link text-primary" style="cursor:pointer">
              <i class="tim-icons icon-minimal-left"></i> Login options
            </a>
          </div>
          <div class="col-lg-7 d-flex justify-content-end">
            <a @click=forgotPass() class="nav-link text-primary" style="cursor:pointer">
              Fotgot my password
              <i class="tim-icons icon-minimal-right"></i>
            </a>
          </div>
        </div>
        
      </template>
    </card>
  </form>
 </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    forgotPass: function () {
        this.$emit('passwordRecovery', false);
    },
    Back: function () {
        this.$emit('emailLogin', false);
    },
    submit() {
      alert("Form has been submitted!");
    }
  }
};
</script>
<style></style>
