<template>
    <div>
        <DeveloperDashboard :user="user" v-if="user && user.user_is_developer"/>
        <MarketingDashboard :user="user" v-else/>
    </div>
</template>
<script>
import DeveloperDashboard from './DeveloperDashboard.vue'
import MarketingDashboard from './MarketingDashboard.vue'
export default {
    components:{
        DeveloperDashboard,
        MarketingDashboard
    },
    watch: {
        "$store.state": {
            handler: function (ov,nv){
                this.user = nv.user
            },
            deep: true
        }
    },
    data() {
        return {
            timeOfDay: null,
            user: this.$store.getters.user
        }
    },
    methods: {
        
    },
    created () {
        

    }
}
</script>
<style >

.btn span{
    display:inline-block;
}

.db-height{
    min-height:250px !important;
}

.row-height{
    min-height:120px !important;
}

.h-90{
    height: 90% !important
}

.h-95{
    height: 95% !important
}

.h-70{
    height: 70% !important
}

.h-40{
    height: 40% !important
}

.h-30{
    height: 30% !important
}
</style>