<template>
  <div class="row" v-loading="submitInProccess" >
    
    <div class="col-lg-12">
        
        <card style="min-height:600px">
            <template slot="header">
                <h4 class="card-title">Online Networks</h4>
            </template>
            <!--
            <el-select
                class="select-primary mb-3 pagination-select"
                v-model="perPageSelect"
                @change="perPerChange"
                placeholder="Per page"
            >
                <el-option
                class="select-primary"
                v-for="item in [5,10,20,50]"
                :key="item"
                :label="item"
                :value="item"
                >
                </el-option>
            </el-select>
            -->
            <base-input
                toggleIcon
                addonRightIcon="close"
                addonRightIconToggle="close"
                v-model="searchQuery"
                @keyup.stop.native="filterData($event)"
                @right-icon-click="clearSearch"
            >
            </base-input>
            
                
            <div class="d-flex justify-content-center" style="height:400px">
                <el-table :data="dataArray" v-if="dataArray.length">
                    
                    <el-table-column prop="" label="" width="40px">
                        <div slot-scope="props">
                            <base-checkbox
                            :checked="props.row.checked"
                            @input="addOperator(props)"        
                            ></base-checkbox>
                        </div>
                        
                    </el-table-column>

                    <el-table-column
                        prop="country"
                        label="Country">
                        <div slot-scope="props">
                            <span style="width:75px;white-space: nowrap;">
                                {{ props.row.country }}
                            </span>
                        </div>
                    </el-table-column>
                    

                    <el-table-column
                        prop="network"
                        label="Network">
                        <div slot-scope="props">
                            <span style="width:75px;white-space: nowrap;">
                                {{ props.row.network }}
                            </span>
                        </div>
                    </el-table-column>

                    <el-table-column
                        prop="mccmnc"
                        label="MccMnc">
                    </el-table-column>
                    
                    <el-table-column
                        width="120px"
                        prop=""
                        label="Ported">
                        <div slot-scope="props">
                            <!--
                            <country-flag :country='props.row.isoAlpha2' size='normal'/>
                            -->
                            {{ props.row.mccmncOriginal ? getOpName(props.row.mccmncOriginal) : 'No' }}
                        </div>    
                    </el-table-column>


                </el-table>
                <h5 v-else>
                    Please try again soon
                </h5>
            </div>
            </br>
            
            <div class="d-flex justify-content-center">
                
                    <base-pagination
                        class="pagination-no-border"
                        v-model="currentPage"
                        :page="page"
                        :per-page="perPage"
                        :total="totalRecords"
                        @input="pagination">
                    </base-pagination>
            </div>
            
        </card>
        
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Loading } from 'element-ui';
import { BasePagination} from '@/components';
import { BaseCheckbox, BaseRadio } from 'src/components/index';
import CountryFlag from 'vue-country-flag'
import mccmnc from '../../../json/mcc-mnc.json'
export default {
    components: {
        BaseCheckbox,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        CountryFlag,
        BasePagination
    },
    computed: {
        /*
        queriedData() {
            
            let result = this.dataArray;
            this.filteredDataArray= this.dataArray;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        total() {
            return this.dataArray.length > 0
                ? this.dataArray.length
                : this.dataArray.length;
        }
        */
    },
    data(){
        return {
            perPageSelect: 5,
            searchQuery: null,
            filteredDataArray: [],
            currentPage: this.page
        }
    },
    props:['dataArray', 'totalRecords', 'page', 'perPage', 'pageCount', 'submitInProccess'],
    methods:{
        filterData(event){
            this.$emit('searchData', this.searchQuery);
        },
       
        getOpName(lmccmnc){
            let operator = mccmnc.find(elm => (elm.mcc + elm.mnc) == lmccmnc)
            return operator ? operator.network : lmccmnc;
            
        },
        clearSearch(){            
            this.searchQuery = null
            this.$emit('searchData', this.searchQuery);
        },
        addOperator(rowData){
            
            this.$emit('operatorAdded', rowData);
        },
        pagination(page){  
            this.$emit('pagination', page);
        },
        perPerChange(){
            this.$emit('perPerChange', this.perPageSelect);
            
        }
    }
}
</script>

<style >
#loading {
  min-height: 200px;
  display: flex;
  align-items: center;
}

.el-loading-spinner .path {
  stroke: #66615b !important;
}

.el-loading-mask {
  background: transparent !important;
}
.el-table th.el-table__cell{
    background-color: transparent !important;
}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    border-bottom:none !important;
}
</style>