<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row">
      <div class="col-lg-12">
        <router-link 
          class="btn btn-primary btn-simple" 
          to="/campaigns/new" tag="button">  
            <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; New Campaign
        </router-link>
       
        <base-button @click="loadData" simple type="primary">
         <i class="fa fa-redo" aria-hidden="true"></i> &nbsp; Refresh
        </base-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <Table @action="action" :tblData="data" :loading="loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Table from './Componenets/Table.vue';
export default {
  components: {
    Table
  },
  data(){
    return {
      data: [],
      loading: false
    }
  },
  created(){
    this.loadData()
  },
  methods: {
    async action(data){
      console.log(data)
      
      this.loading = !this.loading
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.get(`${api_endpoint}/campaigns/${data.action}/${data.camp.id}`)
      if(res && res.status == 200){ 
        const type = res.data.status ? 'success' : 'error'
        const text = res.data.status ? `${data.action}ed` : 'Something went wrong'
        this.$toast[type](`campaign ${data.camp.campaign_id} ${text}`);
      }
      this.loading = !this.loading

    },
    async loadData(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.get(`${api_endpoint}/campaigns/read`)
      if(res && res.status == 200){
        this.data = res.data
      }
    }
  }
}
</script>

<style>

</style>