<template>
    <div class="row justify-content-center"> 
        <div class="col-lg-12 col-md-12 col-sm-12" style="max-width:1300px">
            <div class="row">
                <div class="col-lg-12">
                    <Tiles :user="user"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tiles from './Marketing/Tiles.vue'
export default {
    components: {
        Tiles
    },
    props: ['user'],
}
</script>

<style>
    
</style>