<template>
  <ValidationObserver  v-slot="{ handleSubmit, valid }">
    <form ref="form" @submit.prevent="handleSubmit(submit)">
      <div class="row">
        <div class="col-lg-12">
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed, errors }">
              <base-input label="Specify Timezone">
                <el-select
                    class="select-primary"
                    size="large"
                    placeholder="Timezone Select"
                    v-model="timeZone"
                  >
                    <el-option
                      v-for="option in timzeZones"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                </el-select>
              </base-input>
              <span class="local-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
      </div>
       <div class="row">
        <div class="col-lg-12">
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed, errors }">
              <base-input 
                label="Specify Time">
                <el-time-select 
                   
                  :picker-options="pickerOptions"
                  :disabled="!timeZone"
                  placeholder="Time Picker" v-model="timePicker">
                </el-time-select>
              </base-input>
              <span class="local-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed, errors }">
              <base-input label="Specify Date">

                <el-date-picker
                  :disabled="!timePicker"
                  v-model="datePicker"
                  :picker-options="datePickerOptions"
                  type="date"
                  placeholder="Date Picker"
                  align="center">
                </el-date-picker>

              </base-input>
              <span class="local-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
      </div>
     
      <button type="submit" style="display:none" ref="myButton" @click="submit(valid)">Click me</button>

    </form>
  </ValidationObserver>
</template>

<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
export default {
  components:{
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data(){
    return {
      datePickerOptions:{
        disabledDate(time) {
          return time.getTime() < new Date(new Date().toDateString()).getTime();
        }
      },
      pickerOptions: {
        step: '00:5',
        start: '06:00', // Set the start time
        end: '23:00'    // Set the end time
      },
      datePicker: '',
      timePicker: '',
      timeZone: '',
      timzeZones: [
        {
          value: 'CET',
          label: 'Central European Time'
        },
        {
          value: 'EET',
          label: 'Eastern European Time'
        },
        {
          value: 'WET',
          label: 'Western European Time'
        },
        {
          value: 'JST',
          label: 'Japan Standard Time'
        },
        {
          value: 'CST',
          label: 'China Standard Time'
        },
        {
          value: 'EST',
          label: 'Eastern Standard Time (US)'
        },
        {
          value: 'PST',
          label: 'Pacific Standard Time (US)'
        },
        {
          value: 'GMT',
          label: 'GMT'
        },
        {
          value: 'UTC',
          label: 'UTC'
        }
      ]
    }
  },
  methods: {
    click(){
      this.$refs['myButton'].click()
    },
    getFormatedDate(preDate){
      const formatter = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        //timeZone: this.timeZone,
        //timeZoneName: 'short',
      });
      
     
      const formattedDate = formatter.format(preDate);
      return formattedDate
    },
    submit(valid){
      const [hrs, mns]  = this.timePicker.split(/\:/)
      let preDate = new Date(this.datePicker)
      preDate.setHours(parseInt(hrs))
      preDate.setMinutes(parseInt(mns))

      if(!valid) return 
      const formatedDateByTimeZone = this.getFormatedDate(preDate)

      const conf = {
        formatedDateByTimeZone: formatedDateByTimeZone,
        datePicker: preDate,
        timePicker: this.timePicker,
        timeZone: this.timeZone
      }

      this.$emit('schedule', conf)
      
    },
    close(){
      this.datePicker = ''
      this.timePicker = ''
      this.timeZone = ''
    }
  }
}
</script>

<style>
.local-danger {
  display: block;
}
</style>
