<template>
  <div>
    <card>
        <template slot="header">
            <h4 class="card-title">All countries and operators</h4>
            <p class="card-category">Total by country by operator</p>
            <base-button size="sm" type="default" >
                <download-csv :data="FiltertilesData">
                    <mdicon name="file-export-outline" size="18"/>
                </download-csv>                
            </base-button>
        </template>
        <div class="row">
            <div class="col-md-12 ml-auto mr-auto">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td style="width:5%"></td>
                                <td>Country</td>
                                <td>Mobile Operator</td>
                                <td>Total sent</td>
                                <td>Operator processing</td>
                                <td>Delivered</td>
                                <td>Failed</td>
                                <td>Queued</td>
                                <td>No Receipt</td>
                            </tr>
                            
                            <tr v-for="(item, index) in FiltertilesData" :key="index">
                                <td style="width:5%"><country-flag :country='item.country' size='normal'/></td>
                                <td style="width:10%">{{countryCodes[item.country] ? countryCodes[item.country].name : item.country}}</td>
                                <td style="width:30%">{{item.operator}}</td>
                                <td>{{item.total}}</td>
                                <td>{{item.processing}} ({{ ((item.processing/item.total) * 100 ).toLocaleString('en', {maximumFractionDigits: 0}) }}%)</td>
                                <td>{{item.delivered}} ({{ ((item.delivered/item.total) * 100 ).toLocaleString('en', {maximumFractionDigits: 0}) }}%)</td>
                                <td>{{item.failed}} ({{ ((item.failed/item.total) * 100 ).toLocaleString('en', {maximumFractionDigits: 0}) }}%)</td>
                                <td>{{item.queued}} ({{ ((item.queued/item.total) * 100 ).toLocaleString('en', {maximumFractionDigits: 0}) }}%)</td>
                                <td>{{item.other}} ({{ ((item.other/item.total) * 100 ).toLocaleString('en', {maximumFractionDigits: 0}) }}%)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </card>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import DownloadCsv from 'vue-json-csv'
export default {
    components: {
        CountryFlag,
        DownloadCsv
    },
    props:['FiltertilesData', 'countryCodes'],
}
</script>

<style>

</style>