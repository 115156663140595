var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|customUrl"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{ref:"linkInput",class:{  
                      'has-success': !errors[0] && _vm.mediaLink,
                      'has-danger': errors[0] || !_vm.mediaLink
                  },attrs:{"type":"url","label":"Media Link (web page/external platform)","placeholder":"http[s]://yourUrl.com"},model:{value:(_vm.mediaLink),callback:function ($$v) {_vm.mediaLink=$$v},expression:"mediaLink"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('el-tooltip',{attrs:{"content":"Your media (tracking) link shortened, enables click analytics. our short URLs are typically 15-20 charachters long.","effect":"light","open-delay":300,"placement":"top"}},[_c('base-checkbox',{staticStyle:{"display":"inline-block"},model:{value:(_vm.advanced.shortUrl),callback:function ($$v) {_vm.$set(_vm.advanced, "shortUrl", $$v)},expression:"advanced.shortUrl"}},[_vm._v(" Shorten my url   "),_c('i',{staticClass:"fa fa-magic text-info",attrs:{"aria-hidden":"true"}})])],1),_vm._v("   "),_c('i',{staticClass:"fa fa-exclamation-circle text-warning",attrs:{"aria-hidden":"true"}}),_vm._v("  "),_c('p',{staticStyle:{"display":"inline"}},[_vm._v("Short URL length is 15-20 characters replacing your media link in message body.")])],1)])],1)]}}])})],1)]),_c('br'),_c('base-button',{staticClass:"btn btn-simple",attrs:{"type":"primary"},on:{"click":function($event){return _vm.toggle('external')}}},[_vm._v(" Dynamic Contact information ")]),_c('base-button',{staticClass:"btn btn-simple",attrs:{"type":"primary"},on:{"click":function($event){return _vm.toggle('advanced')}}},[_vm._v(" Advanced options ")])],1),_c('Modal',{ref:"external-modal",attrs:{"type":"external","mediaLink":_vm.mediaLink},on:{"done":_vm.done}}),_c('Modal',{ref:"advanced-modal",attrs:{"type":"advanced"},on:{"done":_vm.done}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Add the link to your web site or your external polatform, "),_c('br'),_vm._v(" Click on Advanced to view additional options. ")])}]

export { render, staticRenderFns }