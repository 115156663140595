<template>
    <base-button @click="exportCsv" type="primary" class="ml-3">
        Download CSV
    </base-button>
</template>

<script>
export default {
    props: {
        rows:{
            type: Array,
            default: () => []
        },
        headers: {
            type: Array,
            default: () => []
        },
        fileName: {
            type: String,
            default: 'file.csv'
        }
    },
    methods: {
        exportCsv(){
            let csvText = this.headers.join(',') + "\n"            
            for(const row of this.rows) csvText += Object.values(row).join(',') + "\n"
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvText);
            anchor.target = '_blank';
            anchor.download = `${this.fileName}.csv`;
            anchor.click();
        },
    }
}
</script>

<style>

</style>