<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12">
        <simple-wizard 
        :loading="submitInProccess"
        :downloadLink="downLoadLink"
        @wizzard-complete="wizardComplete"
        @download-client-created-invoice="downLoadInvoice()"
        finishButtonText="Pay Securly">
          <template slot="header">
            <h3 class="card-title">Add funds to your account</h3>
            <h3 class="description">
              If you have any questions, please contact our support team <br> support@message-deck.com
            </h3>
          </template>
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Payment method</p>
            </template>
            <choose-method
              ref="step1"
              @on-validation-error="onValidatatiopError"
              @on-validated="onStepValidated" 
            ></choose-method>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <strong class="fa-stack-1x">2</strong>
              <p>Billing notes</p>
            </template>

            <credit-card
              v-if="this.wizardModel.method=='card'"
              :billingMethod=wizardModel.method
              ref="step2"
              @on-validated="onStepValidated"
            ></credit-card>

            <bank-pay-pal
              v-if="this.wizardModel.method != 'card'"
              :billingMethod=wizardModel.method
              ref="step2"
              @on-validated="onStepValidated"
            ></bank-pay-pal>
            
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <strong class="fa-stack-1x">3</strong>
              <p>Invoice information</p>
            </template>
            <billing-information
            @on-validated="onStepValidated"
            ref="step3"/>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <strong class="fa-stack-1x">4</strong>
              <p>Amount to add</p>
            </template>
            <choose-amount
            :someData="this.wizardModel"
            @on-validated="onStepValidated"
            ref="step4"/>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
              <strong class="fa-stack-1x">5</strong>
              <p>Payment</p>
            </template>
            <summery 
            :summeryData="summeryData"
            @on-validated="onStepValidated"
            ref="step5"></summery>
          </wizard-tab>
        </simple-wizard>
        <div v-if="isError" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="info">
            <strong>{{erroMessage}}</strong>
          </base-alert>
        </div>
        </div>
        <div v-if="paymentRef" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="info">
            <strong>A new invoice was created</strong><br>
            <mdicon name="information-outline" size="18" /> To download the invoice please click the download button. <br>
            <mdicon name="information-outline" size="18" /> Please add the following Payment reference:	{{paymentRef}} when you wire the amount.<br>
            <mdicon name="information-outline" size="18" /> As soon as we get transfer confirmation, your balance will be updated. This process can take 3-5 working days.<br>
          </base-alert>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ChooseMethod from './ChooseMethod.vue';
import BankPayPal from './BankPayPal.vue';
import CreditCard from './CreditCard.vue';

import BillingInformation from './BillingInformation.vue';
import ChooseAmount from './ChooseAmount.vue';
import Summery from './Summery.vue';

import swal from 'sweetalert2';
import { SimpleWizard, WizardTab, BaseAlert } from 'src/components';
var easyinvoice = require('easyinvoice');


export default {
  components: {
    ChooseMethod,
    BankPayPal,
    CreditCard,
    BillingInformation,
    ChooseAmount,
    Summery,
    SimpleWizard,
    WizardTab,
    BaseAlert
  },
  data() {
    return {
      proforma: {},
      pdfData: null,
      title: 'sup',
      wizardModel: {},
      summeryData: {},
      isError: false,
      erroMessage: null,
      submitInProccess:false,
      downLoadLink: "",
      paymentRef: ""
    };
  },
  methods: {
    onValidatatiopError(isError, value){
      this.isError = !this.isError
      this.erroMessage = value
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      
      this.isError = false
      this.wizardModel = { ...this.wizardModel, ...model };
      
      // Create summery object only after all of the wizzard data is ready
      if(
          this.wizardModel && 
          this.wizardModel.billing_accounts_uuid &&
          this.wizardModel.method && 
          this.wizardModel.amount
      ){
        this.summeryData = this.wizardModel
      }
      
    },
    wizardComplete() {
      
      
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      this.wizardModel.billing_invoice_date = this.wizardModel.billing_invoice_due_date = yyyy + '-' + mm + '-' + dd;
      //console.log(this.wizardModel);
      this.submitInProccess = !this.submitInProccess


      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      //this.$http.post(api_endpoint + '/billing/create-wave-invoice', this.wizardModel)
      //this.$http.post(api_endpoint + '/billing/create-proforma-invoice', this.wizardModel)
      this.$http.post(api_endpoint + '/billing/invoice/proforma', this.wizardModel)
      .then( async (resp) => {
        this.submitInProccess = !this.submitInProccess;

        this.downLoadLink = `data:application/pdf;base64,${resp.data.encodedInvoice}`;
        //this.paymentRef = resp.data.proforma_invoice_number
        this.paymentRef = resp.data.proforma_invoice_number
        this.proforma = resp.data
        /*
        var docUrl = document.createElement('a');
        docUrl.href=`data:application/pdf;base64,${resp.data}`
        docUrl.setAttribute('download', 'invoice.pdf');   
        document.body.appendChild(docUrl);
        docUrl.click();
        */
        /*
        this.paymentRef = this.paymentRef = this.wizardModel.billing_entity_name.replace(/\s/g, '').substr(0,7).toUpperCase() + '-' + resp.data.createdProformaInvoice[0].proforma_invoice_wave_invoice_number
        this.downLoadLink = resp.data.createdProformaInvoice[0].proforma_invoice_wave_download_link
        this.$toast.success("A new invoice was added, goto billing history to view");
        */
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
      });
      


    },
    async downLoadInvoice(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      
      const res = await this.$http.post(api_endpoint + '/billing/billing/download-proforma-invoice', this.proforma)
      if(res && res.status == 200){
        var docUrl = document.createElement('a');
        docUrl.href=`data:application/pdf;base64,${res.data}`
        docUrl.setAttribute('download', this.proforma.payment_proforma_invoice_number + '.pdf');   
        document.body.appendChild(docUrl);
        docUrl.click();
      }
      /*
      var docUrl = document.createElement('a');
      docUrl.href = this.downLoadLink
      console.log(docUrl.href)
      docUrl.setAttribute('download', this.paymentRef + '.pdf');   
      document.body.appendChild(docUrl);
      docUrl.click();
      */
      //window.open(this.downLoadLink, '_blank');
    }
  }
};
</script>
