<template>
    <div class="row">
        <div class="col-lg-12">
            <card type="chart">
                <template slot="header">
                    <h4 class="card-title">Performance Chart by {{groupBy}}</h4>
                    <h5 class="card-category">Clicks In Period</h5>
                </template>
                
                <div v-if="bigLineChart.chartData.datasets[0].data.length">
                    <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                    <line-chart
                        v-else
                        style="height: 250px"
                        ref="bigChart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
                    </line-chart>
                </div>
                <div class="d-flex justify-content-center chart-area" style="padding:25px" v-else>
                    <p>
                        <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                        No data to show. Consider expending your search dates
                    </p>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';


let bigChartDatasetOptions = {
    fill: true,
    borderColor: config.colors.primary,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: config.colors.primary,
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: config.colors.primary,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4,
}

export default {
    components: {
        LineChart
    },
    props: ['data', 'loading', 'groupBy'],
    watch:{
        'data': {
            handler: function (newVal,oldVal) {
                this.bigLineChart.chartData = {
                    datasets: [
                        {
                            ...bigChartDatasetOptions,
                            data: Object.values(newVal).map(item => item.clicks)
                        }
                    ],
                    labels: Object.keys(newVal)
                }

            },
            deep: true
        }
    },
    data(){
        return {
            bigLineChart: {
                activeIndex: 'total',
                chartData: {
                    datasets: [
                        {
                            ...bigChartDatasetOptions,
                            data: [  ]
                        }
                    ],
                    labels: [ ]
                },
                extraOptions: chartConfigs.purpleChartOptions,
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.4, 0],
                categories: []
            }
        }
    }
}
</script>

<style>

</style>