<template>
  <div class="container" style="max-width: 1700px !important">
    
    
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto text-center">
        <h3 class="title">Service level pricing</h3>
        <p class="description">
          You will enjoy our system wide upgrades free of charge at all times.
        </p>
      </div>
    </div>

    <br>

    <div class="row">
      
      <div class="col-lg-4 col-md-6">
        <card
          type="pricing"
          class="card-success card-white my-pricing-card"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">basic</h1>
          <img class="card-img" src="/img/card-success.png" alt="Image" />
          <ul class="list-group my-margin">
            <li class="list-group-item">24/7 Support</li>
            <li class="list-group-item">Priority email assistance</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>&euro;</span>100</h3>
            <h5 class="text-on-back">100</h5>
            <p class="plan">Monthly <br> Min 12 Months</p>
            
          </div>

          <base-button slot="footer" round type="success" class="btn-just-icon">
             <a style="color:white" href="mailto:support@message-deck.com?subject=I%20want%20to%20upgrade%20to%20basic%20SLA">
              Get started
            </a>
          </base-button>
        </card>
      </div>
      <div class="col-lg-4 col-md-6">
        <card
          
          type="pricing"
          class="card-success card-white card-raised"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">pro</h1>
          <img class="card-img" src="/img/card-primary.png" alt="Image" />
          <ul class="list-group my-margin">
            <li class="list-group-item">24/7 Support</li>
            <li class="list-group-item">Skype assistance in real time</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>&euro;</span>200</h3>
            <h5 class="text-on-back">200</h5>
            <p class="plan">Monthly<br> Min 12 Months</p>
          </div>
          <base-button slot="footer" round type="primary" class="btn-just-icon">
            <a style="color:white" href="mailto:support@message-deck.com?I%20want%20to%20upgrade%20to%20pro%20SLA">
              Get started
            </a>
          </base-button>
        </card>
      </div>
      <div class="col-lg-4 col-md-6">
        <card
          type="pricing"
          class="card-danger card-white "
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">free</h1>
          <img class="card-img" src="/img/card-danger.png" alt="Image" />
          <ul class="list-group my-margin">
            <li class="list-group-item">Email assistance</li>
            <li class="list-group-item">Extended availibilty</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>&euro;</span>0</h3>
            <h5 class="text-on-back">0</h5>
            <p class="plan">Free</p>
          </div>

          <base-button  slot="footer" round type="danger" class="btn-just-icon">
             <a style="color:white" href="mailto:support@message-deck.com?subject=I%20want%20to%20upgrade%20my%20SLA">
              Change
            </a>
          </base-button>
        </card>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-lg-12">
        <card>
          <template slot="header">
              <h3 class="card-title">SMS pricing</h3>
              <p class="card-category">Your pricing for sending SMS by API/Campaign token</p>
          </template>

          <pricing-table :keys="keys"/>
        </card>
      </div>
    </div>
    
  </div>
</template>
<script>
import Pricing from './Pricing.vue';
import  PricingTable  from './PricingComponents/PricingTable.vue';

export default {
  name: 'pricing-page',
  components: {
    PricingTable,
    Pricing
  },
  data(){
    return {
      keys: []
    }
  },
  async created(){
    await this.getApiKeys()
  },
  methods: {
    fixSmppKeys(smppKeys){

      return smppKeys.map(key => {
        return {
            "http_api_jwt_type": key.key_type,
            "http_api_jwt_tp": key.key_tp,
            "http_api_jwt_uuid": key.key_uuid,
            "http_api_jwt_short_token": key.key_accounting_token,
            "http_api_jwt_description": key.key_desc,
            "http_api_jwt_status": true,
            "http_api_jwt_mode": key.key_mode
        }
      })
    },
    async getApiKeys(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        //this.$http.get(api_endpoint + '/api/get-http-api-keys'),
        //this.$http.get(api_endpoint + '/api/get-smpp-api-keys')
        this.$http.get(api_endpoint + '/api/keys/http/read'),
        this.$http.get(api_endpoint + '/api/keys/smpp/read')
      ]).then(([result1, result2]) => {
        this.keys = this.keys.concat(result1.data)
        const digestSmppKeys = this.fixSmppKeys(result2.data)
        this.keys = this.keys.concat(digestSmppKeys)
                

      }).catch(function(err){
        console.log('Cant fetch API keys', err);
      });
    },
  }
};
</script>
<style>
.my-margin{
margin: 140px auto 40px !important;
}
</style>
