<template>
    <modal
      :show.sync="show" 
      type="notice"
      @close="close"
      id="modal-schedule">
        
        <h4 slot="header" class="title title-up" v-if="repeat">
            {{altTitle}}<br><small>{{altSubTitle}}</small>
        </h4>
        <h4 slot="header" class="title title-up" v-else>
            {{title}}<br><small>{{subTitle}}</small>
        </h4>

        <Recurring ref="repeat" v-if="repeat" @recurring="recurring" />
        <Schedule ref="schedule" v-else @schedule="date" />

        
        <template slot="footer">
            <base-button 
            @click="click()"
            :loading="proccessing" 
            type="primary" 
            class="btn-fill">
            Done
            </base-button>
        </template>
      

    </modal>
</template>

<script>
import { Modal } from 'src/components';
import Recurring from './Recurring.vue'
import Schedule from './Schedule.vue'

export default {
    components: {
        Modal,
        Recurring,
        Schedule
    },
    props: {
        repeat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            proccessing: false,
            show: false,
            title: `Schedule By Date`,
            subTitle: `Schedule A Campaign By Date`,
            altTitle: `Schedule Recurring`,
            altSubTitle: `Schedule A Recurring Campaign`,
            type: null
        }
    },
    
    methods: {
        getType(){
            return this.repeat ? 'repeat' : 'schedule'
        },
        toggle(){
            this.show = !this.show
        },
        close(){
            this.$refs[this.getType()].close()
        },
        click(){
            this.$refs[this.getType()].click()
        },
        recurring(setting){
            this.$emit('done', {
                type: this.getType(),
                setting: setting
            })
            this.toggle()
        },
        date(setting){
            this.$emit('done', {
                type: this.getType(),
                setting: setting
            })
            this.toggle()
        }
    }
}
</script>

<style>
    #modal-schedule input {
        background: white !important;
        color: black !important;
    }

    #modal-schedule input:hover {
        color: black !important;
    }
</style>