<template>
  <div class="row">
    <div class="col-lg-12">
        <card>
            <template slot="header">
                <div class="row">
                    <div class="col-lg-6">
                        <h5 class="card-category">Groups</h5>
                        <h3 class="card-title">Lists</h3>
                    </div>
                    <div class="col-lg-6 text-right">
                        
                            <base-button type="primary" class="btn btn-simple" @click="toggle()">
                                <i class="fa fa-plus"></i> &nbsp; Upload Contacts
                            </base-button>
                        
                    </div>
                </div>
                
            </template>

            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <base-input label="Search" v-model="search" @input="searchInput"></base-input>
                    </div>
                </div>



                <div class="row">
                    <div class="col-lg-12">
                        <div ref="scrollContainer" class="scroll-container">
                            <div style="width:95% !important;" 
                                v-for="(item, index) in filterData" :key="index">
                                <card :class="{ 'group-card active': index == active, 'group-card': index != active }"
                                    style="margin-top:10px">
                                    <div slot="header" class="card-header-success">
                                        <mdicon v-if="index == active" name="folder-open-outline" size="22"/> 
                                        <mdicon v-else name="folder-outline" size="22"/> 
                                        &nbsp;{{ item.groups_name }} &nbsp; <i v-if="item.refresh" class="fa fa-spinner fa-spin" style="color: white"></i>
                                    </div>
                                    <p class="" style="height:30px;cursor: pointer;" @click="toggleActive(item, index)">
                                        {{ item.total }} Contacts
                                    </p>
                                    <div slot="footer">
                                        <el-tooltip v-if="item.groups_name != 'all'" content="Delete Group" effect="light" :open-delay="300" placement="top">
                                            <base-button :loading="submitInProccess" size="sm" simple type="danger" @click="deleteGroup(item)">
                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                            </base-button>
                                        </el-tooltip>
                                    </div>
                                </card> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            
        </card>

    </div>
    <UploadContactsModalVue 
        @uploadedContacts="getGroups"
        continueButtonText="continue"
        ref="contactsModal"/>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { BaseTable } from '@/components';
//import CsvUpload from 'src/components/CsvUpload.vue';
import UploadContactsModalVue from './UploadContactsModal.vue'
export default {
    components: {
        BaseTable,
        //CsvUpload
        UploadContactsModalVue
    },
    props:['groups', 'deleteGroup', 'getGroups', 'submitInProccess'],
    mounted() {
        // Initialize Perfect Scrollbar when the component is mounted
        this.ps = new PerfectScrollbar(this.$refs.scrollContainer, {
            minScrollbarLength: 20,
        });
    },
    watch: {
        'groups': {
            handler: function(nv){
                this.filterData = nv
            },
            deep:true
        }
    },
    methods:{
        searchInput(string){
            this.filterData = [...this.groups].filter(group=>group.groups_name.toLowerCase().includes(string))
        },
        toggle(){
            return this.$refs['contactsModal'].toggle();
        },
        toggleActive(item, index){           
            if(item.refresh) item.refresh = false
            this.active = index
            this.$emit('getList', item)
        }
    },
    data() {
        return {
            filterData: [],
            search: null,
            active: -1,
            uploadFile: false,
        }
    }
}
</script>

<style scoped>
.input-group-prepend .input-group-text {
    padding: 6px !important;
}
.group-card:hover{
    background: #344675;
}

.group-card:focus {
    background:#344675;
}

.active {
    background:#344675;
}

.group-card{
    box-shadow:none !important;   
    margin:0px;
    border-radius: 0px;
    border-bottom:1px solid #344675;
}

.scroll-container {
    position: relative;
    max-height: 805px; 
    overflow: hidden;
    box-sizing: border-box;
}


    @media screen and (max-width: 600px) {
    .scroll-container {
        max-height: 400px; 
    }

    
    }


</style>