<template>
    <div class="row">
        <div class="col-lg-5">
            <i class="fa fa-circle text-primary" aria-hidden="true"></i> &nbsp; <b><u>Summery</u></b>
            <div class="p-2">
                <base-table :data="[
                    {
                        value: dataObject.campaign_tracking_link,
                        name: 'Media (Tracking) Link',
                        icon: 'fa fa-link text-primary',
                        shortenUrl: dataObject.campaign_shorten_url,
                        filterCrawlers: dataObject.campaign_filter_crawlers,
                        appendOperator: dataObject.campaign_append_operator,
                        appendPrice: dataObject.campaign_append_price,
                        appendGeoData: dataObject.campaign_append_geo_data,
                    },
                    {
                        value: `${dataObject.campaign_local_originator ? 'Random Local' : dataObject.campaign_mt_sender} `,
                        name: 'Originator (Sender ID)',
                        icon: 'fa fa-bullhorn text-primary'
                    },
                    {
                        value: dataObject.campaign_mt_text,
                        name: 'Message Text',
                        icon: 'fa fa-comment text-primary'
                    },
                    {
                        value: getRotation(),
                        name: 'Rotate Contacts',
                        icon: 'fa fa-users text-primary'
                    },
                ]" thead-classes="text-primary">

                    <template slot="columns" slot-scope="{ columns }">
                        <th style="width:200px !important;">Item</th>
                        <th>Value</th>
                    </template>
                    <template slot-scope="{ row, index }">
                        <td style="vertical-align: top;">
                            <i :class="row.icon" aria-hidden="true"></i>
                            {{ row.name }}
                        </td>
                        <td style="vertical-align: top;">
                            <div class="row"><div class="col-lg-12">{{row.value}}</div></div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <el-tooltip 
                                        v-if="row.shortenUrl"
                                        content="Your media (tracking) link will be replaced with a short link" 
                                        effect="light" :open-delay="300" placement="top">
                                        <i class="fa fa-magic text-info" aria-hidden="true"></i> 
                                    </el-tooltip>
                                    &nbsp;
                                    <el-tooltip 
                                        v-if="row.filterCrawlers"
                                        content="Filter out apps that crawl your link, not all apps are filtered" 
                                        effect="light" :open-delay="300" placement="top">
                                        <i class="fa fa-eye-slash text-info" aria-hidden="true"></i> 
                                    </el-tooltip>
                                    &nbsp;
                                    <el-tooltip 
                                        v-if="row.appendOperator"
                                        content="Append operator mcc, mnc and name to your media link, 0.002EUR/SMS." effect="light" :open-delay="300" placement="top">
                                            <i class="fa fa-mobile text-info" aria-hidden="true"></i>
                                    </el-tooltip>
                                    &nbsp;
                                    <el-tooltip 
                                        v-if="row.appendPrice"
                                        content="Append the price of the message to your media link" effect="light" :open-delay="300" placement="top">
                                            <span class="text-info">&euro;</span>
                                    </el-tooltip>
                                    &nbsp;
                                    <el-tooltip 
                                    v-if="row.appendGeoData"
                                    content="Append IP city and country ISO 2 letter code to your media link, 0.002EUR/SMS. (city not always availble)" effect="light" :open-delay="300" placement="top">
                                        <i class="fa fa-globe text-info" aria-hidden="true"></i> 
                                    </el-tooltip>
                                </div>
                            </div>
                        </td>
                    </template>

                </base-table>  
            </div>
        </div>
        <div class="col-lg-3">
            <i class="fa fa-filter text-primary" aria-hidden="true"></i> &nbsp; <b><u>Contact Filters</u></b> 
            <br/><br/>

            <span v-if="dataObject.filters && dataObject.filters.length">
                <el-tag
                    v-for="(item, index) in dataObject.filters" :key="index"
                    :closable="false"
                    :close-transition="false"
                    class="filter-tags">
                    {{item.filter_key}} {{item.filter_action}} {{item.filter_value}}
                </el-tag>
            </span>
            <div v-else>
                <p>No contact filters</p>
            </div>
        </div>
        <div class="col-lg-4">
            <i class="fa fa-comment text-primary" aria-hidden="true"></i> &nbsp; <b><u>Campaign Description</u></b> 
            <br/><br/>
            {{dataObject.description}}
        </div>
    </div>
</template>

<script>
import { Tag } from 'element-ui'
import { BaseTable } from 'src/components'
export default {
    components: {
        [Tag.name]: Tag,
        BaseTable
    },
    props: {
        dataObject: Object
    },
    methods:{
        getRotation(){
            if(this.dataObject.campaign_contact_rotate_period)
                return `By ${this.dataObject.campaign_contact_rotate_period}. Wait for ${this.dataObject.campaign_contact_rotate_value} ${this.dataObject.campaign_contact_rotate_period}s.`
            else
                return 'No Rotation'
        }
    }
}
</script>

<style>

</style>