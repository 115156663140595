<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row">
        <div class="col-lg-12" >
          <filter-area :tokenSelectData="tokensList" :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
      </div>      
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-table :submitInProccess="submitInProccess"  :FilterChartData="topTableChartData" @fiterData="filterOptions"/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-chart :submitInProccess="submitInProccess"  :FilterChartData="topLineChartData" @fiterData="filterOptions"/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-break-down :submitInProccess="submitInProccess" :countryCodes="countryCodes"  :FilterChartData="countryOpData" @fiterData="filterOptions"/>
        </div>
    </div>
  </div>
</template>

<script>
import FilterArea from './components/FilterArea.vue';
import SummeryChart from './components/SummeryChart.vue';
import SummeryTable from './components/SummeryTable.vue';
import SummeryBreakDown from './components/SummeryBreakDown.vue'
import countryByAbr from '../../../json/iso-3166-2-country.json';

export default {
    components: {
        FilterArea,
        SummeryChart,
        SummeryTable,
        SummeryBreakDown
    },
    data(){
        return {
            countryCodes: countryByAbr,
            submitInProccess: false,
            tokensList:[],
            countryOpData:[],
            topTableChartData: {
                totalSent: 0,
                totalCost: 0,
                totalParts: 0
            },
            topLineChartData: {
                labels: [],
                data: {
                    total: []
            }
        }
        }
    },
    beforeMount(){ 
        this.getApiKeys()
    },
    methods: {
        getApiKeys(){
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            Promise.all([
                this.$http.get(api_endpoint + '/api/get-http-api-keys')
            ])
            .then(([result1]) => {
                this.tokensList = result1.data
                this.submitInProccess = false
            })
            .catch((err)=>{
                this.$toast.error("Error server side data")
                console.log('There is an error', err);
            });
        },
        parseAnalyticsData(data){
            
            
            this.topTableChartData = {
                totalSent: 0,
                totalCost: 0,
                totalParts: 0
            };
            this.topLineChartData.labels = [];
            this.topLineChartData.data = {
                total: []
            };
            for(var date in data.byDate){


                this.topLineChartData.labels.push(date)
                this.topLineChartData.data.total.push(data.byDate[date].cost.toFixed(4)) 
                
                this.topTableChartData.totalSent += data.byDate[date].sent
                this.topTableChartData.totalCost += data.byDate[date].cost
            }
            this.topTableChartData.totalSent = this.topTableChartData.totalSent.toLocaleString('en', {maximumFractionDigits: 0})
            
            this.topTableChartData.totalCost = this.topTableChartData.totalCost.toLocaleString('en', {maximumFractionDigits: 4})

            this.countryOpData=[]
            if(data.byCountryByOp){
                Object.keys(data.byCountryByOp).forEach(ccName=>{
                    Object.keys(data.byCountryByOp[ccName]).forEach(opName => {
                        this.topTableChartData.totalParts +=  data.byCountryByOp[ccName][opName].parts
                        this.countryOpData.push({
                            country: ccName,
                            operator: opName,
                            sent: data.byCountryByOp[ccName][opName].sent,
                            parts: data.byCountryByOp[ccName][opName].parts,
                            cost: data.byCountryByOp[ccName][opName].cost
                        })
                    })
                })
                this.topTableChartData.totalParts = this.topTableChartData.totalParts.toLocaleString('en', {maximumFractionDigits: 0})
                this.countryOpData = this.countryOpData.sort(function(a, b){
                    return b.sent - a.sent
                })
            }

        },
        filterOptions(data){
            
            this.submitInProccess = true
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT           
            this.$http.post(api_endpoint + '/pricing/usage', data)
            .then( (response) => {
                
                this.parseAnalyticsData(response.data)
                this.$toast.success("Loaded");
                this.submitInProccess = false
            })
            .catch( (error) => {
                
            });
            
        }
    }
}
</script>

<style>

</style>