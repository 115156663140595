<template>
    <ValidationObserver  ref="observer" v-slot="{ handleSubmit, valid }">
        <form id="form" ref="form" @submit.prevent="handleSubmit(submit)">
            <base-checkbox  @input="weekly=!weekly;filterCrons()" :checked="weekly" inline>Weekly</base-checkbox>
            <base-checkbox  @input="weekly=!weekly;filterCrons()" :checked="!weekly" inline>Monthly</base-checkbox>
            
            <div class="row" v-for="(item, index) in cronsToShow" :key="index">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <ValidationProvider
                                rules="required"
                                v-slot="{ passed, failed, errors }">
                                    <base-input :label="item.label">
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        :placeholder="item.label"
                                        v-model="item.tempo"
                                        :disabled="false">

                                            <el-option v-if="item.name != 'minute'" class="select-primary" value="every" :label="`Every ${item.short}`" key="every"></el-option>
                                            <el-option class="select-primary" value="specific" :label="`Specific ${item.short}`" key="specific"></el-option>
                                            <el-option v-if="item.range" class="select-primary" value="range" :label="`Range Of ${item.short}`" key="range"></el-option>

                                        </el-select>
                                    </base-input>
                                    <span class="local-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-8" v-if="item.tempo == 'specific'">
                            <ValidationProvider
                                rules="required"
                                v-slot="{ passed, failed, errors }">
                                    <base-input :label="item.short">
                                        <el-select
                                            class="select-primary"
                                            size="large"
                                            :placeholder="item.label"
                                            v-model="item.value">

                                                <el-option
                                                v-for="option in lists[item.list]"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="`${option.label}`"
                                                :key="option.label">
                                                </el-option>

                                        </el-select>
                                    </base-input>
                                <span class="local-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-8" v-if="item.tempo == 'every'">
                            <base-input 
                                :label="item.short"
                                :disabled="true"
                                :value="`every ${item.short}`"
                                type="white"
                                :placeholder="`Every ${item.name}`">
                            </base-input>
                        </div>
                        <div class="col-lg-4" v-if="item.tempo == 'range'">
                            <ValidationProvider
                                rules="required"
                                v-slot="{ passed, failed, errors }">
                                    <base-input 
                                        label="From"
                                        type="white"
                                        placeholder="From">
                                            <el-select
                                            class="select-primary"
                                            size="large"
                                            placeholder="From"
                                            v-model="item.ranges.start">
                                                <el-option
                                                v-for="option in lists[item.list]"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="`${option.label}`"
                                                :key="option.label">
                                                </el-option>
                                        </el-select>
                                    </base-input>
                                <span class="local-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-4" v-if="item.tempo == 'range'">
                            <ValidationProvider
                                rules="required"
                                v-slot="{ passed, failed, errors }">
                                <base-input 
                                    label="To"
                                    type="white"
                                    placeholder="To">

                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="To"
                                        v-model="item.ranges.end">

                                            <el-option
                                            v-for="option in lists[item.list]"
                                            class="select-primary"
                                            :value="option.value"
                                            :label="`${option.label}`"
                                            :key="option.label">
                                            </el-option>

                                        </el-select>

                                </base-input>
                                <span class="local-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" style="display:none" ref="myButton" @click="submit(valid)">Click me</button>
        </form>
    </ValidationObserver>
</template>

<script>
import { Select, Option } from 'element-ui'
const weekDays = require('../json/weekDays.json')
const monthByName = require('../json/monthByName.json')

export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option
    },
    props: {
        crons: {
            type: Array,
            default: []
        },
    },
    data(){
        return {
            cronsToShow: [],
            weekly: true,
            lists: {
                daysByName: weekDays,
                monthByName: monthByName,
                daysInMonth: [...Array(31).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
                //hours: [...Array(24).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
                hours: [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map((day)=> {return { "label": day, "value": day } }),
                minute: [...Array(59).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } })
            },
            options: [ 
                { label: 'Every', value: 'every' },
                { label: 'Specific', value: 'specific' },
                { label: 'Range Of', value: 'range' }
            ],
        }
    },
    created(){
        this.filterCrons()
    },
    methods:{
        filterCrons(){
            if(this.weekly){
                this.cronsToShow = [ ...this.crons ]
                this.cronsToShow.splice(1,1)
                //this.cronsToShow.splice(2,1)
            }else{
                this.cronsToShow = [ ...this.crons ]
                this.cronsToShow.splice(0,1)
            }
        },
        async click(){
            //return this.$refs['myButton'].click()
            console.log(`isValid: ${isValid}`)
        },
        async submit(){
            const valid = await this.$refs.observer.validate();
            if(valid) {
                this.$emit('done', this.cronsToShow)
                return valid
            }   
        },
        async close(){
            this.filterCrons()
        }
    }
}
</script>

<style>

</style>