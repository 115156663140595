<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-4" v-if="$slots.icon || icon">
        <div class="info-icon text-center" :class="`icon-${type}`">
          <slot name="icon">             
            <mdicon :name="icon" size="32" class="tim-icons"/>
          </slot>    
        </div>
      </div>
      <div class="col-8" v-if="$slots.content || title || subTitle">
        <slot name="info">
        </slot>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String
  }
};
</script>
<style>



</style>
