var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"local-h2"},[_vm._v("Thank you for registering ")]),_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"rules":"agreeValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-checkbox',{staticClass:"text-left",model:{value:(_vm.user_agree),callback:function ($$v) {_vm.user_agree=$$v},expression:"user_agree"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"target":"blank","href":"https://message-deck.com/General-terms-and-conditions/"}},[_vm._v(" terms & conditions ")]),_vm._v(" and "),_c('a',{attrs:{"target":"blank","href":"https://message-deck.com/privacy-policy/"}},[_vm._v(" privacy policy ")]),_vm._v(". ")]),_c('span',{domProps:{"innerHTML":_vm._s(errors[0])}})]}}],null,true)}),_c('base-button',{attrs:{"loading":_vm.submitInProccess,"disabled":!valid,"native-type":"submit","type":"primary","round":"","block":"","size":"lg"}},[_vm._v(" Continue ")])],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"color":"black"}},[_vm._v(" To continue please agree to our terms and privacy policy "),_c('br')])}]

export { render, staticRenderFns }