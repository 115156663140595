<template>
  <card 
    style="margin-bottom: 0;"
    class="card-user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="javascript:void(0)">
        
        <mdicon style="font-color:white" name="account-circle-outline" size="72" />
        
        <h5 class="title">{{UserData.user_first_name}} {{UserData.user_last_name}}</h5>
        <p class="description">{{UserData.user_role}}</p>
      </a>
      
    </div>
    <p>
      
    </p>
    <div class="card-description">
      
      <table style="margin:50px">
        <tr>
          <td>Authenticated by:</td>
          <td class="p-2">{{UserData.user_subscribe_by}}</td>
        </tr>
        <tr>
          <td>Notifications:</td>
          <td class="p-2">
            <mdicon v-if="UserData.notifications_enabled" style="font-color:white" 
            name="toggle-switch-outline" size="32" />
            <mdicon v-if="!UserData.notifications_enabled" style="font-color:white" 
            name="toggle-switch-off-outline" size="32" />
            
          </td>
        </tr>
        <tr>
          <td>Created:</td>
          <td class="p-2">
            {{UserData.createdAt}}
          </td>
        </tr>
        <tr>
          <td>Updated:</td>
          <td class="p-2">
            {{UserData.updatedAt}}
          </td>
        </tr>
      </table>
        

    </div>

    <div slot="footer" class="button-container">
      
      <base-button @click="closeModal()" class="mt-3" native-type="submit" type="primary"
              >Close</base-button>
    </div>
  </card>
</template>
<script>
import { BaseSwitch } from 'src/components/index';
export default {
  components: {
    BaseSwitch
  },
  methods:{
    closeModal(){
      this.$emit('showUserCardModal', false)
    }
  },
  props:['UserData']
};
</script>
<style>

</style>
