<template>
    <modal :show.sync="showModal"
        body-classes="p-0"
        modal-content-classes="no-background"
        modal-classes="modal-lg">
        
        <card type="secondary"
            header-classes="bg-black pb-5"
            body-classes="px-lg-5 py-lg-5 "
            class="border-0 mb-0"
            id="smpp-form">
            
            <h4 class="card-title">Add access key</h4>
            <p class="card-text text-light">
                An SMPP key requires moderator approval.
                You will recive an email notification when your key is active.
                <br><br>
                The difference between a live key and a test key is that a live key actually sends a message, while a test key doesn't. 
                <br>
                However, when you send a message using a test key, you will get a fake delivery report back.
            </p>
            <br>
            <br>

            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(create)">
                    <div class="row">
                        <div class="col-lg-12">
                        
                        <ValidationProvider
                        rules="required|name:5"
                        v-slot="{ passed, failed, errors }">
                            <base-input 
                                v-model="newKey.smpp_key_description" 
                                alternative
                                class="mb-3"
                                placeholder="Api key description">
                            </base-input>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">

                            <ValidationProvider
                            rules="required"
                            v-slot="{ passed, failed, errors }">

                                <el-select 
                                style="width:100%"
                                filterable
                                class="select-primary"
                                v-model="newKey.smpp_key_mode" 
                                value-key="abbreviation"
                                label="Mode"
                                placeholder="Mode">
                                <el-option
                                    v-for="mode in modes.modes"
                                    :key="mode"
                                    :label="mode"
                                    :value="mode">
                                </el-option>
                                </el-select>
                                <span class="validation-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-lg-12">
                            <ValidationProvider
                            rules="required"
                            v-slot="{ passed, failed, errors }">

                                <el-select 
                                style="width:100%"
                                filterable
                                class="select-primary"
                                v-model="newKey.smpp_key_type" 
                                value-key="abbreviation"
                                label="Type"
                                placeholder="Type">
                                <el-option
                                    v-for="type in types.types"
                                    :key="type"
                                    :label="type"
                                    :value="type">
                                </el-option>
                                </el-select>
                                <span class="validation-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <br>
                    <div class="text-center">
                        <base-button :loading="submitInProccess" native-type="submit" type="primary" class="btn-fill ">
                            Create API key
                        </base-button>
                    </div>
                    
                </form>
            </ValidationObserver>
        </card>
    </modal>
</template>

<script>
import { Modal } from 'src/components'
import { extend } from "vee-validate";
import { required,min } from "vee-validate/dist/rules";


extend("name",{
  ...min,
  message: 'Description must be at leat 5 charachters long'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

const modes = require('./modes.json')
const types = require('./types.json')

import {Select, Option} from 'element-ui'

export default {
    components: {
        Modal,
        [Select.name]: Select,
        [Option.name]: Option
    },
    data() {
        return {
            modes: modes,
            types: types,
            showModal: false,
            submitInProccess: false,
            newKey: {
                smpp_key_description: null,
                smpp_key_mode: null,
                smpp_key_type: null
            }
        }
    },
    methods:{
        create(){
            this.$emit('create', this.newKey)
            this.showModal = !this.showModal
        }
    }

}
</script>

<style>

#smpp-form .el-input__inner {
    color: white !important
}

</style>