<template>
    <card   
        style="margin:5px;min-height:100px;"
        class="card-user"
        :class="[active ? 'activeCard' : '']">
        <a href="javascript:void(0)" @click="">

            <div class="author">
                <div class="block block-one"></div>
                <div class="block block-two"></div>
                <div class="block block-three"></div>
                <div class="block block-four"></div>
                
                <mdicon style="font-color:white" :name="icon" size="72" />
                <h2 class="title">{{type}}</h2>
                <p class="description">{{description}}</p>
                
            </div>
            <div class="card-description p-3">
                
            </div>
            <div slot="footer" class="button-container">
        
            </div>
        </a>
    </card>
</template>

<script>
export default {
    props: [
        'active', 
        'type',
        'description',
        'icon'
    ]

}
</script>

<style>
    .activeCard {
        border:2px solid white;
    }
</style>