<template>
  <div class="container" style="max-width: 1700px !important">
  <div class="d-flex justify-content-center w-100">
    <div class="contacts-wrapper">
      <div class="row">  
          <div class="col-lg-4">
            <ContactGroups 
              :submitInProccess="submitInProccess"
              :groups="groups"
              @getList="loadData"
              :getGroups="contactsAdded"
              :deleteGroup="deleteGroup"
            />
          </div>
          <div class="col-lg-8">
            <ContactGroupMembers
              :currentGroup="groupName"
              :groupMembersArray="groupMembersArray"
              :totalRecords="groupMembersCount"
              :pageCount="pageCount"
              :perPage="perPage"
              :page="page"
              :sort="sort"
              @pagination="pagination"
              @sortme="sortme"
              @download="download"
              @filterColumn="filterColumn"
              :submitInProccess="submitInProccess"
            />
          </div>
          
          
      </div>
    </div>

  </div>
  </div>
</template>
<script>
//import OverView from './ContactManagment/OverView.vue';
import ContactGroups from './ContactManagment/ContactGroups.vue';
import ContactGroupMembers from './ContactManagment/ContactGroupMembers.vue';


const api_endpoint = process.env.VUE_APP_API_ENDPOINT
export default {
  components: {
    ContactGroups,
    ContactGroupMembers
    //OverView
  },
  mounted() {
    this.getGroups()
  },
  data(){
    return {
      groupTotal: null,
      groupName: null,
      groups: [],
      submitInProccess: false,
      groupMembersArray: [],
      groupMembersCount: 0,
      page: 1,
      perPage: 10,
      pageCount: 0,
      sort: {
        by: 'contact_add_date',
        direction: 'desc'
      },
      filteFields:{
        contact_country_name: null,
        contact_msisdn: null,
        contact_operator_name: null
      }
      //view:"overview"
    }
  },
  methods:{
    async deleteGroup(row){
      this.submitInProccess = !this.submitInProccess
      api_endpoint + '/contacts/read'
      const res = await this.$http.delete(`${api_endpoint}/contacts/delete/${row.groups_name}`)
      this.submitInProccess = !this.submitInProccess
      if(res && res.status == 200){
        this.groups = res.data
      }
    },
    filterColumn(data){
      this.page = 1
      this.filteFields = data
      this.loadData()
    },
    download(){
      this.submitInProccess = !this.submitInProccess
      this.$http.post(api_endpoint + '/contacts/read',{
        page: this.page,
        perPage: this.perPage,
        sort: this.sort,
        filteFields: this.filteFields,
        download: true,
        contactListName: this.groupName
      },{
        responseType: 'blob'
      })
      .then( (response) => {
        this.submitInProccess = !this.submitInProccess
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'contacts.csv');
        document.body.appendChild(link);
        link.click()
      })
      .catch( (error) => {
        
      });
    },
    sortme(direction){
      this.sort.direction = direction
      this.loadData()
    },    
    pagination(page){
      this.page = page
      this.loadData()
    },
    contactsAdded(group){
      
      const existing = this.groups.findIndex(existing => existing.groups_name == group.groupAdded)
      
      if(existing > -1){
        let temp = this.groups[existing]
        temp.refresh = true
        this.groups.splice(existing, 1)
        this.groups.push(temp)
      }else{
        this.groups[0].total += group.totalRowsAdded
        this.groups.push({
          groups_name: group.groups_name,
          total: group.totalRowsAdded
        })
      }
      
    },
    async loadData(row){

      if(row) {
        this.groupName = row.groups_name
        this.groupTotal = row.total
      }

      this.submitInProccess = !this.submitInProccess

      const res = await this.$http.post(api_endpoint + '/contacts/read', {
      //const res = await this.$http.post(`${api_endpoint}/contacts/${path}`, {
        page: this.page,
        perPage: this.perPage,
        sort: this.sort,
        filteFields: this.filteFields,
        contactListName: this.groupName
      })
      
      this.submitInProccess = !this.submitInProccess

      if(res && res.status == 200){
        this.groupMembersArray = res.data.rows
        this.groupMembersCount = this.groupTotal
        this.pageCount = Number((this.groupMembersCount / this.perPage).toFixed(0)) + 1
        this.page = this.page > this.pageCount ? 1 : this.page

      }
    },
    async getGroups(){
      
      this.submitInProccess = !this.submitInProccess
      const res = await this.$http.get(api_endpoint + '/contacts/groups')
      
      if(res && res.status == 200){
        this.groups = res.data
        this.submitInProccess = !this.submitInProccess
      }

    }
  },
  
};
</script>
<style>
  .contacts-wrapper {
    width:100%;
    max-width:1800px;
  }

</style>

