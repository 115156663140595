<template>
  <ValidationObserver v-slot="{ handleSubmit, valid }">
  <form @submit.prevent="handleSubmit(submit)">
    <card class="card card-register card-white">
    <template slot="header">
      <img class="card-img" style="max-height:300px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Email</h4>
    </template>
      <div>
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ passed, failed, errors }"
        >
        <base-input
          required
          v-model="user_email"
          type="email"
          label="Email address"
          :class="{ 'has-success': !errors[0] && user_email, 'has-danger': errors[0] }">
        </base-input>
        <span class="validation-error-register" v-html="errors[0]"></span>
       </ValidationProvider>

       <ValidationProvider
        
        name="confirm"
        rules="required|passwordValidator"
        
         v-slot="{ errors }"
       >
       <base-input
         required
         v-model="user_password"
         type="password"
         label="Password"
         :class="{ 'has-success': !errors[0] && user_password, 'has-danger': errors[0] }">
       </base-input>
       <span class="validation-error-register" v-html="errors[0]"></span>
      </ValidationProvider>

      <ValidationProvider
        
        rules="required|password:@confirm"
        
        v-slot="{ errors }"
      >
        <base-input
          required
          v-model="user_password_confirmation"
          type="password"
          label="Confirm Password"
          :error="errors[0]"
          :class="{ 'has-success': !errors[0] && user_password_confirmation, 'has-danger': errors[0] }">
        </base-input>
        
      </ValidationProvider>
        <div class="category form-category">* Required fields</div>
      </div>

      <ValidationProvider
        rules="agreeValidator"
        v-slot="{ passed, failed, errors }"
      >
      <base-checkbox 
      v-model="user_agree"
      class="text-left">
        I agree to the 
        <a target="blank" href="https://message-deck.com/General-terms-and-conditions/">
            terms & conditions 
        </a>
        and
        <a target="blank" href="https://message-deck.com/privacy-policy/">
            privacy policy
        </a>.
      </base-checkbox>
      <span class="validation-error-register" v-html="errors[0]"></span>
      </ValidationProvider>
      
      
       
      <template slot="footer">

        <base-button :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" round block size="lg">
          Signup
        </base-button>

        <a @click=Back() class="nav-link text-primary" style="cursor:pointer">
            <i class="tim-icons icon-minimal-left"></i> Signup options
          </a>
        
      </template>
      
    </card>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);

extend("required", {
  ...required,
  message: 'This field is required'
});

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:
    `
    Password must be at least 8 charachters long. </br>
    Password must have: one number. </br>
    Password must have: one capital letter. </br>
    Password must have: one special character. </br>
    `
});


extend("agreeValidator", {
  params: ["user_agree"],
  validate: (value) => {    
   
    return value
  },
  message:`Please agree to terms and conditions`
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password does not match'
});
export default {
  name: 'register-form',
  components: {
    BaseCheckbox
  },
  data() {
    return {
      user_email: "",
      user_password: "",
      user_password_confirmation: "",
      user_agree: false,
      message: null,
      submitInProccess:false
    };
  },
  methods: {
   Back: function () {
        this.$emit('emailSignUp', false);
    },
    submit() {
      const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      
      //this.$http.post('/auth/register', {
      
      this.$http.post( local_auth_base_url + '/register', {
        user_email: this.user_email,
        user_password: this.user_password
      })
      .then( (response) => {
        this.message = response.data.message  
        this.$toast.success(this.message);
        this.$router.push('/login')
      })
      .catch(function (error) {
        //console.log(error);
        this.$toast.error(error);
      });
      
      /*
      this.$store.dispatch('register', {
        user_email: this.user_email,
        user_password: this.user_password
      }).then(() => {
          this.$toast.success("Registered succefully");
          //this.$router.push('/home')
      })
      */

      /*
      this.$http.post('/auth/register', {
        user_email: this.user_email,
        user_password: this.user_password
      })
      .then( (response) => {
        //this.submitInProccess = !this.submitInProccess;
        this.$toast.success("Submit success");
      })
      .catch(function (error) {
        //console.log(error);
      });
      */
    }
  }
};
</script>
<style>

.validation-error-register{
  color:rgb(12, 9, 6) !important;
  font-size:12px
}

.list-group-item{
  background:none;
  border:none;
  color:rgb(12, 9, 6) !important;
  padding:0 !important;
}

</style>
