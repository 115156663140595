<template>
  <div class="container" style="max-width: 1700px !important">
    <base-nav v-model="showMenu" type="dark" data-color="white" style="z-index:1">
          <button
            slot="toggle-button"
            class="navbar-toggler collapsed"
            type="button"
            @click="toggleMenu"
            aria-expanded="false"
            aria-label="Toggle navigation">
              <mdicon style="font-color:white" name="backburger" size="22" />
          </button>
          <a slot="brand" class="navbar-brand" href="#">
            Billing tokens managment
          </a>
          <ul class="navbar-nav">
            <li class="nav-item ">
              <a class="nav-link" @click="showHttpApi=true;showSmpppApi=false" href="#">HTTP API BILIING TOKENS</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link disabled" @click="showHttpApi=false;showSmpppApi=true" href="#">SMPP API BILIING TOKENS&nbsp;
                  <badge type="info">
                    <span slot="badgeContent">SOON</span>
                  </badge>
                </a>
            </li>
          </ul>
    </base-nav>
    <div class="row">
      <div class="col-lg-12">
        <billing-tokens :existingApiKeys="ExistingHTTPApiArray" v-if="showHttpApi" />
      </div>
    </div>
  </div>
  
</template>
<script>

import { BaseNav, Modal, Badge } from '@/components';
import BillingTokens from './components/BillingTokens.vue';


export default {
  components: {
    BillingTokens,
    BaseNav,
    Modal,
    Badge
  },
  
  data(){
    return {
      showHttpApi:true,
      showSmpppApi: false,
      showMenu: false,
      ExistingHTTPApiArray: [],
      ExistingSMPPApiArray: []
    }
  },
  methods: {
    getApiKeys(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.get(api_endpoint + '/billing/get-http-billing-keys'),
        this.$http.get(api_endpoint + '/billing/get-smpp-billing-keys')
      ]).then(([result1, result2]) => {
        
        this.ExistingHTTPApiArray = result1.data;
        this.ExistingSMPPApiArray = result2.data;

      }).catch(function(err){
        console.log('Cant fetch API keys', err);
      });
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  },
  beforeMount () {
    this.getApiKeys();
  }
};
</script>
<style>
.badge{
  margin:0 !important;
}


.mdi-backburger{
  color:white;
}

.my-padding{
  
}

@media only screen and (max-width: 1200px) {
  .navbar {
      top: 0px;
  }
}
</style>
