<template>
    <div id="item-wrapper">
        <table>
            <thead>
                <td class="text-info" >Sender</td>
                <td class="text-info">Operator</td>
                <td class="text-info">Message text</td>
            </thead>
            <tbody>
                <tr>
                    <td class="text-primary" style="width:70px;word-break: break-all;">{{ sender }}</td>
                    <td class="text-primary" style="width:120px;word-break: break-all;">{{ operator.mccmnc }} - {{ operator.network }}({{operator.isoAlpha2}}) </td>
                    <td class="text-primary" style="width:400px;word-break: break-all;">{{ text ? text : "TEXT EMPTY" }}</td>
                </tr>
            </tbody>
        </table>
    
    </div>
</template>

<script>
export default {
    props: {
        operator: {
            type: Object,
            description: 'Operator name'
        },
        sender: {
            type: String,
            description: 'Sender ID'
        },
        text: {
            type: String,
            description: 'Message text'
        },
    }
}
</script>

<style scoped>
    #item-wrapper {
        border: 1px solid #2b3553;
        border-radius: 0.2857rem;
        padding:10px;
        margin-top:2px;
    }
    
    table thead td {
        font-size: 12px;
    }
    table tbody td {
        font-size: 12px;
        padding: 5px;
    }
</style>