<template>
  <div style="min-height: 250px">
    <div class="row">
      <div class="col-lg-12">
        <h3 style="margin:0"><u>Recipients</u></h3>
        <small>Select contacts for this campaign.</small>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <base-button type="primary" class="btn btn-simple" @click="toggle()">
          <i class="fa fa-plus"></i> &nbsp; Upload Contacts
        </base-button>
        <base-button type="primary" class="btn btn-simple" @click="select()">
          <i class="fa fa-users"></i> &nbsp; Select Contacts
        </base-button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <div v-if="conf.contacts">
          <div class="row">
            <div class="col-lg-8">
              <h6><i class="fa fa-users text-info"></i> &nbsp; You have Selected {{conf.contacts.toLocaleString('en-GB')}} contacts.</h6>
              <div v-if="conf.contacts < totalContacts">
                <el-tooltip content="Message is GSM-7" effect="light" :open-delay="300" placement="top">
                  <i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>
                </el-tooltip>
                &nbsp;<p style="display:inline">You have limited the number of messages to send.</p>
              </div>

              <br />
              <br />
              <base-checkbox style="display:inline" v-model="rotate"><p>Rotate your contacts</p></base-checkbox>
              &nbsp;
              <el-tooltip :content="rotateMessage" effect="light" :open-delay="300" placement="top">
                <i class="fa fa-question-circle text-info" aria-hidden="true"></i> 
              </el-tooltip>
              <Rotate :conf="conf" @done="rotated" v-if="rotate"/>

            </div>
            <div class="col-lg-4">
              <div class="row" v-if="conf.filters.length">
                <div class="col-lg-12">
                  <h6>
                    <u>Filters:</u> &nbsp;
                    <el-tooltip content="Filters are used to target specific contacts in your list" effect="light" :open-delay="300" placement="top">
                      <i class="fa fa-question-circle text-info" aria-hidden="true"></i> 
                    </el-tooltip>
                  </h6>
                  <FilteredRecipients v-for="(item, index) in conf.filters" :key="index" :setting="item"/>
                  </div>
              </div>
              <div v-else-if="conf.contacts && !conf.filters.length">
                <el-tooltip content="Filters are used to target specific contacts in a list" effect="light" :open-delay="300" placement="top">
                  <i class="fa fa-question-circle text-white" aria-hidden="true"></i> 
                </el-tooltip>
                &nbsp;<p style="display:inline">You have not selected any filters.</p>
              </div>
            </div>
          </div>
        </div>
        <p v-else>
          Please upload some contacts if you haven't already. <br>
          Once you have uploaded some contacts click on select contacts to add recipients.
        </p>

      </div>
    </div>

    <UploadContactsModalVue ref="contactsModal"/>
    <SelectContacts ref="selectModal" @contacts="contacts"/>
  </div>
</template>

<script>
import UploadContactsModalVue from '../../../Contacts/ContactManagment/UploadContactsModal.vue'
import SelectContacts from '../Recipients/SelectContacts.vue'
import FilteredRecipients from '../Components/Filtered.vue'
import Rotate from '../Recipients/Rotate.vue'

export default {
  components:{
    UploadContactsModalVue,
    FilteredRecipients,
    SelectContacts,
    Rotate
  },
  props: ['contactCount', 'loading'],
  data() {
    return {
      //rotateMessage: `Cconfigure a waiting time before sending SMS messages to your selected contacts. This delay helps space out your messages, ensuring a more controlled and effective SMS campaign. Please specify the desired waiting time below.`,
      rotateMessage: `Set a delay between messages sent to the same contact, ensuring a more controlled and effective SMS campaign.`,
      rotate: false,
      rotateConf: null,
      //totalContacts: 10000,
      totalContacts: 0,
      //conf: { "contacts": "111", "filters": [ { "fieldName": "custom5", "fieldAction": "equals to", "fieldValue": "5", "applied": true, "index": 0 } ], "rotate": {} }
      conf: { contacts: null, filters: [], rotate: {}}
    }
  },
  methods: {
    contacts(contacts){
      this.conf.contacts = contacts.count
      this.totalContacts = contacts.total
      this.conf.filters = contacts.filters
    },
    toggle(){
      return this.$refs['contactsModal'].toggle();
    },
    select(){
      return this.$refs['selectModal'].toggle();
    },
    rotated(data){
      this.rotateConf = {
        rotate: data
      }
    },
    validate() {

      if(this.conf.contacts){
        this.$emit('on-validated', true, { ...this.conf, ...this.rotateConf,...{ totalContacts: this.totalContacts} });
        return true
      }else{
        this.$emit('on-validation-error', false, {});
        return false
      }
      
      /*
      this.$notify({
        message:
          '<b>Please select some contacts</b> - in order to continue',
        timeout: 30000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger'
      });
      */
    },
  }
}
</script>

<style>

</style>