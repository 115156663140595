<template>

    <el-tag
    :closable="closable"
    :close-transition="false"
    @close="handleClose"
    class="filter-tags"
    v-if="setting.applied">

    {{setting.fieldName}} {{setting.fieldAction}} {{setting.fieldValue}}
    </el-tag>

</template>

<script>
import { Tag } from 'element-ui';
export default {
    components: {
        [Tag.name]: Tag
    },
    props:{
        setting: {
            type: Object
        },
        closable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleClose(){
            this.$emit('close', this.setting)
        }
    },
    data() {
        return {
        };
    },
}
</script>

<style>
    .filter-model span.el-tag {
        margin-left:2px !important;
    }
</style>