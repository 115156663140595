<template>
  <div class="row">
      <div class="col-sm" >
      <stats-card
        :title="FiltertilesData.Total.toString()"
        subTitle="Total Volume"
        type="info"
        icon="email-fast"
      >
        
        <div slot="footer" >
          Total Outgoing
        </div>
      </stats-card>
      </div>
      <div class="col-sm" >
        <stats-card
          :title="operatorStats()"
          subTitle="Operator"
          type="success"
          icon="antenna"
        >
          <div slot="footer" >
            Processing by operator
          </div>
        </stats-card>
      </div>
      <div class="col-sm" >
        <stats-card
          :title="FiltertilesData.Delivered.toString()"
          subTitle="Delivered"
          type="success"
          icon="email-check"
        >
          <div slot="footer" >
            Succefully delivered to handset
          </div>
        </stats-card>
      </div>
      
      <div class="col-sm" >
        <stats-card
          :title="FiltertilesData.Failed.toString()"
          subTitle="Failed"
          type="danger"
          icon="email-remove"
        >
          <div slot="footer" >
            Delivery failed
          </div>
        </stats-card>
      </div>
      
      <div class="col-sm" >
        <stats-card
          :title="FiltertilesData.Queued.toString()"
          subTitle="Queued"
          type="info"
          icon="tray-full"
        >
          <div slot="footer" >
            Queued
          </div>
        </stats-card>
      </div>
      <div class="col-sm" >
        <stats-card
          :title="FiltertilesData.Other.toString()"
          subTitle="No receipt"
          type="defualt"
          icon="email-alert"
        >
          <div slot="footer" >
            Delivery receipt missing
          </div>
        </stats-card>
      </div>
  </div>
</template>

<script>
import {Tooltip} from 'element-ui'
import StatsCard from 'src/components/Cards/StatsCard';
export default {
  components: {
    StatsCard,
    [Tooltip.name]: Tooltip
  },
  props:['FiltertilesData'],
  methods: {
    operatorStats(){
      return ( this.FiltertilesData.Total - this.FiltertilesData.Failed - this.FiltertilesData.Other - this.FiltertilesData.Queued ).toString()
    }

  },
  data() {
    //<mdicon name="wallet-outline" size="18" />
    return {
       statsCards: [
        { 
          
          title: '1000000',
          subTitle: 'Total Volume',
          type: 'info',
          icon: 'email-fast',
          footer: 'Total Outgoing'
        },
        {
          title: '125000',
          subTitle: 'Delivered',
          type: 'success',
          icon: 'email-check',
          footer: 'Succefully delivered'
        },
        {
          title: '15000',
          subTitle: 'Failed',
          type: 'danger',
          icon: 'email-remove',
          footer: 'Delivery failed'
        },
        {
          title: '23',
          subTitle: '',
          type: 'info',
          icon: 'email-sync',
          footer: 'Processing'
        },
        {
          title: '23',
          subTitle: 'No delivery receipt',
          type: 'defualt',
          icon: 'email-alert',
          footer: 'Delivery receipt missing'
        }
      ],
    }
  },
  computed: {

  },
}
</script>

<style>
.info-icon> .mdi {
  color:white !important;
  margin-top:15%;
}
</style>