<template>
    <div class="row">
        <div class="col-lg-6">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, valid }">
                <form @submit.prevent="handleSubmit(submit)">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group has-label rotate-select">
                                <label>Period</label>
                                <div class="mb-0">
                                <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                                    <el-select 
                                        :disabled="disabled"
                                        v-model="periodName" 
                                        value-key="value"
                                        label="Period"
                                        placeholder="Period">
                                        <el-option
                                            v-for="item in periods"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="validation-error">{{errors[0]}}</span>  
                                </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group has-label">
                                
                                <ValidationProvider
                                    rules="required"
                                    v-slot="{ passed, failed, errors }">
                                    <base-input
                                        :disabled="disabled"
                                        :class="{  'has-success': passed,  'has-danger': !passed }"
                                        type="number"
                                        label="Length"
                                        placeholder="Period Length"
                                        v-model="periodValue">
                                    </base-input>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                                
                            </div>
                        </div>
                    </div>
                    <base-button 
                        v-if="!disabled"
                        simple
                        size="sm"
                        :disabled="!valid" 
                        native-type="submit" 
                        type="primary" 
                        class="btn-fill">
                        confirm
                    </base-button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {Select, Option} from 'element-ui'
export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            disabled: false,
            periodName: null,
            periodValue: null,
            periods: [
                {
                    label: 'Month',
                    value: 'month'
                },
                {
                    label: 'Week',
                    value: 'week'
                },
                {
                    label: 'Day',
                    value: 'day'
                }
            ]
        }
    },
    methods: {
        submit(){
            this.disabled = true
            this.$emit('done', {
                period: this.periodName,
                value: this.periodValue
            })
        },
        reset(){
            this.periodName = null
            this.periodValue = null
        }
    }
}
</script>

<style>
    .rotate-select .el-input.is-disabled .el-input__inner {
        background: #1d253b !important;
        color: rgb(125, 100, 100) !important;
    }

    .rotate-select .el-input__inner {
        color: white !important;
    }
</style>