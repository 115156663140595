<template>
  <div class="row">
    <div class="col-lg-12">
      <p>
        Coming soon
      </p>
      <!--
      <base-button type="primary" class="btn btn-simple" @click="toggle()">
        Setup Default Tracking
      </base-button>
      -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggle(){
      this.$emit('toggle', 'internal')
    }
  }
}
</script>

<style>

</style>