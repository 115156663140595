<template>
<div>
  <div class="row">
    <div class="col-4">
         <base-button 
         disabled
          @click="model.method='card';choosenText='a credit card'" 
          :class="{active:  this.model.method=='card'}" 
          style="width:100%;display:block;" type="primary" simple>
          <i class="fa fa-credit-card" style="font-size:35px" aria-hidden="true"></i>
          <br> 
          <br> 
          <span style="font-size:25px">Card payment
            <br> 
            <br> 
          <badge type="info">
              <span slot="badgeContent">COMING SOON</span>
          </badge>
          </span>
          
         </base-button>

    </div>
    <div class="col-4">
         <base-button 
         @click="model.method='bank';choosenText='a wire transfer'" 
         :class="{active: this.model.method=='bank'}" style="width:100%;display:block;" type="primary" simple>
          <i class="fa fa-university" style="font-size:35px" aria-hidden="true"></i>
          <br> 
          <br> 
          <span style="font-size:25px">Bank transfer</span>
         </base-button>
    </div>
    <div class="col-4">
         <base-button
         disabled
          @click="model.method='paypal';choosenText='paypal'" :class="{active: this.model.method=='paypal'}" style="width:100%;display:block;" type="primary" simple>
          <i class="fab fa-paypal" style="font-size:35px"></i>
          <br> 
          
          <span style="font-size:25px">PayPal
            <br> 
            <br> 
          <badge type="info">
              <span slot="badgeContent">COMING SOON</span>
          </badge></span>
         </base-button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h3 style="text-align:center;margin-top:25px">
        Use {{choosenText}} to add funds to your account
      </h3>
      <!--
      <credit-card v-if="isCard"></credit-card>
      <bank-transfer v-if="isBank"></bank-transfer>
      <pay-pal v-if="isPaypal"></pay-pal>
      -->
    </div>
  </div>
</div>
</template>
<script>
import {Badge} from 'src/components'
/*
import CreditCard from './Methods/CreditCard.vue';
import BankTransfer from './Methods/BankTransfer.vue';
import PayPal from './Methods/PayPal.vue';
*/
export default {
  components:{
    Badge
    /*
    CreditCard,
    BankTransfer,
    PayPal
    */
  },
  data() {
    return {
        model: {
          method:null
        },
        choosenText:"bank",
        method:"bank"
    };
  },
  methods: {
    
    validate() {
      
      if(!this.model.method){
        this.$emit('on-validation-error', true, 'Please choose a payment menthod');
        return false
      }
      this.$emit('on-validated', true, this.model);
      return true;
      //return Promise.resolve(true);
      /*this.$emit('method', this.method);
      
      */
    }
  }
};
</script>
<style>

</style>
