<template>
    <card>
        <template slot="header">
            <div class="row">
                <div class="col-lg-10">
                    <h4 class="card-title">History</h4>
                    <h5 class="card-category">Of clicks in period</h5>
                </div>
            </div>
        </template>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    
                    <base-pagination 
                        type="info"
                        :pageCount="Math.ceil(length / this.perPage)" 
                        :perPage="perPage"
                        :total="rows.length"
                        :value="value"
                        :pagesToDisplay="pagesToDisplay"
                        @input="pageChange">
                    </base-pagination>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>Found {{length}} records</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <CsvDownload :rows="csvRows" :headers="csvHeaders" :fileName="csvFileName"/>
            </div>
            <div class="col-2">
                <base-input
                label="Rows to Show">
                    <el-select
                        @change="rowsToShow"
                        v-model="perPageLocal"
                        class="select-primary"
                        name="Rows to show">
                        <el-option class="select-primary" label="10" :value="10" :key="10"></el-option>
                        <el-option class="select-primary" label="50" :value="50" :key="50"></el-option>
                        <el-option class="select-primary" label="100" :value="100" :key="100"></el-option>
                        <el-option class="select-primary" label="250" :value="250" :key="250"></el-option>
                    </el-select>
                </base-input>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <el-table
                    @sort-change="onSortChange"
                    @filter-change="onFilterChange"
                    :data="paginatedRows"
                    style="width: 100%; margin-bottom: 20px;min-height:500px;">
                        <!--
                        <el-table-column sortable :width="70" prop="id" label="Id"/>
                        -->
                        <el-table-column sortable :width="70" prop="redirect_campaign_id" label="Campaign Id"/>
                        <el-table-column sortable prop="redirect_click_id" label="Click (Message) Id"/>
                        <el-table-column sortable prop="redirect_date" label="Date"/>
                        <el-table-column sortable prop="redirect_country_name" label="Country"/>
                        <el-table-column sortable prop="redirect_city" label="City"/>
                        <el-table-column sortable prop="redirect_platform" label="Platform"/>
                        <el-table-column sortable prop="redirect_sender" label="Sender"/>
                        <el-table-column sortable :width="120" prop="redirect_receiver" label="Receiver"/>
                        <el-table-column sortable prop="redirect_group" label="Contact Group"/>
                        <el-table-column sortable prop="redirect_custom1" label="Contact Custom1"/>
                        <el-table-column sortable prop="redirect_custom2" label="Contact Custom2"/>
                        <el-table-column sortable prop="redirect_custom3" label="Contact Custom3"/>
                        <el-table-column sortable prop="redirect_custom4" label="Contact Custom4"/>
                        <el-table-column sortable prop="redirect_custom5" label="Contact Custom5"/>
                        
                </el-table>
            </div>
        </div>
    </card>
</template>

<script>
import {Table, TableColumn, Select, Option} from 'element-ui'
import { BasePagination } from '@/components';
import CsvDownload from '../../../Common/CsvDownload.vue'
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BasePagination,
        CsvDownload
    },
    props:{
        length: {
            type: Number,
            default: 0
        },
        rows: {
            type: Array,
            default: () => []
        },
        perPage: {
            type: Number,
            default: 10
        }
    },
    watch: {
        'rows': {
            handler: function(nv){
                this.paginate()
            },
            deep: true
        },
    },
    data() {
        return {
            perPageLocal: 10,
            csvRows: [],
            paginatedRows: [],
            value: 1,
            //perPage: 10,
            pagesToDisplay: 10,
            csvFileName: 'clicks',
            csvHeaders: [
                'Campagin id', 'Click id', 'Date', 'Timestamp' , 'Country', 'City', 'Platform', 
                'Sender', 'Receiver', 'Group', 'Custom1',
                'Custom2', 'Custom3', 'Custom4', 'Custom5'
            ]
        }
    },
    methods: {
        rowsToShow(perPage){
            this.$emit('changePerPage', perPage)
            this.pageChange(1)
        },
        paginate(){
            this.paginatedRows = [...this.rows]
            this.csvRows = [...this.rows].map(row => {
                return {
                    redirect_campaign_id: row.redirect_campaign_id,
                    redirect_click_id: row.redirect_click_id,
                    redirect_date: row.redirect_date,
                    redirect_timestamp: row.createdAt,
                    redirect_country_name: row.redirect_country_name,
                    redirect_city: row.redirect_city,
                    redirect_platform: row.redirect_platform,
                    redirect_sender: row.redirect_sender,
                    redirect_receiver: row.redirect_receiver,
                    redirect_group: row.redirect_group,
                    redirect_custom1: row.redirect_custom1,
                    redirect_custom2: row.redirect_custom2,
                    redirect_custom3: row.redirect_custom3,
                    redirect_custom4: row.redirect_custom4,
                    redirect_custom5: row.redirect_custom5
                }
            })
            //this.paginatedRows = [...this.rows].slice( ( this.value - 1 )  * this.perPage, this.value * this.perPage)
        },
        onSortChange(sort){},
        onFilterChange(filter){},
        pageChange(page){
            this.value = page
            this.$emit('pagination', page)
        }
    }
}
</script>

<style>

</style>