<template>
  <modal 
    id="filters-modal"
    style="padding:25px;"
    :show.sync="show" 
    size="large" 
    type="notice"
    @close="close"
    footerClasses="footer-padding">
        <h4 slot="header" class="title title-up">
            Select contacts filters <br>
            <small>
                Use contacts filters to filter results
            </small>
        </h4>
        <div class="row">
            <div class="col-lg-12">
                <h6 class="text-muted"><u>Filters:</u></h6>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <base-button :disabled="pending" class="filter-button" size="sm" type="primary" @click="addFitler()">
                    <i class="fa fa-filter"></i> Add filter
                </base-button>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div v-if="filters.length">
                    <ContactFiltered v-for="(item, index) in filters" :key="index" @close="removeFilter(item)" :setting="item"/>
                </div>
                <p v-else>
                    <i class="fa fa-exclamation" aria-hidden="true"></i> &nbsp;  No filters have been selected
                </p>
            </div>
        </div>

        <div class="row">
            <div 
                v-for="(item, index) in filters" :key="index" 
                class="col-lg-12">
                <ContactFilters 
                    :noSort="true"
                    :setting="item" 
                    :index="index"
                    @settings="addFilterConfirm" 
                    @cancel="removeFilter(item)"
                    :cfg="item" 
                    v-if="!item.applied"
                />
            </div>
        </div>

        


        <template slot="footer" >
            <div style="padding:25px">
                <base-button @click="done()" native-type="submit"  type="primary" class="btn-fill">
                    Confirm
                </base-button>
            </div>
        </template>
    </modal>
</template>

<script>
import { Modal } from 'src/components';
import ContactFilters from '../../New/Components/Filter.vue'
import ContactFiltered from '../../New/Components/Filtered.vue'

export default {
    components: {
        Modal,
        ContactFilters,
        ContactFiltered
    },
    data() {
        return {
            filters: [], 
            show: false,
            pending: false
        }
    },
    methods: {
        toggle(){
            this.show=!this.show
        },
        close(){
            this.filters = []
        },
        done(){
            this.$emit('filters', this.filters)
            this.toggle()
        },
        addFitler(){
            this.pending = !this.pending
            this.filters.push({
                fieldName: null,
                fieldAction: null,
                fieldValue: null,
                applied: false,
                index: this.filters.length
            })
        },
        addFilterConfirm(setting){
            this.pending = !this.pending
            this.filters[setting.index].fieldName  = setting.fieldName,
            this.filters[setting.index].fieldAction  = setting.fieldAction,
            this.filters[setting.index].fieldValue  = setting.fieldValue,
            this.filters[setting.index].applied = setting.applied
            this.filters[setting.index].index = setting.index
        },
        removeFilter(setting){
            this.pending = false
            if(this.filters[setting.index]) {
                this.filters.splice(setting.index, 1)
            }else{
                this.filters.splice(0, 1)
            }
        }
    }
}
</script>

<style>

#filters-modal .modal-content {
    height: 500px;
}

</style>