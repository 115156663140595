<template>
  <ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <card class="card card-register card-white">
    <template slot="header">
      <img class="card-img" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Email</h4>
    </template>
      <div>
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ passed, failed, errors }"
        >
        <base-input
          required
          v-model="email"
          type="email"
          label="Email address"
          :error="errors[0]"
          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
        </base-input>
       </ValidationProvider>

       <ValidationProvider
         name="password"
         rules="required|confirmed:confirmation"
         v-slot="{ passed, failed, errors }"
       >
       <base-input
         required
         v-model="password"
         type="password"
         label="Password"
         :error="errors[0]"
         :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
       </base-input>
      </ValidationProvider>

      <ValidationProvider
        name="confirm"
        vid="confirmation"
        rules="required"
        v-slot="{ passed, failed, errors }"
      >
      <base-input
        required
        v-model="confirmation"
        type="password"
        label="Confirm Password"
        :error="errors[0]"
        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
      </base-input>
     </ValidationProvider>

        <div class="category form-category">* Required fields</div>
      </div>
      <base-checkbox class="text-left">
        I agree to the <a href="#something">terms and conditions</a>.
      </base-checkbox>
      
      
       
      <template slot="footer">
        <base-button native-type="submit" type="primary" round block size="lg">
        Signup
        </base-button>
        <a @click=Back() class="nav-link text-primary" style="cursor:pointer">
            <i class="tim-icons icon-minimal-left"></i> Signup options
          </a>
        
      </template>
      
    </card>
  </form>
  </ValidationObserver>

</template>
<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);

export default {
  name: 'register-form',
  components: {
    BaseCheckbox
  },
  data() {
    return {
      email: "",
      password: "",
      confirmation: "",
      subscribe: false
    };
  },
  methods: {
   Back: function () {
        this.$emit('emailSignUp', false);
    },
    submit() {
      alert("Form has been submitted!");
    }
  }
};
</script>
<style></style>
