<template>
    <modal 
      style="padding:25px;"
      :show.sync="show" 
      size="large" 
      type="notice"
      @close="close"
      footerClasses="footer-padding">

      <h4 slot="header" class="title title-up">
          Upload Contacts From Your Device
      </h4>
      <p>
        Example CSV File Format 

        <el-tooltip 
          content="You can export an Excel or a Google sheets file to a CSV file" 
          effect="light" :open-delay="300" placement="top">
          <i class="fa fa-question-circle text-info" aria-hidden="true"></i>
        </el-tooltip>
        
        <br/>
        <small>Every Number Must Include A Country Code To Be Imported Successfully.</small>
        &nbsp;
        <el-tooltip 
          content="Make sure that every number begins with a country code" 
          effect="light" :open-delay="300" placement="top">
            <i class="fa fa-question-circle text-info" aria-hidden="true"></i>
        </el-tooltip>
        <br/>
        <small>Custom fields are up to 100 charachters long.</small>
      </p>
      

      <img style="border:1px solid" src="/img/csvExample.png" alt="">
      &nbsp;&nbsp;
      <div 
        @dragover.prevent
        @drop="handleDrop"
        @dragleave="handleDragLeave"
        class="dropzone">
          <div v-if="!done">
            <div v-if="!file">
              <p>Click or drag and drop CSV files here</p>
              <input ref="fileUpload" accept=".csv" @change="handleFileInput" type="file" hidden>
              <base-button type="primary" @click="$refs['fileUpload'].click()">Choose File</base-button>      
              <p><small>Maximum allowed file size is 50MB</small></p>
              <div class="text-danger">{{error}}</div>
            </div>
            <div v-else>
              <p>{{ file.name }}</p>
              <base-button 
              v-if="!proccessing"
              type="danger" 
              @click="close()">Remove file</base-button>
            </div>
          </div>
          <div v-else>
            <base-button 
              @click="done=false;close()"
              type="primary" 
              class="btn-fill">
              Upload more contacts
            </base-button>
          </div>
      </div>
      
      &nbsp;
      <div class="uploadedFiles" v-if="file">
          
        <div class="row">
          <div class="col-lg-12">
            
              <ValidationProvider
                ref="listName"
                rules="required|listName:5"
                v-slot="{ passed, failed, errors }">
                <base-input 
                  :disabled="proccessing"
                  textMuted
                  type="text"
                  label="Optional Contacts Group Name"
                  placeholder="Contacts group name"
                  v-model="contactListName"
                >
                </base-input>
                <span class="local-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            
          </div>
        </div>
        
        
        <div class="row">
          <div class="col-lg-12">
            <base-progress
              :label="`${file.name} ${( file.size / 1024 / 1024 ).toFixed(2) } MB`"
              :value="progress"
              value-position="right"
              type="primary"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <p class="text-muted">Items are uploaded in batches of 10,000.</p>
          </div>    
        </div>
       
      </div>


      <div class="row" v-if="done">
        <div class="col-lg-12">
          <p>
            <i class="fa fa-hourglass-half fa-spin" aria-hidden="true"></i> &nbsp; Proccessing {{response.totalRowsAdded}} contacts <br>
            <i class="fa fa-exclamation" aria-hidden="true"></i> &nbsp; {{response.badRows}} rows are malformed.
          </p>
          
        </div>
      </div>

      <template slot="footer">

        <base-button 
          v-if="!done"
          @click="proccessFile()"
          :loading="proccessing" 
          native-type="submit" 
          type="primary" 
          class="btn-fill"
          :disabled="!file">
          Upload contacts
        </base-button>

        <base-button 
          v-if="done"
          @click="show=false"
          type="info" 
          class="btn-fill">
          {{continueButtonText}}
        </base-button>
        
      </template>
    </modal>
  
</template>

<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { BaseProgress } from 'src/components/index';
import { Modal } from 'src/components';

extend("listName",{
  ...min,
  message: 'Contact group name is too short'
})
extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
    components: {
        Modal,
        BaseProgress
    },
    props: {
      continueButtonText: {
        Type: Text,
        default: 'Continue campaign setup'
      }
    },
    data() {
        return {
          show: false,
          isDragging: false,
          uploadedFiles: [],
          error: '',
          columns: [],
          parsedData: [],
          proccessing: false,
          progress: 0,
          file: null,
          response: {
            totalRowsAdded: 0,
            badRows: 0,
          },
          done: false,
          contactListName: ""
        }
    },
    methods: {
      close(){
        this.contactListName = null
        this.progress = 0
        this.file=null
      },
      toggle(){
        
        this.show = !this.show
        this.close()
        
      },
      async uploadFile(){

        const validate = await this.$refs.listName.validate()
        if(! validate.valid ) return
        
        this.response = {
          totalRowsAdded: 0,
          badRows: 0,
        }
        const file = this.file
        const api_endpoint = process.env.VUE_APP_API_ENDPOINT
        var formData = new FormData();
        formData.append('file', file, 'chunk.csv');
        formData.append('contactListName', this.contactListName);
        this.proccessing = !this.proccessing

        const res = await this.$http.post(
          `${api_endpoint}/contacts/lists/create`, 
          formData, 
          {
            timeout: 50000,  
            maxBodyLength: 50 * 1048576, // 250MB
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            },
          }
        )
        
        if(res && res.status == 200){
          this.done = !this.done
          this.proccessing = !this.proccessing
          this.response = res.data
          this.$emit('uploadedContacts', {...this.response, ...{groups_name: this.contactListName}})
          return 
        }

        this.proccessing = !this.proccessing



      },
      async proccessFile(){
        this.uploadFile()        
      },
      handleDrop(event) {
        event.preventDefault();
        this.isDragging = false;
        const files = event.dataTransfer.files;

        if(files.length > 1) {
          this.error = `Please upload 1 file at a time`
          return 
        }

        if(!files[0].name.endsWith('.csv')){
          this.error = `Please select a valid CSV file.`
          return
        }

        this.file = files[0]
        
      },
      handleDragLeave() {
        this.isDragging = false;
      },
      handleFileInput(event) {
        this.file = event.target.files[0];
        this.response = {
          totalRowsAdded: 0,
          badRows: 0,
        }
      },

    }
}
</script>

<style>
.modal-body {
  padding: 20px !important;
}

.modal-footer {
  padding: 20px !important;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 50px 20px;
    text-align: center;
    font-size: 16px;
}

.file-input {
  /* Customize button appearance */
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Styling when hovering over the button */
.file-input:hover {
  background-color: #0056b3;
}

.uploaded-files {
  margin-top: 20px;
}

.uploaded-files ul {
  list-style: none;
  padding: 0;
}

.uploaded-files li {
  margin: 10px 0;
  font-size: 18px;
}
.footer-padding {
  padding:20px;
}

.local-success{
  color:black !important;
} 

.local-danger{
  color: red !important;
} 

.progress-badge{
  color:#e14eca !important;
}

.el-input__inner {
  color: black !important;
}

[disabled].text-muted {
  background: #fff !important;
}

</style>