<template>
  
  
    <card class="card card-login  card-white">
    
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Email</h4>
    </template>

      <ValidationObserver v-slot="{ handleSubmit, valid }">
      <form @submit.prevent="handleSubmit(submit)">
      
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
          :class="{ 
                    'has-success': !errors[0] && email, 
                    'has-danger': errors[0] 
                }"
            required
            v-model="email"
            type="email"
            label="Email address">
          </base-input>
          <span  v-html="errors[0]"></span>
         </ValidationProvider>

         <ValidationProvider
           name="password"
           :rules="{ 
                  required: true, 
                  passwordValidator: { } 
                }" 
           v-slot="{ passed, failed, errors }"
         >
         <base-input
           required
           v-model="password"
           type="password"
           label="Password"
          :error="errors[0]"
           :class="{ 
                    'has-success': !errors[0] && password, 
                    'has-danger': errors[0] 
                }">
         </base-input>
         
        </ValidationProvider>
        <div class="category form-category">* Required fields</div>
    

        <base-button :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" round block size="lg">
          Login
        </base-button>

        <div class="row">
          <div class="col-lg-12">
            <base-button @click="resendConfirmationEmail()" :loading="submitInProccess" :disabled="!valid" type="primary" round block simple size="lg">
              Resend confirmation email
            </base-button>
          </div>
        </div>

      </form>
      </ValidationObserver>
      
      <template slot="footer">
        
        
        <div class="row">
          <div class="col-lg-5 d-flex justify-content-start">
            <a @click=back() class="nav-link text-primary" style="cursor:pointer">
              <i class="tim-icons icon-minimal-left"></i> Login options
            </a>
          </div>
          <div class="col-lg-7 d-flex justify-content-end">
            <a @click="recover()" class="nav-link text-primary" style="cursor:pointer">
              Fotgot my password
              <i class="tim-icons icon-minimal-right"></i>
            </a>
          </div>
        </div>
        
      </template>

    </card>
  
 
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:` Password must be at least 8 charachters long it must have: one number, one capital letter, one special charachter.`
});

export default {
  data() {
    return {
      email: "",
      password: "",
      submitInProccess: false
    };
  },
  methods: {
    resendConfirmationEmail(){
      const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      this.submitInProccess = true
      this.$http.post( local_auth_base_url + '/resend-confiramion-email', {
        user_email: this.email,
        user_password: this.password
      })
      .then( (response) => {
        this.submitInProccess = false
        
        this.message = response.data.message  
        this.$toast.success(this.message);
        //this.$router.push('/login')
        
      })
      .catch( (error) => {
        this.submitInProccess = false
        //console.log(error);
        //this.$toast.error(error);
      });
      
    },
    submit() {

      this.$store.dispatch('login', {
        user_email: this.email,
        user_password: this.password
      }).then((res) => {
        //console.log(res)
      //}).then(() => {
        /**
         * 
         * this.$toast.success("Logged in succefully");
           this.$router.push('/home')
         */
         this.$toast.success("Logged in succefully");
         this.$router.push('/home')
           

           //setTimeout(this.$router.push('/home'), 1000)
          
      }).catch((err)=>{
        if(err) {
          
          if(err.message){
            this.$toast.error(err.message);
            console.log(err)
            if(err.code && err.code == 88){
              console.log(err)
              this.$router.push('/signup?confirm_terms=1&user_email=' + err.user.user_email + '&user_account_uuid=' + err.user.user_account_uuid)  
            }
          }else if(err.code && err.code == 77){
            this.$toast.info("Please tell us about yourself");
            this.$router.push('/on-board/organization')
          }
          
          /*
          
          if(err.match(/terms/)){
            this.$router.push('/signup?confirm_terms=1&user_email=' + data.user.user_email + '&user_account_uuid=' + data.user.user_account_uuid)
          }
          */
          
        }
        
        
      })
      /*
      this.$http.post('/auth/login', {
        user_email: this.email,
        user_password: this.password
      })
      .then( (response) => {
        console.log(response.data)   
        let data = response.data
         if(!data.token){
            this.showErrortxt = true;
            this.loginErrorText = data.info.message;
        }else{
            this.$store.dispatch('login', data)
            .then(() => {
                this.$router.push('/home')
            })
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
      */
    },
    recover: function () {
      this.$emit('recoverPassword', true)
    },
    back: function () {
        this.$emit('emailLogin', false);
    }
  }
};
</script>
<style>

</style>
