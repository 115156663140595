<template>
  <div class="container">
    <div class="col-lg-6 col-md-6 ml-auto mr-auto">
        <login-options 
          v-if="!emailLogin && !recoverPassword && !showRecoverToken" 
          @emailLogin="updateEmail"/>

        <login-form 
          v-if="emailLogin && !recoverPassword"  
          @recoverPassword="recoverPasswordFunc" 
          @emailLogin="updateEmail"/>

        <pass-recover 
          v-if="!emailLogin && recoverPassword"
          @emailLogin="updateEmail" />
        
        <new-pass v-if="showRecoverToken" :token="recoverToken" @emailLogin="updateEmail"/>
    </div>
  </div>
</template>
<script>
import LoginForm from './Login/LoginForm';
import PassRecover from './Login/PassRecover';
import LoginOptions from './LoginOptions';
import NewPass from './Login/NewPass';


export default {
  components: {
    PassRecover,
    LoginForm,
    LoginOptions,
    NewPass
  },
  data() {
    return {
      emailLogin: false,
      recoverPassword: false,
      recoverToken: this.$route.query.token,
      showRecoverToken: this.$route.query.token,
      registrationConfirmationToken: this.$route.query.confirmation_token
    };
  },
  created() {
    //console.log(this.registrationConfirmationToken)
    if(this.registrationConfirmationToken){
      //console.log(this.registrationConfirmationToken)
      this.$store.dispatch('confirm', {
        user_confirm_register_token: this.registrationConfirmationToken
      //}).then((user) => {
      }).then(() => {
        /*
        console.log("-------------")
        console.log(user)
        console.log("-------------")
        */
        /*
          this.$toast.success("Registration confirmed");
          this.$router.push('/home')
          */
          //
          //
      }).catch((err)=>{
          
          if(err.message){
            this.$toast.error(err.message);
            
            if(err.code && err.code == 88){
              this.$router.push('/signup?confirm_terms=1&user_email=' + err.user.user_email + '&user_account_uuid=' + err.user.user_account_uuid)  
            }
          }else if(err.code && err.code == 77){
            this.$toast.info("Please tell us about yourself");
            this.$router.push('/on-board/organization')
          }
      })
    }

	},
  methods: {
    updateEmail (value) { 
      //console.log(value)
      this.emailLogin = value.hasOwnProperty("emailLogin") ? value.emailLogin : !this.emailLogin;
      this.recoverPassword = value.hasOwnProperty("recoverPassword") ? value.recoverPassword : false;
      this.showRecoverToken = value.hasOwnProperty("showRecoverToken") ? value.showRecoverToken : false;
      this.recoverToken = null
    },
    recoverPasswordFunc (value) { 
      this.emailLogin = false;
      this.recoverPassword = !this.recoverPassword;
    }

  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
