<template>
  <usage-layout />
</template>

<script>
import UsageLayout from './Usage/UsageLayout.vue';
export default {
    components: {
        UsageLayout
    }
}
</script>

<style>

</style>