<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="info-text">Your Invoicing information</h3>
        </div>
      </div>  
      <div class="row justify-content-center">
        <div class="col-sm-4">

          <ValidationProvider
            name="name"
            rules="required|min:1"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_name"
            label="Company/First name"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
         
        </div>
        <div class="col-sm-3">
          <ValidationProvider
            name="taxId"
            rules="required|min:5"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_tax_number"
            label="Vat/Tax Id"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-3">
          <ValidationProvider
              name="vatId"
              rules="required|min:5"
              v-slot="{ passed, failed, errors }"
            >
            <base-input
              disabled
              textLight
              v-model="model.billing_entity_vat_number"
              label="Vat"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-if="model.billing_entity_name_eu_vat_registery_name" class="col-sm-10">
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_name_eu_vat_registery_name"
            label="EU VAT registry name">
          </base-input>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-5">
          <ValidationProvider
            name="street"
            rules="required|min:2"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_address_one"
            label="Street 1"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-5">
          <ValidationProvider
            name="street"
            rules=""
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_address_two"
            label="Street 2"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
      </div>
      <div class="row justify-content-center">
        <div v-if="model.billing_entity_name_eu_vat_registery_address" class="col-sm-10">
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_name_eu_vat_registery_address"
            label="EU VAT registry address">
          </base-input>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3"></div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <ValidationProvider
            name="city"
            rules="required|min:2"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_city"
            label="City"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-3">
          <ValidationProvider
            name="postal"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            disabled
            textLight
            v-model="model.billing_entity_postal_code"
            label="Postal Code"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-4">
          <ValidationProvider
            name="country"
            rules="required|min:5"
            v-slot="{ passed, failed, errors }"
          >
          <base-input 
           disabled
            textLight
            v-model="model.billing_entity_country_name"
            label="Country"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            
            <!--
            <el-select
              :disabled="true"
              v-model="model.billing_entity_country_name"
              class="select-primary"
              name="country"
            >
              <el-option
                v-for="country in countryOptions"
                class="select-primary"
                :label="country"
                :value="country"
                :key="country"
              ></el-option>
            </el-select>
            -->
          </base-input>
         </ValidationProvider>
        </div>
      </div>
      <br>
      <div v-if="!model.billing_entity_valid_eu_vat && model.billing_entity_country_continent =='EU'" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="info">
            <strong>Dear client!</strong> <br>
            Based on your Billing Details Your agency is located within the EU common economic area. <br>
            Unfortunately your VAT number/VAT id is not valid or is not subject to the reverse charge.<br>
            VAT cannot be accounted for by the recipient as per Article 196 of the Council Directive 2006/112/EC.<br>
            {{ billing_entity_valid_eu_vat }}% VAT will be applied to all Invoice items.<br><br>
            For any question please don't hesitate to contact our support team support@message-deck.com
          </base-alert>
        </div>
      </div>
      <div v-if="!model.billing_entity_name" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="danger">
            <strong>Important!</strong> <br>
            It seems that we are missing your Billing Details<br> 
            To proceed with this payment method please go to Settings/account and fill in your Billing Details.<br>
          </base-alert>
        </div>
      </div>
      
     
    </form>
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { Modal, BaseAlert } from 'src/components';
extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option
  },
  

  data() {
    return {
      billing_entity_valid_eu_vat: 19,// get this from vat validation
      model:{
        billing_entity_name: null,
        billing_entity_address_one: null,
        billing_entity_address_two: null,
        billing_entity_city: null,
        billing_entity_country_name: null,
        billing_entity_country_code: null,
        billing_entity_postal_code: null,
        billing_entity_wave_customer_id: null,
        billing_entity_tax_number: null,
        billing_entity_vat_number: null,
        billing_accounts_uuid: null,
        billing_entity_valid_eu_vat: null,
        billing_entity_email: null,
        billing_entity_phone_number: null,
        billing_entity_registration_number: null
      },
      countryOptions: ['Please provide a country name']
    };
  },
  mounted(){ 
    this.getBillingData()
  },
  methods: {
    getBillingData(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.get(api_endpoint + '/billing/billing/read')
      ])
      .then(([result1]) => {
        this.model = result1.data
        
      })
      .catch(function(err){
        console.log('There is an error', err);
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      
      return this.$refs.form.validate().then(res => {
        if (!res) {
          return;
        }
        //console.log(res)
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
