<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="info-text">Your credit card information</h3>
        </div>
      </div>  
      <div class="row justify-content-center">
        <div class="col-sm-6">

          <ValidationProvider
            name="cardNumber"
            rules="required|min:5"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="model.cardNumber"
            label="Card Number"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
         
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <ValidationProvider
            name="cardHolder"
            rules="required|min:2"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="model.cardHolder"
            label="Card Holder"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-2">
          <ValidationProvider
            name="expireMonth"
            rules="required|min:2"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="model.expireMonth"
            label="Expire Month"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-2">
          <ValidationProvider
            name="expireYear"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="model.expireYear"
            label="Expire Year"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-2">
          <ValidationProvider
            name="cvv"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="model.cvv"
            label="CVV"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
      </div>
     
    </form>
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
        model : {
            cardNumber: "5373 8172 9406 5052",
            cardHolder: "John Smith",
            expireMonth: "",
            expireYear: "",
            name: "",
            cvv: "",
            currentYear: new Date().getFullYear(),  
        }
      
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      
      return this.$refs.form.validate().then(res => {
        console.log(res);
        console.log(this.model);
        if (!res) {
          return;
        }
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style></style>
