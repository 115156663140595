<template>
  <card >
    <h4 class="card-title">Campaign Account</h4>
    <hr>
    <el-table :data="tableData">
      <el-table-column 
            :min-width="50"
            prop="http_api_jwt_short_token"
            label="Token">
      </el-table-column>
      <el-table-column 
          :min-width="100"
          prop="http_api_jwt_tp"
          label="Throughput">
          <template slot-scope="scope">
            {{scope.row.http_api_jwt_tp}}/Sec
          </template>
      </el-table-column>
      <el-table-column 
            :min-width="100"
            prop="http_api_jwt_mode"
            label="Mode">
      </el-table-column>
       <el-table-column 
            :min-width="100"
            prop="http_api_jwt_type"
            label="Type">
      </el-table-column>
      
      <el-table-column 
            :min-width="100"
            label="Status">
            <template slot-scope="scope">
              <i v-if="scope.row.http_api_jwt_status" class="fa fa-check-circle text-success"></i>
              <i v-else class="fa fa-times-circle text-danger"></i>
            </template>
      </el-table-column>
      
      <el-table-column
        label="Created">
          <template slot-scope="scope">
            {{ (new Date(scope.row.http_api_jwt_updated_at)).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', }) }}
          </template>
      </el-table-column>
    </el-table>
    
  </card>
</template>

<script>
import {Table, TableColumn} from 'element-ui'
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  //props: ['campKey'],
  props: ['tableData'],
  data(){
    return {}
  },
  

}
</script>

<style>

</style>