<template>
  <div style="min-height: 200px">
    <div class="row">
      <div class="col-lg-12">  
        <h3 style="margin:0"><u>Schedule</u></h3>
        <small>Set the schedule of the campaign.</small>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <base-button type="primary" class="btn btn-simple" @click="toggle(false)">
          <i class="fa fa-calendar-alt"></i> &nbsp; Schedule By Date
        </base-button>
        <base-button type="primary" class="btn btn-simple" @click="toggle(true)">
          <i class="fas fa-redo"></i> &nbsp; Recurring Schedule
        </base-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="row" v-if="data.type">
            <div class="col-lg-12">
              <h5 style="margin:0"><u>Summery:</u></h5>
              
              <div class="row" v-if="data.type=='schedule'">
                <div class="col-lg-12">
                  <br>
                  <h6><i class="fas fa-calendar-alt"></i> &nbsp; Scheduled By Date</h6>
                  <i class="fa fa-clock" aria-hidden="true"></i>  {{data.setting.timePicker}} ({{data.setting.timeZone}})<br>
                  <i class="fa fa-calendar-alt" aria-hidden="true"></i>  {{data.setting.formatedDateByTimeZone}}
                </div>
              </div>
              <div class="row" v-else-if="data.type=='repeat'">
                <div class="col-lg-12">
                  <br>
                  <h6><i class="fas fa-redo"></i> &nbsp; Recurring Campaign.</h6>
                  <h6><i class="fa fa-calendar-alt"></i> &nbsp; Recurring Shedule Settings: <br><br>
                  <ul>
                    <li v-for="(item, index) in data.setting.crons" :key="index">
                      {{item.label}}: &nbsp;&nbsp;{{ parseReccuringRow(item) }} <br>
                    </li>
                  </ul>
                  
                  </h6> 
                
                </div>
              </div>
              
            </div>
          </div>
      </div>
    </div>
    <Modal @done="done" :repeat="type" ref="modal"/>

  </div>
</template>

<script>
import Modal from '../Schedule/Modal.vue'
const weekDays = require('../json/weekDays.json')
const monthByName = require('../json/monthByName.json')
const numericDayToName = require('../json/numericDayToName.json')
const numericMonthToName = require('../json/numericMonthToName.json')

export default {
  components:{
    Modal,
  },
  data() {
    return {
      type: false,
      data: {
        "type": null,
        "setting": []
    },
      lists: {
        daysByName: weekDays,
        monthByName: monthByName,
        daysInMonth: [...Array(31).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
        hours: [...Array(24).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
        minute: [...Array(59).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } })
      }
      
    }
  },
  methods: {
    parseReccuringRow(row){
       
      if(row.range && row.tempo == 'range'){
        if(row.short.toLowerCase() == 'day'){          
          return `${numericDayToName[row.ranges.start].label} to ${numericDayToName[row.ranges.end].label}`
        }else if(row.short.toLowerCase() == 'month'){
          return `${numericMonthToName[row.ranges.start].label} to ${numericMonthToName[row.ranges.end].label}`
        }
      }

      if(row.short.toLowerCase() == 'day'){
        return row.value ? numericDayToName[row.value - 1].label : '-every-'
      }else if(row.short.toLowerCase() == 'month'){
        return row.value ? numericMonthToName[row.value].label : '-every-'
      }
  
      return row.value ? row.value.toString().padStart(2, '0') : '-every-'
    },
    validate() {      
      if(this.data.type){
        this.$emit('on-validated', true, this.data);
        return true
      }else{
        this.$emit('on-validation-error', false, {});
        return false
      }
      
    },
    done(data){
      this.data = data
    },
    toggle(type){
      this.type = type
      this.$refs['modal'].toggle(); 
    },
   
  }
}
</script>

<style>
  .timeline-row .timeline-panel {
    background: #ffffff;
  }
</style>