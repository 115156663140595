<template>
 <card class="card-register card-white" showFooterLine>
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Signup</h4>
    </template>
    
    <div v-if="!socialRegisterMustAgreeShow">    
        <base-button @click=EmailSignUp() type="primary" round block size="lg">
            Signup with Email
        </base-button>

        <base-button @click=GoogleSignUp() type="primary" round block simple size="lg">
            <i class="fab fa-google"></i> Signup with Google
        </base-button>
        
        <base-button @click=FacebookSignUp() type="primary" round block simple size="lg">
            <i class="fab fa-facebook"></i> Signup with Facebook
        </base-button>
    </div>
    <div v-if="socialRegisterMustAgreeShow">    
        <register-social-must-agree :registeredData="registeredData" @socialTermsConfirmed="SocialSignUpTermsConfirmed"/>
    </div>

    <template slot="footer">
        Already have an account? 
        <router-link class="nav-item"  to="login"> Login</router-link>
        <h5 class="text-danger d-flex justify-content-center"  v-if="registerErrorText">
            {{registerErrorText}}
        </h5>
    </template>

</card>  
</template>

<script>
const handleMessage = (e, callback) => {
  if (typeof callback === 'function' && e.data.auth === 'passport') { callback(e.data); }
}
const base_url = process.env.VUE_APP_BASE_DOMAIN_URI

import RegisterSocialMustAgree from './Register/RegisterSocialMustAgree.vue';
      
export default {
    components: {
        RegisterSocialMustAgree
    },
    data() {
        return {
            blah: this.$route.query,
            registerErrorText: "",
            registeredData: {
                user : {
                    user_account_uuid: this.$route.query.user_account_uuid ? this.$route.query.user_account_uuid : null,
                    user_email: this.$route.query.user_email ? this.$route.query.user_email : null
                }
            },
            socialRegisterMustAgreeShow: this.$route.query.confirm_terms ? true : false
        };
    },
    created: function () {
        window.addEventListener('message', this.handleOAuthMessageCb);
    },
    destroyed: function() {
        window.removeEventListener('message', this.handleOAuthMessageCb);
    },
    methods: {
        GoogleSignUp(){
            //window.open("http://localhost/auth/google/register", 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            window.open(base_url + '/auth/google/register', 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            
            },
        FacebookSignUp(){
            //window.open("http://localhost/auth/facebook/register", 'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700px,height=900px`);
            window.open(base_url + '/auth/facebook/register', 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            },
        EmailSignUp() {
            this.$emit('emailSignUp', true);
        },
        SocialSignUpTermsConfirmed(data){
            //console.log(this.registeredData)
            /*
            let message = this.registeredData.info ? 
                            this.registeredData.info.message : "Thank you"; 
            */
            this.$store.dispatch('loginFBGGL', this.registeredData)
            .then(() => {
                //this.$toast.success(message);
                this.$router.push('/login')
            })
        },
        handleOAuthMessageCb: function(e) {
            return handleMessage(e, data => {  
                //console.log(data)
                if(data.info.error){
                    this.$toast.error(data.info.message);
                    this.registerErrorText = data.info.message  
                    return
                }
                
                

                this.socialRegisterMustAgreeShow = !data.info.error
                this.registeredData = data
                //console.log(data);
                return
                if(data.info.error){
                    this.registerErrorText = data.info.message ? data.info.message:"";
                    this.$toast.error("Unable to register");
                }else{

                }
                

            });
        }
    }
};
</script>
<style></style>
