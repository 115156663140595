<template>
  <div class="container" style="max-width: 1700px !important">

    <div class="row">
        <div class="col-lg-12" >
          <filter-area :tokenSelectData="tokensList" 
          :submitInProccess="submitInProccess" 
          @fiterData="fromFilter"
          @resetSearch="reset"/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 " >
          <results 
          :submitInProccess="submitInProccess" 
          :FilteredtData="smsData" 
          :totalRecords="totalRecords" 
          :page="searchData.page"
          :perPage="searchData.perPage"
          :pageCount="pageCount"
          :downLoadData="downLoadData"
          @pagination="pagination"
          @filterColumn="filterColumn"
          @sortColumn="sortColumn"
          @getAllDataForDownLoad="getAllDataForDownLoad"
          ref="Results"
          />
        </div>      
    </div>
    
    
  </div>

</template>


<script>
//https://materialdesignicons.com/
import FilterArea from './components/FilterArea.vue';
import MsisdnArea from './components/MsisdnArea.vue';
import Results from './components/Results.vue';


export default {
  
  components: {
    FilterArea,
    Results,
    MsisdnArea
  },
  data(){
    return {
        downLoadData: [],
        resetSearch: false,
        tokensList:[],
        SessionSearched:0,
        smsData: [],
        fiterData: false,
        fiterDataMsisdn: false,
        submitInProccess: false,
        totalRecords: 0,
        pageCount:1,
        searchData: {
          filterData: {
            client_accounting_token: null,
            country_code: null,
            mccmnc: null,
            period_start: null,
            period_end: null
          },          
          columnFilters: {},
          page: 1,
          perPage: 10
        }
    }
  },
  beforeMount(){
    this.getData()
  },
  methods: {
    //reset(data){
    reset(){  
      this.searchData.columnFilters = {}
      this.$refs.Results.resetFilters();
    },
    getData(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.get(api_endpoint + '/api/get-http-api-keys')
      ])
      .then(([result1]) => {
        this.tokensList = result1.data       
        this.submitInProccess = false
      })
      .catch((err)=>{
        this.$toast.error("Error server side data")
        console.log('There is an error', err);
      });
    },
    parseAnalyticsData(data){
      
        //this.smsData = this.smsData.concat(data)
        this.smsData = data
        //console.log(this.smsData.length)
       // console.log(this.smsData.length)
        //console.log(data)
      
    },
    sortColumn(data){
      this.searchData.sort = data
      this.filterOptions();
    },
    pagination(data){
      this.searchData.page = data
      this.filterOptions();
    },
    pagination(data){
      this.searchData.page = data
      this.filterOptions();
    },
    filterColumn(data){
      //console.log(data)
      this.searchData.columnFilters[data.key] = data.value
      this.searchData.page = 1
      this.filterOptions();
    }, 
    fromFilter(data){
      this.searchData.filterData.client_accounting_token = data.token
      this.searchData.filterData.country_code = data.country ? data.country.abbreviation : null
      this.searchData.filterData.mccmnc = data.operator ? data.operator.mcc + data.operator.mnc : null
      this.searchData.filterData.period_start = data.timeRange.from
      this.searchData.filterData.period_end = data.timeRange.to
        
      this.filterOptions();
    },
    //filterOptions(data){
    filterOptions(){
      
      
      this.smsData = []
      
      this.submitInProccess = !this.submitInProccess
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/analytics/search', this.searchData)
      .then( (response) => {
        this.smsData = response.data.rows
        this.totalRecords = response.data.totalRecords
      
        this.pageCount = Number((this.totalRecords / this.searchData.perPage).toFixed(0))
        this.submitInProccess = !this.submitInProccess
        
      })
      .catch( (error) => {
        
      });
      //console.log(data)
    },
    async getAllDataForDownLoad(){
      this.submitInProccess = !this.submitInProccess
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      let localFilter = this.searchData
      localFilter['perPage'] = this.totalRecords
      let res = await this.$http.post(api_endpoint + '/analytics/search',localFilter)
      this.submitInProccess = !this.submitInProccess      
      if(res.status == 200){
        //console.log(res.data.rows)
        this.downLoadData = res.data.rows
        let csv = 'Accounting token,Protocol,Sender,Receiver,Requested delivery status,Current status,Current status description,Mcc,Mnc,Operator,Country code,Country name,Our message id,Your message id,Message text,Key mode,Delivery report date,Message parts,Timestamp,Message price\n';
        res.data.rows.forEach((row) => {
          csv += Object.keys(row).map(key=>{return row[key]}).join(',') + "\n";
        });

        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'messages.csv';
        anchor.click();
      }
      

    }
  },
  
}
</script>

<style>

</style>