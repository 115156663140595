var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{attrs:{"label":"Specify Timezone"}},[_c('el-select',{staticClass:"select-primary",attrs:{"size":"large","placeholder":"Timezone Select"},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}},_vm._l((_vm.timeZones),function(option){return _c('el-option',{key:option.label,staticClass:"select-primary",attrs:{"value":option.value,"label":option.label}})}),1)],1),_c('span',{staticClass:"local-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('Crons',{ref:"crons",attrs:{"crons":_vm.crons},on:{"done":_vm.cronsForm}}),_c('button',{ref:"myButton",staticStyle:{"display":"none"},attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit(valid)}}},[_vm._v("Click me")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }