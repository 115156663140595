<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
       <h1 class="card-title">
            This feature is coming soon!
        </h1>

    </div>
  </div>
</template>
<script>
import {Badge} from '/src/components'

export default {
  components: {
    Badge
  },
};
</script>
<style></style>
