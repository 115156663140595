<template>
<div>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <h3 class="info-text">Summery</h3>
    </div>
  </div>
  <div class="row justify-content-center">

    <div class="col-lg-8">
      <div class="row">

        <div class="col-sm-5">
          <h5><u>Payment Method:</u><br> {{summeryData.method == 'bank' ? 'Wire transfer' : summeryData.method}}</h5> 
          <h5></h5> 
          
          <p>
            <u>Invoice Address:</u><br>
            {{summeryData.billing_entity_name}}<br>
            {{summeryData.billing_entity_address_one}}<br>
            {{summeryData.billing_entity_address_two}}<br>
            {{summeryData.billing_entity_postal_code}} {{summeryData.billing_entity_city}}<br>
            {{summeryData.billing_entity_country_name}}<br>
            {{summeryData.billing_entity_vat_number}}<br>
          </p>
        </div>
        <div class="col-sm-4 text-left">
          <div v-if="this.summeryData.method=='card'">
            <h5><u>Card information:</u></h5> 
            <p>
              {{summeryData.cardHolder}} <br>
              {{summeryData.cardNumber}} <br>
              {{summeryData.expireMonth}} / {{summeryData.expireYear}} <br>
              {{summeryData.cvv}}
            </p>
          </div>
        </div>
        <div class="col-sm-3 ">
          <h5 class="text-right"><u>Amount to add:</u> {{summeryData.amount}} EUR</h5> 
          <h5 class="text-right"><u>TAX/VAT Rate:</u> {{vatRate}}%</h5> 
          <h5 class="text-right"><u>VAT:</u>  {{vatTotal}} EUR
          </h5> 
          <h5 class="text-right"><u>Checkout amount:</u> {{checkOutAmount}} EUR
          </h5> 
        </div>

      </div>
    </div>
    

  </div>
</div>
</template>
<script>
const euCountries = require('../../../json/eu-countries.json')

export default {
  components: {
   
  },
  props: ['summeryData'],
  data() {
    return {
      vatRate : 0,
      vatTotal : 0,
      checkOutAmount: 0,
      euCountries: euCountries
    };
  },
  watch:{
    'summeryData': function(nv){
      
      
      if(nv.billing_entity_name_eu_vat_registery_name){
        this.summeryData.billing_entity_name = nv.billing_entity_name_eu_vat_registery_name
        this.summeryData.billing_entity_address_one = nv.billing_entity_name_eu_vat_registery_address
        this.summeryData.billing_entity_address_two = null
        this.summeryData.billing_entity_postal_code = null
        this.summeryData.billing_entity_city = null
        this.summeryData.billing_entity_country_name = nv.billing_entity_name_eu_vat_registery_country_code
        
        
      }

      let isCountryInUnion = this.euCountries.find(obj => {
        if(obj.code == nv.billing_entity_country_code){
          return obj
        }
      })

      if(isCountryInUnion && Object.keys(isCountryInUnion)){
        isCountryInUnion = true
      }else{
        isCountryInUnion = false
      }

      
      
      
      if(nv.billing_entity_country_continent == 'EU'){
        if(
          ( !nv.billing_entity_valid_eu_vat && isCountryInUnion) || 
            nv.billing_entity_name_eu_vat_registery_country_code == 'CY'
          ){

          this.vatRate = nv.billing_entity_eu_vat
          this.vatTotal = ( this.vatRate / 100 ) * nv.amount
          this.checkOutAmount = Number(nv.amount) + this.vatTotal

        }
      }
    

    }
  },
  methods: {
    
    validate() {
      return true;
    }
  }
};
</script>
<style></style>

