<template>
    
    <div class="stages-wrapper">
        
        <div class="row h-100">
            <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block" style="border-right:1px solid">
                <div class="h-100 d-flex align-items-center justify-content-end p-4">
                    <div class="d-block">
                        <h3>Tell us about your organization</h3>
                        <p class="text-info">We'll use this info to customize your experience</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="h-100 right-column">
                    <div class="h-100 d-flex align-items-center justify-content-start p-4">
                        <div class="d-block w-100">
                            <div v-if="loading" class="local-spinner">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>

                            <div v-else>
                                <h3>Organization Details</h3>
                                <br/>
                                
                                <Form @submit="submit"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Form from './Organization/Form.vue'
export default {
    components:{
        Form
    },
    data() {
        return {
            loading: false,
            user: {}
        }
    },
    mounted(){
        this.user = this.$store.getters.user
        //if(this.user.user_confirm_is_developer) return this.$router.push('/on-board/whats-next')
    }, 
    methods: {
        async submit(form){
            this.loading = !this.loading
            const api_endpoint = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
            this.user.user_confirm_is_developer = true
            this.user.user_is_developer = form.entity_developer.toLowerCase().match(/integrations|both/) ? true : false

            const res = await this.$http.post(api_endpoint + '/set-developer',  this.user)
            if(res && res.status == 200){
                const done = await this.$store.dispatch('developer', this.user)
                this.loading = !this.loading
                this.$toast.success("Thank you!");
                return this.$router.push('/on-board/whats-next')  
                
            }
            this.loading = !this.loading
            return this.$toast.error("Something went wrong, please contact support");
        }
    }
}
</script>

<style>
    .stages-wrapper {
        margin-top:5%;
        height:70vh;
    }
    

    .stages-wrapper .el-select {
        width:100%
    }
    .stages-wrapper h3 {
        margin: 0 !important;
    }

    
    .stages-wrapper label {
        color:#fafffe;
    }
    .stages-wrapper .el-select .el-input__inner {
        background: #1e1e2f;
        color: #fafffe !important;
    }

    .right-column {
        max-width:430px;
    } 

    .local-spinner {
        color: white;
        font-size: 32px;
    }

    @media (max-width: 992px) {
        .stages-wrapper .right-column{
            margin-left: auto;
            margin-right: auto;
        }
    }
    
</style>