<template>

  <card>
    <div class="my-padding">
      <h4 class="card-title">HTTP API Billing tokens</h4>
      <hr>
       <base-button @click="modals.modalAddKey = true" type="info">
        <mdicon name="cash-plus" size="22" /> &nbsp;&nbsp; Add billing token  
       </base-button>

        <el-table :data="tableData">


          <el-table-column type="index">
          </el-table-column>

          <el-table-column prop="billing_tokens_token"
                          label="Key">
          </el-table-column>

          
          <el-table-column label="Description">
            
            <template slot-scope="scope">
              
               <base-input
                class="border-0"
                :editiable="true"
                @editing-input-start="editRow(scope.$index)"
                @editing-input-end="saveRow(scope.$index)"
                toggleIcon
                addonRightIcon="floppy"
                addonRightIconToggle="square-edit-outline"
                style="color:white"
                label=""
                placeholder=""
                v-model="scope.row.billing_tokens_description"
                value:scope.row.billing_tokens_description
              >

              </base-input>
              
            </template>
          </el-table-column>


        <!--
          <el-table-column
            label="Active">
            <template slot-scope="props">
              <base-switch v-model="props.row.api_keys_key_status" @input="updateKeyStatus(props.row)">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-exclamation" style="color:red;" slot="off"></i>
              </base-switch>
            </template>
          </el-table-column>
        -->
        </el-table>

        <modal :show.sync="modals.modalAddKey"
               body-classes="p-0 "
               modal-content-classes="no-background"
               modal-classes="modal-lg">
            <card 
                  type="secondary"
                  header-classes="bg-black pb-5"
                  body-classes="px-lg-5 py-lg-5 "
                  class="border-0 mb-0">
                
                    <h4 class="card-title">Add billing</h4>
                    <p class="card-text text-light">
                      A billing token can take a few seconds to come online.
                      You will recive an email notification when your token is active.
                      <br><br>
                      A billing token is used for accounting purposes for our various APIs.<br>
                      See our API documentation on how to use it.
                      <br><br>
                      You must have at least one billing token to be able to send messages and use other API's
                      <br>
                      
                    </p>
                  <br>
                  <br>
                    <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(createApiKey)">
                      <ValidationProvider
                      rules="required|name:5"
                      v-slot="{ passed, failed, errors }">
                          <base-input 
                            v-model="newKey.billing_keys_description" 
                            alternative
                            class="mb-3"
                            placeholder="Billing token description">
                          </base-input>
                          <span class="validation-error">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <div class="text-center">
                          <base-button :loading="submitInProccess" native-type="submit" type="primary" class="btn-fill ">
                            Create billing token
                          </base-button>
                      </div>
                        
                    </form>
                    </ValidationObserver>
                
            </card>
        </modal>
      </div>
  </card>
  
</template>

<script>

import {Table, TableColumn, Select, Option} from 'element-ui'
import { BaseSwitch } from '@/components';
import {Modal} from 'src/components'

import { extend } from "vee-validate";
import { required,min } from "vee-validate/dist/rules";


extend("name",{
  ...min,
  message: 'Description must be at leat 5 charachters long'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    Modal,
    BaseSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    existingApiKeys: []
  },
  watch: {
    existingApiKeys: function(ov, nv){
      //console.log(this.existingApiKeys)
      this.tableData = this.existingApiKeys
    }
  },
  data() {
      return {
        submitInProccess: false,
        modes: [
          'Live',
          'Test'
        ],
        modals:{
          modalAddKey: false
        },
        newKey: {
          billing_tokens_description: null,
          billing_tokens_protocol_name: "http",
          billing_tokens_token: null
        },
        tableData: []
      }
  },
  methods: { 
    //editRow(index){
      //console.log('edit row: ' + index)
    editRow(){
    },
    saveRow(index){
      this.tableData[index].billing_tokens_description
      this.submitInProccess = !this.submitInProccess;  
      this.updateKey(this.tableData[index])    
    },
    updateKey(row){
      
      this.submitInProccess = !this.submitInProccess; 
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/billing/update-routing-token', row)
      .then( (response) => {
        this.submitInProccess = !this.submitInProccess;
        this.$toast.success("Billing token updated");
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
      
      });
    },
    createApiKey()  {
      
      this.submitInProccess = !this.submitInProccess;      
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/billing/create-routing-token', this.newKey)
      .then( (response) => {
        this.submitInProccess = !this.submitInProccess;
      
        this.modals.modalAddKey = false;
        this.newKey.billing_keys_key = response.data.api_keys_key;
        
        //console.log(this.newKey)
        this.tableData.push(this.newKey)
        this.$toast.success("Key added");
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
      
      });
      
    }
  }
}
</script>

<style>
.no-background{
  background: none;
}
.my-padding{
  padding-left:30px;
  padding-right:30px;
}
hr{
  
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.el-table th.el-table__cell{
  background:none !important;
  border:none !important;
}

.el-table td.el-table__cell{
  background:none !important;
  border-bottom: rgba(255, 255, 255, 0.1) !important;
}

</style>