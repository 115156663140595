<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-7">
                    <h3 style="margin:0"><u>Name And Description</u></h3>
                    <small>Provide a name and a description for this campaign.</small>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-lg-12">
                    <ValidationObserver ref="observer" v-slot="{ valid }">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <ValidationProvider
                                        rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input
                                            label="Name"
                                            v-model="campaignName"
                                            :class="{  'has-success': passed,  'has-danger': !passed }"
                                            type="text"
                                            placeholder="Name"
                                        />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">                    
                    <label>Description</label>
                    <base-textarea
                        :class="{  
                        'borderRadius': true
                        }"
                        type="text"
                        label=""
                        placeholder="Description"
                        v-model="campaignDescription">
                    </base-textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: 'This field is required'
});
export default {
    data() {
        return {
            campaignName: "My name",
            campaignDescription: null
        }
    },
    methods: {
        async validate() {
            const valid =  await this.$refs.observer.validate()
            if(valid){
                this.$emit('on-validated', true, { name: this.campaignName, campaignDescription: this.description });
                return true
            }else{
                this.$emit('on-validation-error', false, {});
                return false
            }
            
        }
    }
}
</script>

<style>

</style>