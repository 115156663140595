var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"card card-login  card-white"},[_c('template',{slot:"header"},[_c('img',{staticClass:"card-img",staticStyle:{"max-height":"250px"},attrs:{"src":"/img/card-primary.png","alt":"Card image"}}),_c('h4',{staticClass:"card-title"},[_vm._v("Reset")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|passwordValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{class:{ 
                'has-success': !errors[0] && _vm.new_password, 
                'has-danger': errors[0] 
            },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","label":"Enter new password","placeholder":"Enter new password","error":errors[0]},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('base-input',{class:{ 
                'has-success': !errors[0] && _vm.verify_new_password, 
                'has-danger': errors[0] 
            },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","label":"Verify new password","placeholder":"Verify new password","error":errors[0]},model:{value:(_vm.verify_new_password),callback:function ($$v) {_vm.verify_new_password=$$v},expression:"verify_new_password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"category form-category"},[_vm._v("* Required fields")]),_c('base-button',{attrs:{"native-type":"submit","type":"primary","round":"","block":"","size":"lg"}},[_vm._v(" Reset password ")])],1)]}}])}),_c('template',{slot:"footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 d-flex justify-content-start"},[_c('a',{staticClass:"nav-link text-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"tim-icons icon-minimal-left"}),_vm._v(" Login options ")])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }