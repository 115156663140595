<template>
<h3 class="card-title" style="text-align:center;margin:10px 0">Use your PayPal account - minimum amount {{minAmount}} EUR.</h3>
</template>
<script>

export default {
  props:['minAmount'],
  data() {
    return {
        
    };
  },
  methods: {
    
  }
};
</script>
<style></style>
