var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('card',{staticStyle:{"min-height":"600px"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Sender, text and token")])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('el-select',{staticClass:"select-primary",staticStyle:{"width":"100%","margin-bottom":"15px"},attrs:{"disabled":!_vm.tokenSelectData.length,"filterable":"","value-key":"mnc","label":"Accounting token","placeholder":"Accounting token"},on:{"change":_vm.selectToken},model:{value:(_vm.accountigToken),callback:function ($$v) {_vm.accountigToken=$$v},expression:"accountigToken"}},_vm._l((_vm.tokenSelectData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.http_api_jwt_short_token + ' ' + item.http_api_jwt_description,"value":item.http_api_jwt_short_token}})}),1)],1),_c('p',[_vm._v(" Sender IDs to test: ")]),_c('tags-input',{attrs:{"placeholder":"Enter sender "},on:{"change":_vm.addedSenderId},model:{value:(_vm.dynamicTags),callback:function ($$v) {_vm.dynamicTags=$$v},expression:"dynamicTags"}}),_c('br'),_c('br'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('p',[_vm._v(" Message text: ")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 
                            'has-success': !errors[0] && _vm.MessageText, 
                            'has-danger': errors[0] || !_vm.MessageText
                            }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.MessageText),expression:"MessageText"}],staticClass:"form-control text-area",attrs:{"placeholder":"Test message text"},domProps:{"value":(_vm.MessageText)},on:{"keyup":function($event){return _vm.testTextAdded()},"input":function($event){if($event.target.composing){ return; }_vm.MessageText=$event.target.value}}})]),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}])}),_c('h6',[_vm._v(" "+_vm._s(( _vm.textIsGsm ? '7bit' : 'Unicode'))+": "+_vm._s(_vm.MessageText.length)+" characters ")]),_c('h6',[_vm._v(" "+_vm._s(_vm.getMessageCount())+" messages count ")])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }