<template>
  
        <ValidationObserver  v-slot="{ handleSubmit, valid }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|entity_name:2"
                            v-slot="{ passed, failed, errors }">
                            <label>Organization name</label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="text"
                                placeholder="Organization name"
                                v-model="form.entity_name"
                            >
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="entity_name:1"
                            v-slot="{ passed, failed, errors }">
                            <label>Organization website</label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="url"
                                placeholder="Your Website"
                                v-model="form.entity_website">
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required:entity_name:1"
                            v-slot="{ passed, failed, errors }">
                            <label class="d-block">
                                Country of legal entity
                            </label>
                            <el-select 
                                filterable
                                v-model="form.entity_country" 
                                value-key="abbreviation"
                                label="Country of legal entity"
                                placeholder="Country">
                                <el-option
                                    v-for="item in countries"
                                    :key="item.abbreviation"
                                    :label="item.country"
                                    :value="item">
                                </el-option>
                            </el-select>

                        <span v-if="failed" class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required:entity_name:1"
                            v-slot="{ passed, failed, errors }">
                            <label class="d-block">
                                Intended Usage
                            </label>
                            <el-select 
                                filterable
                                v-model="form.entity_developer" 
                                label=""
                                placeholder="Usage">

                                    <el-option label="In platfrom Marketing" value="Marketing"/>
                                    <el-option label="API Integrations" value="Integrations"/>
                                    <el-option label="Both" value="Both"/>
                                
                            </el-select>

                        <span v-if="failed" class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <br/>
                <base-button simple native-type="submit" type="primary" class="btn-fill">
                    Save and continue
                </base-button>
            </form>
        </ValidationObserver>
  
</template>

<script>
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
import { Select, Option } from 'element-ui'
import countries from 'country-json/src/country-by-abbreviation.json';

extend("entity_name",{
  ...min,
  message: 'Name is too short'
})
extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option
    },

    data() {
        return {
            countries: countries,
            form: {
                entity_name: null,
                entity_website: null,
                entity_country: null,
                entity_developer: null
            }
           /*
            form: {
                "entity_name": "veloxbit",
                "entity_website": "https://veloxbit.com",
                "entity_country": {
                    "country": "Cyprus",
                    "abbreviation": "CY"
                },
                "entity_developer": "Marketing"
            }
            */
        }
    },
    methods: {
        submit(){
            this.$emit('submit', this.form)
        }
    }
}
</script>

<style>

</style>