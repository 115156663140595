var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.showModal,"body-classes":"p-0","modal-content-classes":"no-background","modal-classes":"modal-lg"},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('card',{staticClass:"border-0 mb-0",attrs:{"type":"secondary","header-classes":"bg-black pb-5","body-classes":"px-lg-5 py-lg-5 ","id":"smpp-form"}},[_c('h4',{staticClass:"card-title"},[_vm._v("Add access key")]),_c('p',{staticClass:"card-text text-light"},[_vm._v(" An SMPP key requires moderator approval. You will recive an email notification when your key is active. "),_c('br'),_c('br'),_vm._v(" The difference between a live key and a test key is that a live key actually sends a message, while a test key doesn't. "),_c('br'),_vm._v(" However, when you send a message using a test key, you will get a fake delivery report back. ")]),_c('br'),_c('br'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.create)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|name:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-3",attrs:{"alternative":"","placeholder":"Api key description"},model:{value:(_vm.newKey.smpp_key_description),callback:function ($$v) {_vm.$set(_vm.newKey, "smpp_key_description", $$v)},expression:"newKey.smpp_key_description"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('el-select',{staticClass:"select-primary",staticStyle:{"width":"100%"},attrs:{"filterable":"","value-key":"abbreviation","label":"Mode","placeholder":"Mode"},model:{value:(_vm.newKey.smpp_key_mode),callback:function ($$v) {_vm.$set(_vm.newKey, "smpp_key_mode", $$v)},expression:"newKey.smpp_key_mode"}},_vm._l((_vm.modes.modes),function(mode){return _c('el-option',{key:mode,attrs:{"label":mode,"value":mode}})}),1),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('el-select',{staticClass:"select-primary",staticStyle:{"width":"100%"},attrs:{"filterable":"","value-key":"abbreviation","label":"Type","placeholder":"Type"},model:{value:(_vm.newKey.smpp_key_type),callback:function ($$v) {_vm.$set(_vm.newKey, "smpp_key_type", $$v)},expression:"newKey.smpp_key_type"}},_vm._l((_vm.types.types),function(type){return _c('el-option',{key:type,attrs:{"label":type,"value":type}})}),1),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('br'),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"btn-fill ",attrs:{"loading":_vm.submitInProccess,"native-type":"submit","type":"primary"}},[_vm._v(" Create API key ")])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }