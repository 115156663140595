var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:{  'has-success': passed,  'has-danger': !passed },attrs:{"label":"Name","type":"text","placeholder":"Name"},model:{value:(_vm.campaignName),callback:function ($$v) {_vm.campaignName=$$v},expression:"campaignName"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_vm._v("Description")]),_c('base-textarea',{class:{  
                    'borderRadius': true
                    },attrs:{"type":"text","label":"","placeholder":"Description"},model:{value:(_vm.campaignDescription),callback:function ($$v) {_vm.campaignDescription=$$v},expression:"campaignDescription"}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7"},[_c('h3',{staticStyle:{"margin":"0"}},[_c('u',[_vm._v("Name And Description")])]),_c('small',[_vm._v("Provide a name and a description for this campaign.")])])])}]

export { render, staticRenderFns }