<template>
    <ValidationObserver  v-slot="{ handleSubmit, valid }">
        <form id="form" ref="form" @submit.prevent="handleSubmit(submit)">
            <div class="row">
                <div class="col-lg-4">
                    <base-input :label="cron.label">
                        <el-select
                        class="select-primary"
                        size="large"
                        :placeholder="cron.label"
                        v-model="tempo">

                            <el-option
                            v-for="option in options"
                            class="select-primary"
                            :value="option.value"
                            :label="`${option.label} ${cron.short}`"
                            :key="option.label">
                            </el-option>

                        </el-select>
                    </base-input>
                    
                </div>
                <div class="col-lg-8" v-if="tempo == 'specific'">
                    <base-input :label="cron.short">
                        <el-select
                            class="select-primary"
                            size="large"
                            :placeholder="cron.label"
                            v-model="models.specific">

                                <el-option
                                v-for="option in lists[cron.list]"
                                class="select-primary"
                                :value="option.value"
                                :label="`${option.label}`"
                                :key="option.label">
                                </el-option>

                        </el-select>
                    </base-input>
                </div>

                <div class="col-lg-8" v-if="tempo == 'every'">
                    <base-input 
                        :label="cron.short"
                        :disabled="true"
                        :value="`every ${cron.short}`"
                        type="white"
                        :placeholder="`Every ${cron.name}`">
                    </base-input>
                </div>
                

                <div class="col-lg-4" v-if="tempo == 'range'">
                    <base-input 
                    label="From"
                    type="white"
                    placeholder="From">

                        <el-select
                        class="select-primary"
                        size="large"
                        placeholder="From"
                        v-model="models.range.start">

                            <el-option
                            v-for="option in lists[cron.list]"
                            class="select-primary"
                            :value="option.value"
                            :label="`${option.label}`"
                            :key="option.label">
                            </el-option>

                        </el-select>

                    </base-input>
                </div>
                <div class="col-lg-4" v-if="tempo == 'range'">
                    <base-input 
                        label="To"
                        type="white"
                        placeholder="To">

                        <el-select
                        class="select-primary"
                        size="large"
                        placeholder="To"
                        v-model="models.range.end">

                            <el-option
                            v-for="option in lists[cron.list]"
                            class="select-primary"
                            :value="option.value"
                            :label="`${option.label}`"
                            :key="option.label">
                            </el-option>

                        </el-select>


                    </base-input>
                </div>
                
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { Select, Option } from 'element-ui'
const weekDays = require('../json/weekDays.json')
const monthByName = require('../json/monthByName.json')

export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option
    },
    props: {
        cron: {
            type: Object,
            default: {}
        },
        value: {
            type: Object,
        },
    },
    created(){},
    data(){
        return {
            tempo: 'specific',
            models:{
                specific: null,
                range: {
                    start: null,
                    end: null
                }
            },
            lists: {
                daysByName: weekDays,
                monthByName: monthByName,
                daysInMonth: [...Array(31).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
                //hours: [...Array(24).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
                hours: [10,11,12,13,14,15,16,17,18,19,20,21,22,23].map((day)=> {return { "label": day + 1, "value": day + 1 } }),
                minute: [...Array(59).keys()].map((day)=> {return { "label": day + 1, "value": day + 1 } })
            },
            options: [
                {
                    label: 'Every',
                    value: 'every'
                },
                {
                    label: 'Specific',
                    value: 'specific'
                },
                {
                    label: 'Range Of',
                    value: 'range'
                }
            ],
        }
    },
    methods:{
        submit(valid){
            console.log(valid)
        }
    }

}
</script>

<style>
    
</style>