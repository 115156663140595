<template>
  
    
      <card class="card h-90" :header-classes="{ 'text-right': isRTL }">
        <h4 slot="header" class="card-title">Number / Message-id Search</h4>
        
        <form @submit.prevent="submit()">
        <div class="row">
            <div class="col-lg-12">
                <ValidationProvider
                    rules="phone_len|min:8|max:14"
                    vid="filterNumber"
                    v-slot="{ passed, failed, errors,valid }">

                    <base-input 
                ref="filterNumber"
                v-model="filterNumber" 
                :error="ChechErros(errors)"
                label="Phone number">
                </base-input>
                </ValidationProvider>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12">
                <ValidationProvider
                    vid="filterMsgId"
                    rules="msgid_len|min:8"
                    v-slot="{ passed, failed, errors,valid }">
                <base-input  
                v-model="filterMsgId" 
                :error="ChechErros(errors)"
                label="Message-id">
                </base-input>
                </ValidationProvider>
            </div>
        </div>
            <base-button :loading="submitInProccess" 
            :disabled="!isFormValid"  
            native-type="submit" type="primary" class="btn-fill">
            Apply
          </base-button>
          &nbsp;
          <base-button @click="resetSearchFields" native-type="button" type="default" class="btn-fill">
            Reset
            </base-button>
        </form>
        
      </card>
    
  
</template>

<script>
import { extend,validate } from "vee-validate";
import { required,min,max,email } from "vee-validate/dist/rules";


extend('msgid_len', {
    validate: (value) => {
      //return ['', null, undefined].indexOf(value) === -1
      return {
        valid: ['', null, undefined].indexOf(value) === -1, // or false
        data: {
            required: false
        }
    };
  },
  computesRequired: true,
  message: 'Message-id too short'
})

extend('phone_len', {
  validate: (value) => {
      //return ['', null, undefined].indexOf(value) === -1
      return {
        valid: ['', null, undefined].indexOf(value) === -1, // or false
        data: {
            required: false
        }
    };
  },
  computesRequired: true,
  message: 'Digits only, country code is required'
});
/*
extend("phone_len",{
 async validate(value, { min, max }) {
     console.log(value)
        const res = await validate(value, `numeric|min:${min}|max:${max}`,)
            return res.valid;
  },
  params: ['min', 'max'],
  message: 'The {_field_} must be between {min} and {max} digits'
})
*/

export default {
    components:{
    },
    computed: {
        isRTL() {
            return this.$rtl.isRTL;
        },
    },
    props: {
        submitInProccess: false,
    },
    data() {
        return {
            isFormValid: false,
            filterNumber: null,
            filterMsgId: null
        }
    },
    methods: {
        ChechErros(errors){
            
            if( ( this.filterNumber == null || this.filterNumber == '' ) && 
                    ( this.filterMsgId == null || this.filterMsgId == '' )){
                
                this.isFormValid = false

            }

            if(this.filterNumber != null && this.filterNumber != ''){
                if(this.filterNumber.match(/^[0-9]+$/) && this.filterNumber.length >= 8 &&  this.filterNumber.length <= 14){
                      this.isFormValid = true  
                }else{
                    this.isFormValid = false
                }
            }

            if(this.filterMsgId != null && this.filterMsgId != ''){
                if(this.filterMsgId.match(/^[A-Za-z0-9]+$/) && this.filterMsgId.length >= 8 &&  this.filterMsgId.length <= 50){
                      this.isFormValid = true  
                }else{
                    this.isFormValid = false
                }
            }
            //this.isFormValid = false
            return String(errors)
            
        },
        isFormValidate(){

            //console.log(this.$refs.observer.errors)
        },
        resetSearchFields(){
            this.filterNumber = null
            this.filterMsgId = null
        },
        submit(){      
            let SearchObject = {
                filterNumber: this.filterNumber != null && this.filterNumber != '' ? this.filterNumber : null,
                filterMsgId: this.filterMsgId != null && this.filterMsgId != '' ? this.filterMsgId : null,
                pagination:{
                    start: 0,
                    end: 100
                },
            }
            //console.log(SearchObject)     
            this.$emit('fiterDataMsisdn',SearchObject);
            /* 
            this.$emit('fiterDataMsisdn', {
                filterNumber: this.filterNumber,
                filterMsgId: this.filterMsgId
            })
            */
        }
    },
    mounted() {
        this.isFormValidate()
        this.i18n = this.$i18n;
        if (this.enableRTL) {
            this.i18n.locale = 'ar';
            this.$rtl.enableRTL();
        }
    },
    beforeDestroy() {
        if (this.$rtl.isRTL) {
            this.i18n.locale = 'en';
            this.$rtl.disableRTL();
        }
    }
}
</script>

<style>
.el-range-editor .el-range-input {
    background: none;
}
.el-range-input{
    color:white !important
}
</style>