var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12"},[_c('h3',{staticClass:"info-text"},[_vm._v("Your Invoicing information")])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Company/First name","error":errors[0]},model:{value:(_vm.model.billing_entity_name),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_name", $$v)},expression:"model.billing_entity_name"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"taxId","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Vat/Tax Id","error":errors[0]},model:{value:(_vm.model.billing_entity_tax_number),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_tax_number", $$v)},expression:"model.billing_entity_tax_number"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"vatId","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Vat","error":errors[0]},model:{value:(_vm.model.billing_entity_vat_number),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_vat_number", $$v)},expression:"model.billing_entity_vat_number"}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center"},[(_vm.model.billing_entity_name_eu_vat_registery_name)?_c('div',{staticClass:"col-sm-10"},[_c('base-input',{attrs:{"disabled":"","textLight":"","label":"EU VAT registry name"},model:{value:(_vm.model.billing_entity_name_eu_vat_registery_name),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_name_eu_vat_registery_name", $$v)},expression:"model.billing_entity_name_eu_vat_registery_name"}})],1):_vm._e(),_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col-sm-3"})]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"street","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Street 1","error":errors[0]},model:{value:(_vm.model.billing_entity_address_one),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_address_one", $$v)},expression:"model.billing_entity_address_one"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"street","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Street 2","error":errors[0]},model:{value:(_vm.model.billing_entity_address_two),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_address_two", $$v)},expression:"model.billing_entity_address_two"}})]}}])})],1)]),_c('div',{staticClass:"row justify-content-center"},[(_vm.model.billing_entity_name_eu_vat_registery_address)?_c('div',{staticClass:"col-sm-10"},[_c('base-input',{attrs:{"disabled":"","textLight":"","label":"EU VAT registry address"},model:{value:(_vm.model.billing_entity_name_eu_vat_registery_address),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_name_eu_vat_registery_address", $$v)},expression:"model.billing_entity_name_eu_vat_registery_address"}})],1):_vm._e(),_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col-sm-3"})]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"City","error":errors[0]},model:{value:(_vm.model.billing_entity_city),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_city", $$v)},expression:"model.billing_entity_city"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Postal Code","error":errors[0]},model:{value:(_vm.model.billing_entity_postal_code),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_postal_code", $$v)},expression:"model.billing_entity_postal_code"}})]}}])})],1),_c('div',{staticClass:"col-sm-4"},[_c('ValidationProvider',{attrs:{"name":"country","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"disabled":"","textLight":"","label":"Country","error":errors[0]},model:{value:(_vm.model.billing_entity_country_name),callback:function ($$v) {_vm.$set(_vm.model, "billing_entity_country_name", $$v)},expression:"model.billing_entity_country_name"}})]}}])})],1)]),_c('br'),(!_vm.model.billing_entity_valid_eu_vat && _vm.model.billing_entity_country_continent =='EU')?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-10"},[_c('base-alert',{attrs:{"type":"info"}},[_c('strong',[_vm._v("Dear client!")]),_vm._v(" "),_c('br'),_vm._v(" Based on your Billing Details Your agency is located within the EU common economic area. "),_c('br'),_vm._v(" Unfortunately your VAT number/VAT id is not valid or is not subject to the reverse charge."),_c('br'),_vm._v(" VAT cannot be accounted for by the recipient as per Article 196 of the Council Directive 2006/112/EC."),_c('br'),_vm._v(" "+_vm._s(_vm.billing_entity_valid_eu_vat)+"% VAT will be applied to all Invoice items."),_c('br'),_c('br'),_vm._v(" For any question please don't hesitate to contact our support team support@message-deck.com ")])],1)]):_vm._e(),(!_vm.model.billing_entity_name)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-10"},[_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Important!")]),_vm._v(" "),_c('br'),_vm._v(" It seems that we are missing your Billing Details"),_c('br'),_vm._v(" To proceed with this payment method please go to Settings/account and fill in your Billing Details."),_c('br')])],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }