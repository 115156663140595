<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row">     
      <div class="col-lg-5">
         <edit-user-settings-account-form :accountsObject="apiAccountObject" ></edit-user-settings-account-form>
      </div>   
      <div class="col-lg-7">
        <edit-user-settings-billing-form 
        :billingObject="apiBillingObject" 
        :countriesList="countries"
        :countriesContinents="countriesContinents"
        ></edit-user-settings-billing-form>
      </div>   
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-6">
        <edit-profile-contact-details-form :profileObject="apiProfileObject"></edit-profile-contact-details-form>
      </div> 
      
      <div class="col-lg-3 col-md-6">
        <edit-profile-change-password-form :profileObject="apiProfileObject"></edit-profile-change-password-form>
      </div>
      <div class="col-lg-6 col-md-6">
       <Users />
      </div>
    </div>  

    
  </div>

</template>
<script>
import Users from '../Pages/UsersManagement.vue'
import EditProfileContactDetailsForm from './components/EditProfileContactDetailsForm.vue';
import EditProfileChangePasswordForm from './components/EditProfileChangePasswordForm.vue';
import EditUserSettingsBillingForm from './components/EditUserSettingsBillingForm.vue';
import EditUserSettingsAccountForm from './components/EditUserSettingsAccountForm.vue';
import countriesData from 'country-json/src/country-by-abbreviation.json';
import countriesByContinents from '../../json/country-continent.json';

export default {
  components: {
    Users,
    EditProfileContactDetailsForm,
    EditProfileChangePasswordForm,
    EditUserSettingsBillingForm,
    EditUserSettingsAccountForm
  },
  data() {
    return {
      apiProfileObject: {
        user_first_name: null,
        user_last_name: null,
        user_phone_number: null,
        user_skype_id: null,
        user_email: null,
        user_role: null,
        user_uuid: null
      },
      apiAccountObject: {
        client_accounts_uuid: null,
        client_accounts_name: null,
      },
      apiBillingObject: {
        billing_entity_name: null,
        billing_registration_number: null,
        billing_vat_number: null,
        billing_tax_number: null,
        billing_email: null,
        billing_phone: null,
        billing_country: null,
        billing_country_iso: null,
        billing_city: null,
        billing_adress_one: null,
        billing_adress_two: null,
        billing_entity_wave_customer_id:null,
        billing_entity_valid_eu_vat:null

      },
      countries: countriesData,
      countriesContinents: countriesByContinents
    }
  },
  methods:{
    getDetails(){ 
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.get(api_endpoint + '/users/get-user-details'),
        //this.$http.get(api_endpoint + '/accounts/get-account-details'),
        this.$http.get(api_endpoint + '/accounts/accounts/read'),
        this.$http.get(api_endpoint + '/billing/billing/read')
      ])
      .then(([result1, result2, result3]) => {        
        this.apiProfileObject = result1.data
        this.apiAccountObject = result2.data
        this.apiBillingObject = result3.data
      })
      .catch(function(err){
        console.log('There is an error', err);
      });
    }
  },
  beforeMount () {
    this.getDetails();
  },
  mounted(){ 
    
  },
  created(){
    
  }

};
</script>
<style></style>
