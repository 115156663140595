<template>
  <div class="row" style="min-height:500px !important; color: #d3d4d9">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin:0"><u>Summery of {{campaign.name}} campaign</u></h3>
          <small>A summery of your campaign configuration.</small>
        </div>
      </div>
      <br/>
      <br/>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center">
            <div class="row" style="min-width:80% !important;">
              <div class="col-lg-4">
                <h4><u>Schedule</u></h4>
                <div class="row">
                  <div class="col-lg-12">
                    <h5 v-if="campaign.type == 'schedule'"><i class="fa fa-calendar" aria-hidden="true"></i> &nbsp; Scheduled By Date</h5>
                    <h5 v-else><i class="fa fa-redo " aria-hidden="true"></i> &nbsp; Scheduled To Repeat  ({{campaign.setting ? campaign.setting.timeZone : ''}})</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div v-if="campaign.type == 'repeat'">
                      <ParseReccuring :data="[campaign.setting.crons]"/>
                    </div>
                    <div v-else>
                      <br/>
                      <p>
                        <i class="fa fa-clock" aria-hidden="true"></i>  {{campaign.setting ? campaign.setting.timePicker: ''}} ({{campaign.setting ? campaign.setting.timeZone: ''}})<br>
                        <i class="fa fa-calendar-alt" aria-hidden="true"></i>  {{campaign.setting ? campaign.setting.datePicker : ''}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <h4><u>Message</u></h4>
                <div class="row">
                  <div class="col-lg-12">
                    <i class="fa fa-bullhorn" aria-hidden="true"></i> &nbsp;&nbsp;{{campaign.originator}}
                    <span v-if="campaign.randomLongCodeOriginator">Originator is random local phone number</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <i class="fa fa-comment" aria-hidden="true"></i> &nbsp; {{campaign.text}}
                    <span v-if="!campaign.isUnicode">
                      <el-tooltip content="Message is GSM-7" effect="light" :open-delay="300" placement="top">
                        <i class="fa fa-check-circle text-success" aria-hidden="true"></i>
                      </el-tooltip>
                    </span>
                    <span v-else>
                      <el-tooltip content="Message is Unicode" effect="light" :open-delay="300" placement="top">
                        <i class="fa fa-times-circle text-warning" aria-hidden="true"></i>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div class="row" v-if="campaign.tracking">
                  <div class="col-lg-12">
                    <h4><u>Media (link)</u></h4>
                    <div class="row">
                      <div class="col-lg-12">
                        <i class="fa fa-link" aria-hidden="true"></i> &nbsp; &nbsp; {{campaign.tracking.trackingLink}} &nbsp;
                        
                            <el-tooltip 
                                v-if="campaign.tracking.shortUrl"
                                content="Your media (tracking) link will be replaced with a short link" effect="light" :open-delay="300" placement="top">
                                  <i class="fa fa-magic" aria-hidden="true"></i>
                            </el-tooltip>
                            &nbsp;
                            <el-tooltip 
                              v-if="campaign.tracking.filterCrawlers"
                              content="Filter out apps that crawl your link, not all apps are filtered" effect="light" :open-delay="300" placement="top">
                                <i class="fa fa-eye-slash" aria-hidden="true"></i>
                            </el-tooltip>
                            &nbsp;
                            <el-tooltip 
                              v-if="campaign.tracking.appendOperator"
                              content="Append operator mccmnc to your media link, 0.002EUR/SMS." effect="light" :open-delay="300" placement="top">
                                <i class="fa fa-mobile" aria-hidden="true"></i>
                            </el-tooltip>
                            &nbsp;
                            <el-tooltip 
                              v-if="campaign.tracking.appendPrice"
                              content="Append the price of the message to your media link" effect="light" :open-delay="300" placement="top">
                                <span class="">&euro;</span>
                            </el-tooltip>
                            &nbsp;
                            <el-tooltip 
                              v-if="campaign.tracking.appendGeoData"
                              content="Append IP city and country ISO 2 letter code to your media link, 0.002EUR/SMS. (city not always availble)" effect="light" :open-delay="300" placement="top">
                                <i class="fa fa-globe " aria-hidden="true"></i> 
                            </el-tooltip>
                          
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <h4><u>Contacts</u></h4>
                <div class="row">
                  <div class="col-lg-12">
                    <i class="fa fa-users" aria-hidden="true"></i> &nbsp; &nbsp;{{campaign.contacts}} contacts
                  </div>
                  <div class="col-lg-12" v-if="campaign.rotate">
                    <i class="fa fa-pause" aria-hidden="true"></i> &nbsp; &nbsp; Set a delay of {{campaign.rotate.value}} {{campaign.rotate.period}}{{ (campaign.rotate.value > 1 ? 's' : '') }} between messages sent to the same contact 
                  </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-lg-12">
                    <i class="fa fa-filter" aria-hidden="true"></i>&nbsp; &nbsp; Contact filters: <br/><br/>
                    <div v-if="campaign.filters && campaign.filters.length">
                      <FilteredRecipients :closable="false" v-for="(item, index) in campaign.filters" :key="index" :setting="item"/>
                    </div>
                    <h4 v-else>
                      No Filters Set
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
     


    </div>
  </div>
</template>

<script>
const weekDays = require('../json/weekDays.json')
const monthByName = require('../json/monthByName.json')
const numericDayToName = require('../json/numericDayToName.json')
const numericMonthToName = require('../json/numericMonthToName.json')
import StatsCard from 'src/components/Cards/CampaignSummeryCard';
import ParseReccuring from '../Schedule/ParseReccuring.vue'
import { BaseTable } from '@/components';
import FilteredRecipients from '../Components/Filtered.vue'

import { 
  Collapse, 
  CollapseItem 
} from 'src/components';

export default {
  components:{
    StatsCard,
    Collapse,
    CollapseItem,
    ParseReccuring,
    BaseTable,
    FilteredRecipients
  },
  props:{
    campaign: {
      type: Object,
      default: () => {return { crons: [], tracking: {link: null, shortUrl: false, filterCrawlers: false } }}
    }
  },
  methods: {
    validate() {
      return true
    },
    numbericDayToName(day){
      return numericDayToName[day.value - 1] ? numericDayToName[day.value - 1].label : '-every-'
    },
    parseReccuringRow(row){
       
      if(row.range && row.tempo == 'range'){
        if(row.short.toLowerCase() == 'day'){          
          return `${numericDayToName[row.ranges.start].label} to ${numericDayToName[row.ranges.end].label}`
        }else if(row.short.toLowerCase() == 'month'){
          return `${numericMonthToName[row.ranges.start].label} to ${numericMonthToName[row.ranges.end].label}`
        }
      }

      if(row.short.toLowerCase() == 'day'){
        return row.value ? numericDayToName[row.value - 1].label : '-every-'
      }else if(row.short.toLowerCase() == 'month'){
        return row.value ? numericMonthToName[row.value].label : '-every-'
      }
  
      return row.value ? row.value.toString().padStart(2, '0') : '-every-'
    },
  }
}
</script>

<style>
  #summery-tiles .card-body {
    margin-top:0px;
    border: 1px solid #ca52e4;
  }

  #summery-tiles .info-icon {
    margin-left:auto;
    margin-right:auto;
  }
    .vertical-line {
    /* Vertical line styles go here */
    border-left: 1px solid #ffffff; /* Adjust the color and width of the line */
    height: 100px; /* Adjust the height of the line */
    position: absolute;
    left: 1%; /* Position the line in the center */
    transform: translateX(-50%); /* Adjust to center the line */
  }
</style>