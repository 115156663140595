<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <blockquote>
                    <p class="blockquote blockquote-primary">
                        You can use the following replaceable placeholders to customize your media link with your contacts information. <br />
                        These placeholders will be dynamically replaced with actual contact information before a message is sent: <br />
                    <br />
                </p>
                </blockquote>
                <h4 class="text-muted">Click to add a placehoder to your media link</h4>
                <div class="row">
                    <div class="col-lg-12">
                        <Custom 
                            v-for="(item, index) in custom"  
                            :value="item.value" :label="item.label" 
                            :key="index" 
                            @click="customized"
                        /> 
                    </div>
                </div>
                
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, valid }">
                    <form  @submit.prevent="handleSubmit(submit)">
                        <div class="row">
                            <div class="col-lg-12">
                                <ValidationProvider
                                    rules="required"
                                    v-slot="{ passed, failed, errors }">
                                        
                                        <base-input
                                        ref="linkInput"
                                        id="trackinkLinkInput"
                                        :class="{  
                                            'has-success': !errors[0] && trackingLink,
                                            'has-danger': errors[0] || !trackingLink
                                        }"
                                        type="url"
                                        label="Media (Tracking) Link"
                                        placeholder="http[s]://yoururl.com?click_id={tid}"
                                        v-model="trackingLink">
                                        </base-input>

                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <button 
                        type="submit" 
                        style="display:none" 
                        ref="myButton" >
                            Click me
                        </button>
                    </form>
                </ValidationObserver>   
            </div>
        </div>
        
    </div>
</template>

<script>
import Custom from '../Message/Custom.vue'
export default {
    components:{
        Custom
    },
    props: {
        mediaLink: {
            type: String,
            default: null
        },
        done: {
            type: Function,
            default: () => {}
        }
    },
    watch: {
        'mediaLink': {
            handler(nv){
                this.trackingLink = nv
            }
        }
    },
    data(){
        return {
            trackingLink: null,
            shortUrl: true,
            filterCrawlers: true,
            appendOperator: false,
            appendPrice: false,
            appendGeoData: false,
            custom: [
                {
                    label: 'Message id',
                    value: 'tid'
                },
                {
                    label: 'Sender',
                    value: 'sender'
                },
                {
                    label: 'Receiver',
                    value: 'receiver'
                },
                {
                    label: 'Custom 1',
                    value: 'custom1'
                },
                {
                    label: 'Custom 2',
                    value: 'custom2'
                },
                {
                    label: 'Custom 3',
                    value: 'custom3'
                },
                {
                    label: 'Custom 4',
                    value: 'custom4'
                },
                {
                    label: 'Custom 5',
                    value: 'custom5'
                }
            ]
        }
    },
    methods: {
        
        submit(){
            this.done({
                trackingLink: this.trackingLink
            })
        },
        click(){
            this.$refs['myButton'].click()
        },
        customized(macro){
            const linkInput = this.$refs.linkInput;
            if(!linkInput.value.includes('?')) this.trackingLink = linkInput.insertMacro(macro, '?')
            else this.trackingLink = linkInput.insertMacro(macro)
        }
    }
}
</script>

<style>
    #trackinkLinkInput {
        color: black !important;
    }
</style>