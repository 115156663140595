<template>
  <div class="row">
    <div class="col-lg-12">
      <p>
        Add the link to your web site or your external polatform, <br>
        Click on Advanced to view additional options.
        <!--
        Choose which item to autocomplete using placeholders, 
        each placeholder will be replaced with an acctual value.
        -->
      </p>

      <div class="row">
        <div class="col-lg-12">
          <ValidationObserver ref="formObserver" v-slot="{ handleSubmit, valid }">
          <form  @submit.prevent="handleSubmit(submit)">
            <ValidationProvider
              rules="required|customUrl"
              v-slot="{ passed, failed, errors }">
                  
                  <base-input
                    ref="linkInput"
                    :class="{  
                        'has-success': !errors[0] && mediaLink,
                        'has-danger': errors[0] || !mediaLink
                    }"
                    type="url"
                    label="Media Link (web page/external platform)"
                    placeholder="http[s]://yourUrl.com"
                    v-model="mediaLink">
                  </base-input>

                <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <div class="row">
            <div class="col-lg-12">
                <el-tooltip 
                    content="Your media (tracking) link shortened, enables click analytics. our short URLs are typically 15-20 charachters long." 
                    effect="light" :open-delay="300" placement="top">
                    <base-checkbox 
                        style="display:inline-block"
                        v-model="advanced.shortUrl">
                        Shorten my url &nbsp;
                            <i class="fa fa-magic text-info" aria-hidden="true"></i> 
                    </base-checkbox>
                </el-tooltip>
                &nbsp;&nbsp;<i class="fa fa-exclamation-circle text-warning" aria-hidden="true"></i>&nbsp; <p style="display:inline">Short URL length is 15-20 characters replacing your media link in message body.</p>
            </div>
          </div>
          </form>
          </ValidationObserver>   
        </div>
      </div>
      <br>
      <base-button type="primary" class="btn btn-simple" @click="toggle('external')">
        Dynamic Contact information
      </base-button>
      <base-button type="primary" class="btn btn-simple" @click="toggle('advanced')">
        Advanced options
      </base-button>
    </div>
    <Modal ref="external-modal" type="external" :mediaLink="mediaLink" @done="done" />
    <Modal ref="advanced-modal" type="advanced" @done="done" />
    
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import Modal from '../Tracking/Modal.vue'

extend('customUrl', {
  validate: value => {
    return /^(http|https):\/\/[^ "]+$/.test(value);
  },
  message: 'This field must be a valid URL.'
});

export default {
  components:{
    Modal
  },
  data(){
    return {
      type: 'external',
      mediaLink: null,
      advanced: {
        shortUrl: true,
        filterCrawlers: false,
        appendOperator: false,
        appendPrice: false,
        appendGeoData: false
      },
      combined: {}
    }
  },
  methods: { 
    done(form){
      this.mediaLink = form.trackingLink ? form.trackingLink : this.mediaLink
      this.combined = {
        ...{ trackingLink: this.mediaLink },
        ...this.advanced,
        ...form,
      }
      
      //this.externalDone(blah)

    },   
    submit(){

    },
    async validate(){
      const valid = await this.$refs.formObserver.validate();
      
      
      if(!valid) return
      return {
        ...{ trackingLink: this.mediaLink },
        ...this.advanced,
        ...this.combined
      }
      
    },
    toggle(type){
      this.$refs[`${type}-modal`].toggle()
      //this.$emit('toggle', 'external')
    }
  }
}
</script>

<style>

</style>