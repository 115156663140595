<template>
<div>
  
  <card>
        <h5 slot="header" class="title">Change password</h5>
        <ValidationObserver v-slot="{ handleSubmit,valid }">
        <form @submit.prevent="handleSubmit(updateProfilePassword)">
          <div class="row">
            <div class="col-lg-12">
              <ValidationProvider 
                name="password"
                rules="required|passwordValidator"
              v-slot="{ errors }">
              <base-input
                :class="{ 
                    'has-success': !errors[0] && user.newPassword, 
                    'has-danger': errors[0] 
                }"

                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                label="Enter new password"
                placeholder="Enter new password"
                :error="errors[0]"
                v-model="user.newPassword"
              >
              </base-input>
              </ValidationProvider>
            </div>  
          </div>          
          <div class="row">
            <div class="col-lg-12"
            >
              <ValidationProvider
                rules="required|confirmed:password"
                v-slot="{ errors }"
              >
              <base-input
                :class="{ 
                    'has-success': !errors[0] && user.verifyNewPassword, 
                    'has-danger': errors[0] 
                }"
                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                name="password"
                label="Verify new password"
                placeholder="Verify new password"
                :error="errors[0]"
                v-model="user.verifyNewPassword"
              >
              </base-input>
              
              </ValidationProvider>

            </div>  
          </div>
          <base-button :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
            Update password
          </base-button>
        </form>
        </ValidationObserver>
      </card>
</div>
</template>
<script>
import { extend } from "vee-validate";
import { required,numeric,digits,email,confirmed } from "vee-validate/dist/rules";
extend("email",email)
extend("digits",digits)
extend("numeric",numeric)
extend("confirmed",{
  ...confirmed,
  message: 'Passwords do not match'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:` Password must be at least 8 charachters long it must have: one number, one capital letter, one special charachter.`
});

export default {
  name: 'profile-form',
  props: {
    profileObject:{
      type: Object,
      description: 'User profile data'
    }
  },
  mounted:function(){ 
    this.user = this.profileObject;
  },
  computed: {
    
  },
  data() {
    return {
      submitInProccess: false,
      user: {
        currentPassword: null,
        newPassword: null

      }
    };
  },
  methods: {
    updateProfilePassword() {
      this.submitInProccess = !this.submitInProccess;
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/users/update-user-password', {
        user_password: this.user.newPassword
      })
      .then( (response) => {
        //console.log(response);
        this.submitInProccess = !this.submitInProccess;
        this.$toast.success("Password updated");
        this.$store.dispatch('logout')
        this.$router.push('/login')
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
        this.$toast.error("An error accured");
      });
    }
  }
};
</script>
<style>
.input-group-text{
  padding:0 15px 0 0 !important;
}

.validation-error{
  color:bisque !important;
  font-size:12px
}
.list-group-item{
  background:none;
  border:none;
}
</style>
