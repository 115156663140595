<template>
  
    
      <card class="card h-90" :header-classes="{ 'text-right': isRTL }">
        <h4 slot="header" class="card-title">Filter</h4>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
        <form @submit.prevent="handleSubmit(submit)">
        <div class="row">
            <div class="col-lg-4">
                <ValidationProvider
                        rules="required"
                        v-slot="{ passed, failed, errors }">

                <base-input label="Date range">
                    <el-date-picker        
                        v-model="timeRange"
                        type="daterange"
                        :picker-options="pickerOptions"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        align="right">
                    </el-date-picker>
                </base-input>
                </ValidationProvider>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-4">
                <base-input  label="Country">
                    
                    <el-select 
                        @change="filterCountrySelected($event)"
                        filterable
                        class="select-primary"
                        v-model="filterCountry" 
                        value-key="abbreviation"
                        label="Country"
                        placeholder="Country">
                        <el-option
                            v-for="item in countries"
                            :key="item.abbreviation"
                            :label="item.country"
                            :value="item">
                        </el-option>
                    </el-select>
                    
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input 
                :error=" !filterCountry.country ? 'Please choose a country first': ''"
                label="Operator">
                    <el-select 
                        :disabled="!filterCountry.country"
                        filterable
                        class="select-primary"
                        v-model="operator" 
                        value-key="mnc"
                        label="Operator"
                        placeholder="Operator">
                        <el-option
                            v-for="(item, index) in mccMncSelectData"
                            :key="index"
                            :label="item.network + ' ' + item.mcc + item.mnc"
                            :value="item">
                        </el-option>
                    </el-select>
                </base-input>

            </div>
            <div class="col-lg-4">
                <base-input 
                :error=" !tokenSelectData.length ? 'No tokens found': ''"
                label="Accounting token">
                    <el-select 
                        :disabled="!tokenSelectData.length"
                        filterable
                        class="select-primary"
                        v-model="token" 
                        value-key="mnc"
                        label="Accounting token"
                        placeholder="Accounting token">
                        <el-option
                            v-for="(item, index) in tokenSelectData"
                            :key="index"
                            :label="item.http_api_jwt_short_token + ' ' + item.http_api_jwt_mode"
                            :value="item.http_api_jwt_short_token">
                        </el-option>
                    </el-select>
                </base-input>

            </div>
        </div>
        <base-button :loading="submitInProccess" :disabled="!valid"  native-type="submit" type="primary" class="btn-fill">
            Apply
          </base-button>
          &nbsp;
        <base-button @click="resetSearchFields" native-type="button" type="default" class="btn-fill">
            Reset
        </base-button>
        </form>
        </ValidationObserver>
      </card>
    
  
</template>

<script>
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
import {DatePicker, TimeSelect, Select, Option} from 'element-ui'
import countriesData from '../../../../json/country-by-abbreviation.json'
import mccMncJson from '../../../../json/mcc-mnc.json';

extend("required", {
  ...required,
  message: 'This field is required'
});

var endDate = new Date();
var startDate = new Date(endDate.getTime()-7*24*60*60*1000);


export default {
    components:{
        [DatePicker.name]: DatePicker,
        [Select.name]: Select,
        [Option.name]: Option
    },
    computed: {
        isRTL() {
        return this.$rtl.isRTL;
        },
    },
    props: {
        submitInProccess: false,
        tokenSelectData: []
    },
    data() {
        return {
            
            mccMncData: mccMncJson,
            operator: {},
            token: null,
            filterCountry: {
                country: null,
                abbreviation: null
            },
            countries: countriesData,
            mccMncSelectData: [],
            //tokenSelectData: [],
            pickerOptions: {
                shortcuts: [
                {
                    text: 'Last 7 days',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    },
                },
                {
                    text: 'Last 31 days',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                        picker.$emit('pick', [start, end]);
                    },
                }
                ]
            },
            timeRange: [
                //new Date(2000, 10, 10, 10, 10), 
                //new Date(2000, 10, 11, 10, 10)
                //new Date(), 
                startDate,
                endDate
            ],
        }
    },
    methods: {
        resetSearchFields(){
            if(Object.keys(this.operator).length){
                this.operator = {}
                this.mccMncSelectData = []
            }

            this.filterCountry = {
                country: null,
                abbreviation: null
            }
        },
        filterCountrySelected(){
            this.mccMncSelectData = []
            if(Object.keys(this.operator).length){
                this.operator = {}
                
            }
            this.mccMncData.filter((obj)=>{    
                if(obj.iso.toUpperCase() == this.filterCountry.abbreviation){
                    this.mccMncSelectData.push(obj)
                }
                    
            })
        },
        submit(){
                       
            let StartDateFromSelect = new Date(this.timeRange[0]);
            let EndDateFromSelect = new Date(this.timeRange[1]);  
            var from  = StartDateFromSelect.toLocaleString()
            var to  = EndDateFromSelect.toLocaleString()

            this.$emit('fiterData', {
                country: this.filterCountry,
                operator: this.operator,
                token: this.token,
                timeRange: {
                    //from: new Date(this.timeRange[0]).toISOString().slice(0, 19).replace('T', ' '),
                    //to: new Date(this.timeRange[1]).toISOString().slice(0, 19).replace('T', ' ')
                    from: from,
                    to: to
                }
            })
        }
    },
    mounted() {
        this.i18n = this.$i18n;
        if (this.enableRTL) {
            this.i18n.locale = 'ar';
            this.$rtl.enableRTL();
        }
    },
    beforeDestroy() {
        if (this.$rtl.isRTL) {
            this.i18n.locale = 'en';
            this.$rtl.disableRTL();
        }
    }
}
</script>

<style>
.el-range-editor .el-range-input {
    background: none;
}
.el-range-input{
    color:white !important
}
</style>