<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-7">

          <div class="row">
            <div class="col-lg-12">
              <h3 style="margin:0"><u>Message</u></h3>
              <small>Setup the message to be sent for this campaign.</small>
            </div>
          </div>
          <br/>
          <div class="row">

            <div class="col-lg-12">

              <ValidationObserver ref="observer" v-slot="{ valid }">
                <form>
                  <div class="row">
                    <div class="col-lg-6">
                        <label style="font-size:16px">Originator</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        <base-checkbox 
                            style="display:inline-block"
                            @input="toggleRandomLongCode">
                            Random local long code &nbsp;&nbsp;
                            <el-tooltip 
                                content="The orriginator will be a random local phone number" 
                                effect="light" :open-delay="300" placement="top">
                                <i class="fa fa-question-circle text-info" aria-hidden="true"></i> 
                            </el-tooltip>
                        </base-checkbox>
                        <ValidationProvider
                          v-if="!randomLongCodeOriginator"
                          ref="originator"
                          rules="required|originator"
                          v-slot="{ passed, failed, errors }">
                            <base-input
                              v-model="originator"
                              :class="{  'has-success': passed,  'has-danger': !passed }"
                              type="text"
                              placeholder="Originator"
                            />
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">

                      <ValidationProvider
                        rules="required"
                        
                        v-slot="{ passed, failed, errors }">
                        <label style="font-size:16px">Text</label>
                        <base-textarea
                          ref="textArea"
                          :class="{  
                            'borderRadius': true,
                            'has-success': passed,  
                            'has-danger': !passed
                          }"
                          type="text"
                          label=""
                          placeholder="SMS Text"
                          v-model="text">
                        </base-textarea>

                        <span class="validation-error">{{ errors[0] }}</span>

                      </ValidationProvider>
                    </div>
                  </div>
                </form>
              </ValidationObserver>

            </div>

          </div>
          <br />
          <div class="row">
            <div class="col-lg-12">

              <br>

              <span v-if="text">
                <i v-if="!isUnicode" class="fa fa-check-circle text-success" aria-hidden="true"></i>
                <i v-else class="fa fa-times-circle text-warning" aria-hidden="true"></i>
                &nbsp; <p style="display:inline">Message is GSM-7. (No special charachters were detected)</p> <br/>
              </span>
              <span v-else>
                <i class="fa fa-minus-circle text-warning" aria-hidden="true"></i>&nbsp; <p style="display:inline">Empty Text.</p><br/>
              </span>
              

              
              <i class="fa fa-comment text-primary" aria-hidden="true"></i>
              &nbsp; <p style="display:inline">Message is {{ messageLength }} chars long.</p> <br/>

              <i class="fa fa-envelope text-primary" aria-hidden="true"></i>
              &nbsp; <p style="display:inline">Message parts {{ messageParts }}.</p> <br/><br/>

            </div>
          </div>

        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col-lg-12">
              <h4><u>Placeholders:</u></h4>
              <p>
                Use the following replaceable placeholders to customize the message, Click to add to message body.
                
              </p>
              
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <Custom 
                v-for="(item, index) in custom"  
                :value="item.value" :label="item.label" 
                :key="index" 
                @click="customized"
              /> 
            </div>
          </div>
          <br/>
          <i class="fa fa-check text-success" aria-hidden="true"></i> &nbsp;
          <p style="display:inline">These placeholders are based on your contact infromation and will be populated with values in real time.</p>
          <br/>
          <br/>
          <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> &nbsp;
          <p style="display:inline">
            Message properties may change in real time depending on custom placeholders length and type.
          </p>
        </div>
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>

import Custom from '../Message/Custom.vue'
import {Select, Option} from 'element-ui'
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
extend("min", min);


extend("required", {
  ...required,
  /*
  validate: (value) => {
    const valid = /^[a-z0-9]{1,20}$/i.test(value)
    return valid
  },
  */
  message: 'This field is required'
});


extend('originator', {
  validate: (value) => {
    const valid = /^[a-z0-9]{1,10}$/i.test(value)
    return valid
  },
  computesRequired: true,
  message: 'Upto 10 charachter, English Letters or Digits only'
});


const GSM_7BIT_CHARS = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ`¿abcdefghijklmnopqrstuvwxyzäöñüà";
const GSM_7BIT_REGEX = /^[\s@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ!"#¤%&'()*+,-./0-9:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ`¿abcdefghijklmnopqrstuvwxyzäöñüà]*$/;
const SMS_CHAR_LIMIT = 160;
const UNICODE_SMS_CHAR_LIMIT = 67;

export default {
  components:{
  },
  components:{
    [Select.name]: Select,
    [Option.name]: Option,
    Custom
  },
  watch:{
    'text': { handler: function (ov,nv){ this.parseMessage() }, deep: true }
  },
  data() {
    return {
      originator: null,
      text: null,
      randomLongCodeOriginator: false,
      messageLength: 0,
      messageParts: 0,
      isUnicode: false,
      isOriginator: true,
      hasLinkInBody: false,
      custom: [
        {
          label: 'URL',
          value: 'link'
        },
        {
          label: 'Sender',
          value: 'sender'
        },
        {
          label: 'Receiver',
          value: 'receiver'
        },
        {
          label: 'Custom 1',
          value: 'custom1'
        },
        {
          label: 'Custom 2',
          value: 'custom2'
        },
        {
          label: 'Custom 3',
          value: 'custom3'
        },
        {
          label: 'Custom 4',
          value: 'custom4'
        },
        {
          label: 'Custom 5',
          value: 'custom5'
        }
      ]
    }
  },
  methods: {
    async toggleRandomLongCode(value){
      this.randomLongCodeOriginator = value
    },
    async validate() {
      const valid = await this.$refs.observer.validate()
      if(!valid) return
      this.$emit('on-validated', true, {
        text: this.text,
        isUnicode: this.isUnicode,
        originator: this.originator,
        randomLongCodeOriginator: this.randomLongCodeOriginator
      });
      return true
    },
    calcMessageLength(text){
      this.messageLength = text.length
    },
    calcMessageParts(text){
      if(this.isUnicode){
        return this.messageParts = Math.ceil(text.length / UNICODE_SMS_CHAR_LIMIT)
      }
      return this.messageParts = Math.ceil(text.length / SMS_CHAR_LIMIT)
    },
    checkUnicode(text){
      this.isUnicode = !GSM_7BIT_REGEX.test(text)
    },
    parseMessage(){
      
      let toTestText = this.text
      this.hasLinkInBody = /\{link\}/g.test(toTestText)
      this.$emit('linkInMsg', this.hasLinkInBody)
      
      for(let item in this.custom){
        toTestText = toTestText.replace(new RegExp(`\{${this.custom[item].value}\}`, 'g'), '')
      }
      

      this.checkUnicode(toTestText)
      this.calcMessageLength(toTestText)
      this.calcMessageParts(toTestText)
    },
    customized(macro){
      const textarea = this.$refs.textArea;
      this.text = textarea.insertMacro(macro)
    },
  }
}
</script>

<style>
  .borderRadius textarea{
    border-radius: 0.4285rem;
  } 
</style>