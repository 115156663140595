<template>
  <div class="container" style="max-width: 1700px !important">
    <div class="row">
        <div class="col-lg-12" >
          <filter-area :tokenSelectData="tokensList" :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
        </div>      
    </div>
    <div >
      <div class="row">
          <div class="col-lg-12 col-md-12" >
            <summery-tiles 
            :submitInProccess="submitInProccess" 
            :FiltertilesData="tilesData" 
            @fiterData="filterOptions"/>
          </div>      
      </div>
      <div class="row">
          <div class="col-lg-12 col-md-12" >
            <top-chart 
              :submitInProccess="submitInProccess" 
              :FilterChartData="topChartData" 
              @fiterData="filterOptions"/>
          </div>      
      </div>
      <div class="row">
        <div class="col-lg-6">
          <countries-by-volume-summery 
          :FiltertilesData="countriesData" 
          :countryCodes="countryCodes" 
          :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
        </div>  
        <div class="col-lg-6">
          <operators-by-volume-summery 
          :FiltertilesData="operatorsData" 
          :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
        </div>     
      </div>
      <!--
      <div class="row">     
        <div class="col-lg-6" >
          <countries-by-spent-summery :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
        </div>   
      </div>
      -->
      <div class="row">
        <div class="col-lg-12">
          <countries-operators-by-status 
            :FiltertilesData="countryOpData"
            :countryCodes="countryCodes" 
          /> 
        </div>
      </div>
    </div>

  </div>

</template>


<script>
//https://materialdesignicons.com/
import FilterArea from './components/FilterArea.vue';
import TopChart from './components/TopChart.vue';
import SummeryTiles from './components/SummeryTiles.vue';
import CountriesByVolumeSummery from './components/CountriesByVolumeSummery.vue';
import OperatorsByVolumeSummery from './components/OperatorsByVolumeSummery.vue';
import CountriesBySpentSummery from './components/CountriesBySpentSummery.vue';
import CountriesOperatorsByStatus from './components/CountriesOperatorsByStatus.vue';
import Card from '../../../components/Cards/Card.vue';
import countryByAbr from '../../../json/iso-3166-2-country.json';

import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    FilterArea,
    TopChart,
    SummeryTiles,
    CountriesByVolumeSummery,
    OperatorsByVolumeSummery,
    CountriesBySpentSummery,
    CountriesOperatorsByStatus,
    Card
  },
  data(){
    return {
      fiterData: false,
      submitInProccess: false,
      tokensList:[],
      countriesData: {
        country: {},
        total: 0
      },
      countryOpData:[],
      operatorsData: {
        operator: {},
        total: 0
      },
      countryCodes: countryByAbr,
      tilesData:{
        Total:0,
        Delivered: 0,
        Failed: 0, 
        Other: 0, 
        Processing: 0, 
        Queued: 0 
      },
      topChartData: {
        labels: [],
        data: {
          total: [],
          Delivered: [],
          Failed: [],
          Processing: [],
          Other: []
        }
        
      }
    }
  },
  methods: {
    parseAnalyticsData(data){

      this.topChartData.labels = [];
      this.topChartData.data = {
          total: [],
          Delivered: [],
          Failed: [],
          Processing: [],
          Queued: [],
          Other: []
      };

      this.tilesData = {
        Total:0,
        Delivered: 0,
        Failed: 0, 
        Other: 0, 
        Processing: 0, 
        Queued: 0 
      }

      for(var date in data.byDate){
        
        data.byDate[date].totalByStatus.Processing = data.byDate[date].totalByStatus.Processing + data.byDate[date].totalByStatus.Queued
        
        this.topChartData.labels.push(date)
        this.topChartData.data.total.push(data.byDate[date].total) 
        this.topChartData.data.Delivered.push(data.byDate[date].totalByStatus.Delivered)
        this.topChartData.data.Failed.push(data.byDate[date].totalByStatus.Failed)
        this.topChartData.data.Processing.push(data.byDate[date].totalByStatus.Processing)
        this.topChartData.data.Queued.push(data.byDate[date].totalByStatus.Queued)
        this.topChartData.data.Other.push(data.byDate[date].totalByStatus.Other)

        this.tilesData.Delivered += data.byDate[date].totalByStatus.Delivered
        this.tilesData.Failed += data.byDate[date].totalByStatus.Failed
        this.tilesData.Processing += data.byDate[date].totalByStatus.Processing
        this.tilesData.Other += data.byDate[date].totalByStatus.Other
        this.tilesData.Queued += data.byDate[date].totalByStatus.Queued
  

      }
      
      let total = this.topChartData.data.total.reduce((t,n)=>{return t+n},0)
      this.tilesData.Total = total
      
      this.countriesData.country = {}
      let coutriesNames = data.byCountry ? Object.keys(data.byCountry) : []
      
      if(coutriesNames.length){
        let coutriesNamesSortedByVolume = coutriesNames.sort(function(a, b){
          return data.byCountry[b] - data.byCountry[a]
        })
        
        coutriesNamesSortedByVolume.splice(0,5).forEach(countryCode => {
          this.countriesData.country[countryCode] = data.byCountry[countryCode]
          
        })
      }


      //this.operatorsData.operator = data.byOperator
      this.operatorsData.operator = {}
      let opNames = data.byOperator ? Object.keys(data.byOperator) : []
      if(opNames.length){
        let opNamesSortedByVolume = opNames.sort(function(a, b){
          return data.byOperator[b] - data.byOperator[a]
        })
        
        opNamesSortedByVolume.splice(0,5).forEach(opName => {
          this.operatorsData.operator[opName] = data.byOperator[opName]
          
        })
      }

      
      //this.countryOpData = data.byCountryByOp
      this.countryOpData = []
      if(data.byCountryByOp){
        Object.keys(data.byCountryByOp).forEach(ccName=>{
          
          Object.keys(data.byCountryByOp[ccName]).forEach(opName => {
            
            this.countryOpData.push({
              country: ccName,
              operator: opName,
              total: data.byCountryByOp[ccName][opName].total,
              delivered: data.byCountryByOp[ccName][opName].totalByStatus.Delivered,
              failed: data.byCountryByOp[ccName][opName].totalByStatus.Failed,
              processing: data.byCountryByOp[ccName][opName].totalByStatus.Processing,
              queued: data.byCountryByOp[ccName][opName].totalByStatus.Queued,
              other: data.byCountryByOp[ccName][opName].totalByStatus.Other
            })
          })

        })
        this.countryOpData = this.countryOpData.sort(function(a, b){
          return b.total - a.total
        })
      }
      
      


      this.countriesData.total = this.operatorsData.total = total
      
      
    },
    filterOptions(data){
      
      this.submitInProccess = true
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/analytics/pivot', data)
      .then( (response) => {
        //console.log(response.data)
        this.parseAnalyticsData(response.data)
        this.$toast.success("Loaded");
        this.submitInProccess = false
      })
      .catch( (error) => {
        
      });
      //console.log(data)
    },
    getApiKeys(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.get(api_endpoint + '/api/get-http-api-keys')
      ])
      .then(([result1]) => {
        this.tokensList = result1.data
        this.submitInProccess = false
      })
      .catch((err)=>{
        this.$toast.error("Error server side data")
        console.log('There is an error', err);
      });
    },
    getData(data){
      
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      Promise.all([
        this.$http.post(api_endpoint + '/analytics/pivot', data),
        //this.$http.get(api_endpoint + '/api/get-http-api-keys', data)
      ])
      .then(([result1,result2]) => {
        //
        //console.log(result2.data)
        //this.tokensList = result2.data
        this.parseAnalyticsData(result1.data)
        this.submitInProccess = false
      })
      .catch((err)=>{
        this.$toast.error("Error server side data")
        console.log('There is an error', err);
      });
    }
  },
  mounted(){
    
  },
  beforeMount(){ 
    this.getApiKeys()
    /*
    var endDate = new Date();
    var startDate = new Date(endDate.getTime()-7*24*60*60*1000);
    
    this.getData({
      timeRange: {
          from: new Date(startDate).toISOString().slice(0, 19).replace('T', ' '),
          to: new Date(endDate).toISOString().slice(0, 19).replace('T', ' ')
      }
    })
    */
  },
}
</script>

<style>

</style>