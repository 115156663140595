<template>
  <ValidationObserver v-slot="{ handleSubmit, valid }">
  <form @submit.prevent="handleSubmit(submit)">
    <card class="card card-login  card-white">
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Recover</h4>
    </template>
      <div>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            required
            v-model="user_email"
            type="email"
            label="Email address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>

        <div class="category form-category">* Required fields</div>
      </div>

      <template slot="footer">
        
        <base-button :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" round block size="lg">
          Send a reset link
        </base-button>
        <div class="row">
          <div class="col-lg-12 d-flex justify-content-start">
            <a @click=back() class="nav-link text-primary" style="cursor:pointer">
              <i class="tim-icons icon-minimal-left"></i> Login with email
            </a>
          </div>
        </div>
        
      </template>
    </card>
  </form>
 </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  data() {
    return {
      user_email: "",
      message: null,
      submitInProccess : false
    };
  },
  methods: {
    recover(){
      this.$emit('recoverPassword', true);
    },
    back: function () {
        this.$emit('emailLogin', false);
    },
    submit() {
      this.submitInProccess = true
      //this.$http.post('/auth/recover', {
      const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      this.$http.post(local_auth_base_url + '/recover', {
        user_email: this.user_email
      })
      .then( (response) => {
        this.submitInProccess = false 
        this.message = response.data.message  
        this.$toast.success(this.message);
        this.$emit('emailLogin', {
          emailLogin: false,
          recoverPassword: false,
          showRecoverToken: false
        });
        
      })
      .catch((error) => {
        this.submitInProccess = false
        //this.$toast.error("error");
      });
  

    }
  }
};
</script>
<style></style>
