<template>
    <card>
        <template slot="header">
            <div class="row">
                <div class="col-lg-10">
                    <h4 class="card-title">
                        Statistics
                    </h4>
                    <h5 class="card-category">Breakdown By {{groupBy}}</h5>
                </div>
                <div class="col-lg-2">
                    <base-input label="Group By">
                        <el-select
                            
                            @input="select"
                            class="select-primary"
                            size="large"
                            placeholder="Single Select"
                            v-model="groupBy">
                                <el-option
                                    v-for="option in groupByOptions"
                                    class="select-primary"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.label">
                            </el-option>
                        </el-select>
                    </base-input>
                </div>
            </div>
            
        </template>
        <div class="row">
            <div class="col-12">
                <h4>{{rows.length}} records</h4>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <div v-if="rows.length" >
                    <div class="d-flex justify-content-center">
                        <base-pagination 
                            type="info"
                            :pageCount="Math.ceil(rows.length / 10)" 
                            :perPage="perPage"
                            :total="rows.length"
                            :value="value"
                            :pagesToDisplay="pagesToDisplay"
                            @input="pageChange">
                            
                        </base-pagination>
                    </div>
                    <base-table :data="parsedRows" thead-classes="text-primary">
                        <template slot="columns" slot-scope="{ columns }">
                            <th style="width: 80px"></th>
                            <th style="width: 120px" >{{groupBy}}</th>
                            <th style="width: 120px">Sent</th>
                            <th style="width: 120px">% Of total sent</th>
                            <th style="width: 120px">Clicks</th>
                            <th style="width: 120px">CTR %</th>
                            <th style="width: 120px">Cost</th>
                            <!--<th>Actions</th>-->
                        </template>
                        <template slot-scope="{ row, index }">
                            <td>
                                <span v-if="groupBy == 'country'">
                                    <country-flag :country="row.id" size='normal'/>
                                </span>
                            </td>
                            <td class="text-left">
                                <span v-if="groupBy == 'country'">{{countryCodes[row.id] ? countryCodes[row.id].name : row.id }}</span>
                                <span v-else>{{row.id}}</span>

                            </td>
                            <td>{{ row.sent }}</td>
                            <td>{{ 
                                    row.sent ? (( row.sent / total ) * 100).toFixed(2) : 0
                                }}%
                            </td>
                            <td>{{ row.clicks }}</td>
                            <td>
                                {{ 
                                    row.clicks && row.sent ? (( row.clicks / row.sent) * 100).toFixed(2) :  
                                    row.sent ?  0 : 100
                                }}
                            </td>
                            <td>{{ row.sell.toFixed(3) }}</td>
                            <!--
                            <td class="text-left">

                                <el-tooltip
                                content="Refresh"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                                    <base-button
                                        @click="download()"
                                        :type="index > 2 ? 'success' : 'neutral'"
                                        icon
                                        size="sm"
                                        class="btn-link">
                                        <i class="fa fa-file"></i>
                                    </base-button>
                                </el-tooltip>
                            </td>
                            -->
                        </template>
                    </base-table>
                     
                </div>  
                <h2 v-else>
                    No Data
                </h2>
            </div>
        </div>
    </card>
</template>

<script>
import { BaseTable, BasePagination } from '@/components';
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import CountryFlag from 'vue-country-flag'
export default {
    components: {
        CountryFlag,
        [Option.name]: Option,
        [Select.name]: Select,
        BaseTable,
        BasePagination
    },
    props:{
        countryCodes:{
            type: Object,
            default: () => {}
        },
        total: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        'rows': {
            handler: function(nv){
                this.parseData()
            },
            deep: true
        },
    },
    data() {
        return {
            parsedRows: [],
            value: 1,
            perPage: 10,
            pagesToDisplay: 10,
            groupBy: 'date',
            groupByOptions:[
                {
                    label: 'campaign',
                    value: 'campaign',
                },
                {
                    label: 'date',
                    value: 'date',
                },
                {
                    label: 'country',
                    value: 'country',
                }
            ],
        }
    },
    methods: {
        pageChange(page){
            this.value = page
            this.parseData()
        },
        parseData(){
            let temp = [...this.rows].sort((a,b)=>{
                
                if(this.groupBy == 'date'){
                    const start = new Date(a.id)
                    const end = new Date(b.id)
                    return end.getTime()  - start.getTime()
                }else{
                    Number(a.id) - Number(b.id) 
                }
            })
            
            this.parsedRows = temp.splice(( this.value - 1 )  * this.perPage, this.value * this.perPage)

        },
        download(){
            console.log('download')
        },
        select(){
           this.$emit('groupBy', this.groupBy)
        }
    }
}
</script>

<style>
    .select-primary.el-select .el-input input {
        color:white !important;
    }
</style>