<template>
  <div id="billing-details">
    <card>
          <h5 slot="header" class="title">Billing Details</h5>
          <ValidationObserver  v-slot="{ handleSubmit, valid }">
            
          <form @submit.prevent="handleSubmit(updateAccountBillingDetailes)">
            <div class="row">
              <div class="col-md-3">
                <ValidationProvider
                rules="required|entity_name:2"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_name, 
                    'has-danger': errors[0] || !billing.billing_entity_name 
                  }"
                  type="text"
                  label="Name"
                  placeholder="Entity name"
                  v-model="billing.billing_entity_name"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>     
              <div class="col-md-3">
                <ValidationProvider
                rules="required|regs:5"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_registration_number, 
                    'has-danger': errors[0] || !billing.billing_entity_registration_number 
                  }"
                  type="text"
                  label="Registration number"
                  placeholder="Entity registration number"
                  v-model="billing.billing_entity_registration_number"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>   
              <div class="col-md-3">
                <ValidationProvider
                rules="required|taxvat:5"
                v-slot="{ passed, failed, errors }">
                <base-input
                :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_tax_number, 
                    'has-danger': errors[0] || !billing.billing_entity_tax_number 
                  }"
                  type="text"
                  label="TAX id"
                  placeholder="Entity TAX id"
                  v-model="billing.billing_entity_tax_number"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>   
              <div class="col-md-3">
                <ValidationProvider
                rules="required|taxvat:5"
                v-slot="{ passed, failed, errors }">
                <base-input
                :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_vat_number, 
                    'has-danger': errors[0] || !billing.billing_entity_vat_number 
                  }"
                  type="text"
                  label="VAT number"
                  placeholder="Entity VAT number"
                  v-model="billing.billing_entity_vat_number"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>   
            </div>
            
            <div class="row">
              
              <div class="col-md-7">
                <ValidationProvider
                rules="required|email"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_email, 
                    'has-danger': errors[0] || !billing.billing_entity_email 
                  }"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  v-model="billing.billing_entity_email"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>   
              <div class="col-md-5">
                <ValidationProvider
                rules="required|numeric|min:6|max:12"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_phone_number, 
                    'has-danger': errors[0] || !billing.billing_entity_phone_number 
                  }"
                  type="tel"
                  label="Phone number"
                  placeholder="Phone number"
                  v-model="billing.billing_entity_phone_number"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div> 
            </div> 

            <div class="row">  
              <div class="col-md-4">
                <div class="form-group has-label">
                  <label>Country</label>
                  <div class="mb-0">
                    <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                      <base-input
                        style="display:none"
                        type="text"
                        v-model="billing_country.country">
                      </base-input>                                 
                    
                      <el-select 
                        filterable
                        v-model="billing_country" 
                        value-key="abbreviation"
                        label="Country"
                        placeholder="Country">
                        <el-option
                          v-for="item in countriesList"
                          :key="item.abbreviation"
                          :label="item.country"
                          :value="item">
                        </el-option>
                      </el-select>
                      <span v-if="!billing_country.country" class="validation-error">Please select a country</span>  
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <ValidationProvider
                rules="required|city:3"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_city, 
                    'has-danger': errors[0] || !billing.billing_entity_city 
                  }"
                  type="text"
                  label="City"
                  placeholder="City"
                  v-model="billing.billing_entity_city"
                >
                </base-input>
                  <span class="validation-error">{{ errors[0] }}</span>
                  </ValidationProvider>
              </div>  
              <div class="col-md-4">
                <ValidationProvider
                rules="required|min:3|max:10"
                v-slot="{ passed, failed, errors }">
                <base-input
                  :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_postal_code, 
                    'has-danger': errors[0] || !billing.billing_entity_postal_code 
                  }"
                  type="text"
                  label="Postal code"
                  placeholder="Postal code"
                  v-model="billing.billing_entity_postal_code"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>   
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                rules="required|addr:5"
                v-slot="{ passed, failed, errors }">
                <base-input

                :class="{ 
                    'has-success': !errors[0] && billing.billing_entity_address_one, 
                    'has-danger': errors[0] || !billing.billing_entity_address_one 
                  }"
                  type="text"
                  label="Address one"
                  placeholder="Address one"
                  v-model="billing.billing_entity_address_one"
                >
                </base-input>
                <span class="validation-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <base-input
                :class="{ 
                    'has-success': billing.billing_entity_address_two
                    //,'has-danger': !billing.billingEntityAddressTwo 
                  }"
                  type="text"
                  label="Address two"
                  placeholder="Address two"
                  v-model="billing.billing_entity_address_two"
                >
                </base-input>
              </div>
            </div>
            <div class="row" v-if="billing.billing_entity_name_eu_vat_registery_name != null">
              <div class="col-md-6">
                <base-input
                  :disabled="true"
                  :textLight="true"
                :class="{ 
                    'has-success': billing.billing_entity_name_eu_vat_registery_name, 
                    'has-danger': !billing.billing_entity_name_eu_vat_registery_name 
                  }"
                  type="text"
                  label="EU VAT billing name"
                  placeholder="EU VAT billing name"
                  v-model="billing.billing_entity_name_eu_vat_registery_name"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                  :disabled="true"
                  :textLight="true"
                :class="{ 
                    'has-success': billing.billing_entity_name_eu_vat_registery_address, 
                    'has-danger': !billing.billing_entity_name_eu_vat_registery_address 
                  }"
                  type="text"
                  label="EU VAT billing address"
                  placeholder="EU VAT billing address"
                  v-model="billing.billing_entity_name_eu_vat_registery_address"
                >
                </base-input>
              </div>
            </div>
            <base-button :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
              Save billing details
            </base-button>
          </form>   
          </ValidationObserver>
          
    </card>
  </div> 
</template>
<script>
import { Select, Option } from 'element-ui'
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
extend("email",email)

extend("taxvat",{
  ...min,
  message: 'Tax/VAT is too short'
})
extend("regs",{
  ...min,
  message: 'Registraion is too short'
})
extend("entity_name",{
  ...min,
  message: 'Name is too short'
})

extend("city",{
  ...min,
  message: 'City is too short'
})
extend("addr",{
  ...min,
  message: 'Address is too short'
})
extend("min",{
  ...min,
  message: 'Number is too short'
})
extend("max",{
  ...max,
  message: 'Number is too long'
})
extend("numeric",numeric)
extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
  components:{
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    countriesList:{
      type: Array,
      description: 'Countries JSON object'
    },
    countriesContinents:{
      type: Object,
      description: 'Countries continents JSON object'
    },
    billingObject: {}
  },
  watch: {
    billingObject: {
      handler:function(ov, nv){

      this.countriesList.filter((obj)=>{  
        if(obj.abbreviation == this.billingObject.billing_entity_country_code){
          
          this.billing_country = {
            country: obj.country,
            abbreviation: obj.abbreviation
          }
        }
      });

      this.billing = Object.keys(this.billingObject).length ?  
                      this.billingObject : this.billing;
    }
  },
  deep: true
  },
  data() {
    return {
      submitInProccess: false,
      billing_country: {
        country: null,
        abbreviation: null,
        continent:  null
      },
      billing:{
        billing_entity_currency: 'EUR',
        billing_entity_name: null,
        billing_entity_registration_number: null,
        billing_entity_vat_number: null,
        billing_entity_tax_number: null,
        billing_entity_email: null,
        billing_entity_phone_number: null,
        billing_entity_country_name: null,
        billing_entity_country_code: null,
        billing_entity_city: null,
        billing_entity_address_one: null,
        billing_entity_address_two: null,
        billing_entity_postal_code: null,
        billing_accounts_uuid: this.$store.getters.user.user_account_uuid,
        billing_entity_wave_customer_id:null
      }
    };
  },
  methods: {
    updateAccountBillingDetailes() {

      this.submitInProccess = !this.submitInProccess;
      this.billing.billing_entity_country_code = this.billing_country.abbreviation
      this.billing.billing_entity_country_name = this.billing_country.country
      this.billing.billing_entity_country_continent = this.countriesContinents[this.billing_country.abbreviation]
      this.billing.billing_entity_currency = 'EUR'

      
      //console.log(this.billing)
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      //this.$http.post(api_endpoint + '/billing/update-billing-details', this.billing)
      this.$http.post(api_endpoint + '/billing/billing/update', this.billing)
      .then( (response) => {
        //console.log(response.data)
        this.billing.billing_entity_name_eu_vat_registery_name = response.data.billing_entity_name_eu_vat_registery_name
        this.billing.billing_entity_name_eu_vat_registery_address = response.data.billing_entity_name_eu_vat_registery_address
        this.billing.billing_entity_wave_customer_id = response.data.billing_entity_wave_customer_id
        
        this.submitInProccess = !this.submitInProccess;
        if(!response.data.billing_entity_valid_eu_vat && 
             this.billing.billing_entity_country_continent == 'EU'){
              this.$toast.warning("Billing information updated, please provide a valid VAT number");
        }else{
          this.$toast.success("Billing details updated");
        }
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
      });

    }
    
  }
};
</script>
<style>
#billing-details .el-input__inner {
  color: #fafffe !important;
}
.validation-error{
  color:bisque !important;
  font-size:12px
}
</style>
