<template>
    <modal
        size="lg"
        :show.sync="show" 
        type="notice"
        @close="close"
        :id="`${type}-modal`">
        
        <h4 slot="header" class="title title-up">
            Media setup <br>
            <small >
                Link your site / tracking platform
            </small>
        </h4>
        <!--
        <ExternalModalForm :ref="`${type}-modal`" @done="done"/>
        -->
        <ExternalModalForm :ref="`${type}-modal`" v-if="type=='external'" :mediaLink="mediaLink" :done="done"/>
        <LinkAdvancedOptions :ref="`${type}-modal`" v-if="type=='advanced'" :done="done"/>

        <template slot="footer">
            <base-button 
                @click="click()"
                type="primary" 
                class="btn-fill">
                Done
            </base-button>
        </template>
      
    </modal>
</template>
<script>
import { Modal } from 'src/components';
import ExternalModalForm from './ExternalModalForm.vue'
import LinkAdvancedOptions from './LinkAdvancedOptions.vue'
export default {
    components:{
        Modal,
        ExternalModalForm,
        LinkAdvancedOptions
    },
    data() {
        return  {
            show: false
        }
    },
    props: {
        mediaLink: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    }, 
    methods: {
        toggle(){
            this.show = !this.show
        },
        click(){
            this.$refs[`${this.type}-modal`].click()
        },
        done(form){
            if(form){
                this.$emit('done', form);
                this.show = !this.show
            }
        },
        close(){

        }
    }
}
</script>