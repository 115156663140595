<template>
  <div class="row justify-content-center">  
    <div class="col-lg-12 col-md-12 col-sm-12" style="max-width:1700px">
        <div class="row">
            <div class="col-lg-9">
                <Tiles :user="user"/>
            </div>

    
            <div class="col-lg-3 hide-on-mobile" >
                <h4>First steps</h4>
                <TimeLineDash 
                    :user="user" 
                    :tokens="tokens"
                    :activity="activity"
                />
            </div>
        </div>  
    </div>
  </div>
</template>

<script>
import TimeLineDash from './Developer/TimeLine.vue';
import Tiles from './Developer/Tiles.vue'
export default {
    components: {
        TimeLineDash,
        Tiles
    },
    props: ['user'],
    data() {
        return {
            hoverOverTokens: false,
            hoverOverTeams:false,
            timeOfDay: null,
            userHasApiToken: false,
            userSentSms: false,
            timeLineItems: [],
            tokens: false,
            activity: false,
        }
    },
    computed: {
    },
    created () {},
    mounted(){ 
        this.keys()
        this.hasActivity()
    },
    methods: {
        async keys(){
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            //const res = await this.$http.get(api_endpoint + '/api/get-http-api-keys')
            const res = await this.$http.get(api_endpoint + '/api/count-keys')
            if(res && res.status == 200){
                //this.tokens = res.data && !!res.data.length
                this.tokens = res.data.count
            }
        },
        async hasActivity(){
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            const res = await this.$http.get(api_endpoint + '/analytics/check-sms-sent')
            if(res && res.status == 200){
                this.activity = res.data && !!res.data.totalRecords
            }
        },

        getTimeLineItems(){
            this.timeLineItems = []
            let array = ['account','apiKeys','smsSent']
            if(this.userSentSms){
                array.push('analytics')
            }
            let obj = {}
            let me = this
            array.forEach( (item, index) => {
                obj = {}
                if(item == 'account'){
                    obj.state = 'complete'
                    obj.badge = {
                        type: 'success',
                        iconSize: 32
                    }
                    obj.header = `Account created`
                    obj.content = ` Congratulations!<br> You have created an account. <br>`
                    obj.footer = me.userHasApiToken ? `Account id: &nbsp;${me.user.user_account_uuid}`: `next, create an API token and send SMS.`

                }else if(item == 'apiKeys'){
                    obj.state = me.userHasApiToken ? 'complete' : null,
                    obj.badge = {
                        type: me.userHasApiToken ? 'success' : 'danger',
                        iconSize: 32
                    }
                    obj.header = `API access tokens`
                    if(me.userHasApiToken){
                        obj.content = `Great Job!<br> You are ready to send SMS.<br>`
                    }else{
                        obj.content = `Create an API token`
                        obj.button = {
                            to: 'settings/api-access-tokens',
                            icon: 'key-plus',
                            text : 'Create an API token'
                        }
                    }
                    

                    obj.footer = me.userHasApiToken ? `You have created an api token`: `You have yet to create an API token.`
                }else if(item == 'smsSent'){
                    obj.state = me.userSentSms ? 'complete' : null,
                    obj.badge = {
                        type: me.userSentSms ? 'success' : 'danger',
                        iconSize: 32
                    }
                    obj.header = `Send SMS with API`
                    if(me.userSentSms){
                        obj.content = `You have succesuflly sent an SMS using an access token.<br>`
                    }else{
                        obj.content = `Use your API tokens to send SMS.`
                    }
                    obj.footer = me.userSentSms ? `you have sent an sms`: `You have yet to send an SMS.`
                }else if(me.userHasApiToken && me.userSentSms){
                    obj.state = 'complete',
                    obj.badge = {
                        type: 'info',
                        iconSize: 32
                    }
                    /*
                    obj.button = {
                        to: 'sms/analytics',
                        icon: 'chart-donut-variant',
                        text : 'Analytics'
                    }
                    */
                    obj.header = `Whats next?`
                    obj.content = `<a href="billing/add-funds">Add some credit</a> or Check out SMS <a href="sms/analytics">analytics</a> or <a href="billing/usage">usage</a>.`
                    obj.footer = ``
                }
                if(Object.keys(obj).length){
                    me.timeLineItems.push(obj)

                }
                
                
            });

            console.log(me.timeLineItems)
            
            
        },
    },
    
}
</script>

<style>    
    

    @media only screen and (max-width: 600px) {
        .mobile-space {margin-top:80px !important;}
        .hide-on-mobile { display: none;}
    }

    
</style>