<template>
  <ValidationObserver  ref="observer" v-slot="{ handleSubmit, valid }">
    <form ref="form" @submit.prevent="handleSubmit(submit)">
      
      <div class="row">
        <div class="col-lg-12">
          <ValidationProvider
            rules="required"
            v-slot="{ passed, failed, errors }">
              <base-input label="Specify Timezone">
                <el-select
                    class="select-primary"
                    size="large"
                    placeholder="Timezone Select"
                    v-model="timeZone"
                  >
                    <el-option
                      v-for="option in timeZones"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    >
                    </el-option>
                </el-select>
              </base-input>
              <span class="local-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
      </div>
      
      <Crons ref="crons" :crons="crons" @done="cronsForm"/>
      <!--
      <ValidationProvider
        v-slot="{ errors }"
        name="customElement"
        rules="crons">
          <div class="row" v-for="(item, index) in crons" :key="index">
            <div class="col-lg-12">
            </div>
          </div>
          <span class="local-danger">{{ errors[0] }}</span>
      </ValidationProvider>
     -->
      <button type="submit" style="display:none" ref="myButton" @click="submit(valid)">Click me</button>

    </form>
  </ValidationObserver>
</template>

<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import CronLikeRow from './CronLikeRow.vue'
import Crons from './Crons.vue'
const crons = require('../json/crons.json')
const timeZones = require('../json/timeZones.json')

extend("crons", {
  ...required,
  message: 'Please select the desired time to run the campaign.'
});


export default {
  components:{
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    CronLikeRow,
    Crons
  },
  data(){
    return {
      crons: crons,
      timeZone: '',
      cronRows: null,
      timeZones: timeZones
    }
  },
  methods: {
    async click(){
      //this.$refs['myButton'].click()
      const valid = await this.$refs.observer.validate();
      if(valid){
        const cronsValid = await this.$refs['crons'].submit()
      }
      
      
    },
    cronsForm(data){
      this.$emit('recurring', { crons: data, timeZone: this.timeZone } )
    },
    async submit(valid){
      const cronsValid = await this.$refs['crons'].submit()
      if(!valid) return 
      const conf = {
      }
      this.$emit('schedule', conf)

    },
    async close(){
      const closed = await this.$refs['crons'].close()
      
    }
  }
}
</script>

<style>
.local-danger {
  display: block;
}
</style>

