var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card card-register card-white"},[_c('template',{slot:"header"},[_c('img',{staticClass:"card-img",attrs:{"src":"/img/card-primary.png","alt":"Card image"}}),_c('h4',{staticClass:"card-title"},[_vm._v("Email")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","label":"Email address","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"password","label":"Password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","vid":"confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"password","label":"Confirm Password","error":errors[0]},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)}),_c('div',{staticClass:"category form-category"},[_vm._v("* Required fields")])],1),_c('base-checkbox',{staticClass:"text-left"},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"#something"}},[_vm._v("terms and conditions")]),_vm._v(". ")]),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"native-type":"submit","type":"primary","round":"","block":"","size":"lg"}},[_vm._v(" Signup ")]),_c('a',{staticClass:"nav-link text-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.Back()}}},[_c('i',{staticClass:"tim-icons icon-minimal-left"}),_vm._v(" Signup options ")])],1)],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }