<template>
    <div class="container" style="max-width: 1700px !important">
        <div class="row">
            <div class="col-lg-12">
                <card>
                    <template slot="header">
                        <h4 class="card-title">Delivery verification made simple.</h4>
                        <h5 class="card-category">Test your messages to verify delivery</h5>
                    </template>
                    <p>
                        Delify is a "Delivery verification" tool that was built in order to assist you with SMS delivery. <br>
                        We try our best to make sure that each SMS is delivered with as little effort as possible, 
                        Sometimes though, some part of your SMS content like its sender id (Originator) or text are not allowed on a given mobile operators' network. <br>
                        Use Delify to test your sender IDs and message content if you are sending messages for the first time or are not sure if they are "Spamish" or not. <br><br>
                        
                    </p>
                    <span class="text-primary">
                        <b><u>If your message was not delivery verified, contact our support team, we will get you up and running in no time.</u></b>
                        </span>
                        <br>
                </card>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <networks 
                    :dataArray="networksDisplayArray" 
                    :totalRecords="totalNetworkRecords"
                    :page="networksPage"
                    :perPage="networksPerPage"
                    :pageCount="networksPageCount"
                    :submitInProccess="submitInProccess"
                    @pagination="networksPagination"
                    @perPerChange="networksPerPerChange"
                    @operatorAdded="networksOperatorAdded"
                    @searchData="networksSearchData">
                </networks>
                
            </div>
            <div class="col-lg-4">
                <test-parameters
                :operators="networksToTest"
                :tokenSelectData="tokensList"
                :submitInProccess="submitInProccess"
                @senderIds="updateSenderIdsFromUser"
                @testTextAdded="updatedTestText"
                @messageCount="updateMessageCount"
                @textIsGsm="updateTextIsGsm"
                @selectToken="updateSelectToken">
                </test-parameters>
            </div>
            <div class="col-lg-4">
                <summery
                    :operators="networksToTest"
                    :senderIds="senderIdsFromUser"
                    :text="testText"
                    @confirmTest="confirmTest"
                    :messageCount="messageCount"
                    :testText="testText"
                    :textIsGsm="textIsGsm"
                    :selectedToken="selectedToken"
                    :submitInProccess="submitInProccess"
                    @operatorRemove="networksOperatorRemove">
                    </summery>
                
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <results-table
                :submitInProccess="submitInProccess"
                :tableData="resultsTableDataDisplay"
                :startDate="startDate"
                :endDate="endDate"
                @pickedDate="pickDates"
                @filterResultsTable="filterResultsTable"
                @refreshResult="refreshResult">
                </results-table>
            </div>
        </div>
       
    </div>
</template>

<script>
import Networks from './Networks.vue';
import TestParameters from './TestParameters.vue'
import Summery from './Summery.vue'
import ResultsTable from './ResultsTable.vue'
const api_endpoint = process.env.VUE_APP_API_ENDPOINT

//var endDate = new Date();
//var startDate = new Date(endDate.getTime()-7*24*60*60*1000);
const startDate = new Date();
startDate.setHours(0, 0, 0, 0);

const endDate =  new Date();
endDate.setHours(23, 59, 59, 999);

export default {
    components: {
        Networks,
        TestParameters,
        Summery,
        ResultsTable
    },
    data(){
        return {
            submitInProccess: false,
            //startDate: new Date( ( new Date() ).getTime()-1*24*60*60*1000 ),
            //endDate:  new Date(),
            startDate: startDate,
            endDate:  endDate,
            tsmsAccessToken: null,
            resultsTableData: [],
            resultsTableDataDisplay: [],
            networksArray: [],
            networksDisplayArray: [],
            totalNetworkRecords: 0,
            networksPage: 1,
            networksPerPage: 5,
            networksPageCount: 0,
            networksFrom: 0,
            networksTo: 5,
            networksToTest: [],
            senderIdsFromUser: [],
            testText: null,
            messageCount: 0,
            textIsGsm: true,
            networksQuery: null,
            tokensList: [],
            selectedToken: null,
            tsmsTokenCreated: null,
            tsmsTokenExpires: null
        }
    },
    mounted() {
        this.loadData()
        this.getApiKeys()
        this.loadDelifyHistory()
    },
    methods:{
        updateResult(index, data){
            //console.log(data)

            this.resultsTableDataDisplay[index]['recently_added'] = false
            this.resultsTableDataDisplay[index]['test_sms_mt_last_status'] = data['test_sms_mt_last_status'] ? data['test_sms_mt_last_status'] : 'WAIT'
            this.resultsTableDataDisplay[index]['test_sms_receiver'] =  data['test_sms_receiver'] ? data['test_sms_receiver'] : null
            this.resultsTableDataDisplay[index]['test_sms_test_id'] =  data['test_sms_test_id'] ? data['test_sms_test_id'] : null
            this.resultsTableDataDisplay[index]['test_sms_mt_created_ts'] =  data['test_sms_mt_created_ts'] ? data['test_sms_mt_created_ts'] : null
            this.resultsTableDataDisplay[index]['test_sms_sender_received'] =  data['test_sms_sender_received'] ? data['test_sms_sender_received'] : null
            this.resultsTableDataDisplay[index]['test_sms_mt_text_received'] =  data['test_sms_mt_text_received'] ? data['test_sms_mt_text_received'] : null
           
            /*
            if(this.resultsTableDataDisplay[index]['test_sms_sender_received'] != this.resultsTableDataDisplay[index]['test_sms_sender']){
                this.resultsTableDataDisplay[index]['test_sms_sender_change'] = true
            }
            */

            this.$set(this.resultsTableDataDisplay[index],'loading',false)
             
            
            
        },
        refreshResult(rowData){
            
            let data = rowData.row
            let index = this.resultsTableDataDisplay.findIndex((element) => element.test_sms_our_msg_id === data.test_sms_our_msg_id)

            this.$set(this.resultsTableDataDisplay[index],'loading',true)
            this.$http.post(api_endpoint + '/delify/get-status',{
                data: rowData.row,
                token: this.tsmsAccessToken,
            })
            .then( (res) => {
                this.updateResult(index, res.data)    
            })
            .catch( (error) => {
              
            });
            
            
        },
        filterResultsTable(filterData){
         
            this.resultsTableDataDisplay = 
            this.resultsTableData.filter(data => 
            !filterData.filterValue 
            || 
            data[filterData.filterBy].toLowerCase().includes(filterData.filterValue.toLowerCase())
            )
        },
        updateSelectToken(selectedToken){
            this.selectedToken = selectedToken
        },
        pickDates(ranges){
            

            const startDate = new Date(ranges[0]);
            startDate.setHours(0, 0, 0, 0);

            const endDate =  new Date(ranges[1]);
            endDate.setHours(23, 59, 59, 999);
            
            this.startDate = startDate
            this.endDate = endDate
            
            this.loadDelifyHistory()
            
        },
        networkFilterData(){
            this.networksDisplayArray = this.networksArray.filter(
            data => !this.networksQuery 
                || data['mccmnc'].toLowerCase().includes(this.networksQuery.toLowerCase()) 
                || data['country'].toLowerCase().includes(this.networksQuery.toLowerCase()) 
                || data['isoAlpha2'].toLowerCase().includes(this.networksQuery.toLowerCase()) 
                || data['network'] && data['network'].toLowerCase().includes(this.networksQuery.toLowerCase())        
            )
        },
        networksSearchData(query){
            
            this.networksQuery = query
            this.networksPagination(1)
        },
        async serverSidePolling(time, item){
            let index = this.resultsTableDataDisplay.findIndex((element) => element.test_sms_our_msg_id === item.test_sms_our_msg_id)
            //console.log(index)
            //this.submitInProccess = !this.submitInProccess
            let res = await this.$http.post(api_endpoint + '/delify/get-status',{
                data: item,
                token: this.tsmsAccessToken,
            })
            //this.submitInProccess = !this.submitInProccess            
            
            this.updateResult(index, res.data)

            //if(time <= 3 && ( !res.data['receiptStatus'] || res.data['receiptStatus'] == 'WAIT')){
            //if(time <= 1 && ( !res.data['receiptStatus'] || res.data['receiptStatus'] == 'WAIT')){
            if(time <= 3 && ( !res.data['test_sms_mt_last_status'] || res.data['test_sms_mt_last_status'] == 'WAIT')){
                setTimeout(() => { this.serverSidePolling( ++time , item) }, 5000)
                //this.updateResult(index, res.data)
            }else{
                //this.updateResult(item, res.data)
                //this.updateResult(index, res.data)
                
            }
            
        },
        async confirmTest(value){
            
            let currentTimeSecs = (new Date()).getTime() / 1000
            let createdTimeSecs = this.tsmsTokenCreated / 1000
            if( (  currentTimeSecs - createdTimeSecs ) > this.tsmsTokenExpires ){
                this.$toast.error('Access token expired, please refresh the page')
                return
            }
            
            
            //if(false){
            if(value){

                let networks = []
                this.networksToTest.forEach(network=>{
                    this.senderIdsFromUser.forEach(sender=>{
                   
                        networks.push({
                            "mccmnc": network.mccmnc,
                            "mccmncOriginal": network.mccmncOriginal ? network.mccmncOriginal : '',
                            //"externalMsisdn":"3936622667777",
                            //"messageIdTemplate": `[code]`,
                            "numberSources":"dedicated_then_shared",
                            "isoAlpha2": network.isoAlpha2,
                            "sender" : sender,
                            "country": network.country,
                            "network": network.network
                        })
                        
                    })
                })
                
                //console.log(networks)
                
                this.submitInProccess = !this.submitInProccess
                const res = await this.$http.post(api_endpoint + '/delify/create',{
                    token: this.tsmsAccessToken,
                    accountingToken: this.selectedToken,
                    networks: networks,
                    text: encodeURI(this.testText),
                    messageCount: this.messageCount,
                    textIsGsm: this.textIsGsm,
                    //account: this.$store.getters.account
                })
                if(res && res.status == 200){
                    if(res.data)
                        setTimeout(() => { 
                            this.submitInProccess = !this.submitInProccess
                            this.loadDelifyHistory() 
                        }, 2000)
                        
                    /*
                    res.data.forEach(item=>{
                        this.resultsTableDataDisplay.unshift(item)
                        setTimeout(() => { this.serverSidePolling(1, item) }, 5000)
                        
                    })
                    */
                }
               

                
                
            }
        },
        updateTextIsGsm(value){
            this.textIsGsm = value
        },
        updateMessageCount(count){
            this.messageCount = count
        },
        updatedTestText(text){
            this.testText = text
        },
        updateSenderIdsFromUser(array){
            this.senderIdsFromUser = array
        },
        networksOperatorRemove(rowData){

            let foundIndex = this.networksToTest.findIndex((element) => JSON.stringify(element.mccmnc) === JSON.stringify(rowData.mccmnc))
            delete this.networksToTest.splice(foundIndex, 1)

            let foundParentIndex = this.networksArray.findIndex((element) => JSON.stringify(element) === JSON.stringify(rowData))
            
            this.networksArray[foundParentIndex]['checked'] =!this.networksArray[foundParentIndex]['checked']
            
            this.networksPagination(this.networksPage) 
        },
        networksOperatorAdded(rowData){
            
            
            let operator = rowData.row
            let foundIndex = this.networksToTest.findIndex((element) => JSON.stringify(element) === JSON.stringify(operator))
            let foundParentIndex = this.networksArray.findIndex((element) => JSON.stringify(element) === JSON.stringify(operator))

            this.networksArray[foundParentIndex]['checked'] =!this.networksArray[foundParentIndex]['checked']

            if(foundIndex > -1) {
                delete this.networksToTest.splice(foundIndex, 1)
            }else{
                this.networksToTest.push(operator)    
            }   

            this.networksPagination(this.networksPage) 
            
            
        },
        networksOperatorClearAll(){
            this.networksToTest = []
        },
        networksPerPerChange(perPageFromEmit){
            this.networksPerPage = perPageFromEmit
            //this.parseNetworksData()
            this.networksPagination()
        },
        networksPagination(page){
                       
            if(this.networksQuery){
                

                this.networkFilterData()
                this.totalNetworkRecords = this.networksDisplayArray.length || 0
                this.networksPage = page || 1
                this.networksTo = this.networksPage * this.networksPerPage
                this.networksFrom = (this.networksPage - 1 ) * this.networksPerPage
                
                this.networksDisplayArray = this.networksDisplayArray.slice(this.networksFrom, this.networksTo);
            }else{
                this.networksPage = page ? page : this.networksPage
                this.networksTo = this.networksPage * this.networksPerPage
                this.networksFrom = (this.networksPage - 1 ) * this.networksPerPage
                this.parseNetworksData()
            }
            

        },
        parseNetworksData(){
            if(this.networksQuery){
                /*
                console.log(this.networksFrom, this.networksTo)
                console.log(this.networksDisplayArray)
                */
                this.totalNetworkRecords = this.networksDisplayArray ? this.networksDisplayArray.length : 0
                this.networksDisplayArray = this.networksDisplayArray.slice(this.networksFrom, this.networksTo);
            }else{
                this.totalNetworkRecords = this.networksArray ? this.networksArray.length : 0
                this.networksDisplayArray = this.networksArray.slice(this.networksFrom, this.networksTo);
            }
        },
        loadDelifyHistory(){
            
            this.submitInProccess = !this.submitInProccess      
            this.$http.post(api_endpoint + '/delify/history',{
                startDate: this.startDate,
                endDate: this.endDate
            })
            .then( (res) => {
                this.submitInProccess = !this.submitInProccess
                //console.log(res.data)
                this.resultsTableData = res.data
                this.resultsTableDataDisplay =   this.resultsTableData
            })
            .catch( (error) => {
                this.submitInProccess = !this.submitInProccess
            });
        },
        loadData(){
            this.submitInProccess = !this.submitInProccess
      
      
            this.$http.get(api_endpoint + '/delify/networks/read')
            .then( (res) => {

                this.submitInProccess = !this.submitInProccess
                this.networksArray = res.data.networks
                this.tsmsAccessToken = res.data.token
                this.tsmsTokenCreated = res.data.tokenCreated
                this.tsmsTokenExpires = res.data.tokenExpire
                /*
                this.totalNetworkRecords = res.data ? res.data.length : this.totalNetworkRecords
                this.networksPageCount =  res.data ? ( res.data.length / this.networksPerPage ) : this.networksPageCount
                */
               this.parseNetworksData()
            })
            .catch( (error) => {
                this.submitInProccess = !this.submitInProccess
            });
        },
        getApiKeys(){
            this.submitInProccess = !this.submitInProccess
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            Promise.all([
                this.$http.get(api_endpoint + '/api/get-http-api-keys')
            ])
            .then(([result1]) => {
                this.submitInProccess = !this.submitInProccess
                this.tokensList = result1.data
                
            })
            .catch((err)=>{
                this.$toast.error("Error server side data")
                console.log('There is an error', err);
            });
        },
    }
}
</script>

<style>

</style>
