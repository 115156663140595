<template>
     <card type="chart" style="padding:10px">
        <!--
        <TableFilter @search="search" />
        -->
        <template slot="header">
            <h3 class="card-title">Your Campaigns</h3>
            <p class="card-category">Click on "Show canceled" / "Show expired" campaigns to view canceled or expired campaigns</p>
        </template>
        <hr>

        <div class="d-flex justify-content-center">
            <div class="row">
                <div v-if="true" class="col-lg-12">
                    
                    <div class="row">
                        <div class="col-lg-6">
                            <!--
                            <base-input 
                            v-model="searchTerm"
                            placeholder="Search Table"
                            @input="search"></base-input>
                            -->
                            <div class="row">
                                <div class="col-lg-2">
                                    <h4>Rows: {{total}}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <base-checkbox 
                                        style="display:inline-block"
                                        @input="toggleCanceled">
                                        Show canceled campaigns
                                    </base-checkbox>
                                    &nbsp;
                                    <el-tooltip 
                                        content="Campaigns that have been canceled" 
                                        effect="light" :open-delay="300" placement="top">
                                        <i class="fa fa-question-circle text-info" aria-hidden="true"></i> 
                                    </el-tooltip>
                                </div>        
                                <div class="col-lg-4">
                                    <base-checkbox 
                                        style="display:inline-block"
                                        @input="toggleExpired">
                                        Show Expired campaigns
                                    </base-checkbox>
                                    &nbsp;
                                    <el-tooltip 
                                        content="Campaigns that were scheduled by date and have completed" 
                                        effect="light" :open-delay="300" placement="top">
                                        <i class="fa fa-question-circle text-info" aria-hidden="true"></i> 
                                    </el-tooltip>
                                </div>        
                                <div class="col-lg-2">
                                    
                                </div>
                            </div>
                            
                            
                            
                        </div>
                    </div>
                    
                    <el-table
                    @sort-change="onSortChange"
                    @filter-change="onFilterChange"
                    :data="paginatedData"
                    style="width: 100%;margin-bottom: 20px;min-height:600px">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <MoreDetails :dataObject="scope.row"/>
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            :width="70"
                            prop="campaign_id"
                            label="Id">
                        </el-table-column>
                         <el-table-column
                            :width="120"
                            prop="campaign_name"
                            label="Name">
                            <template slot-scope="scope">
                                <span v-if="scope.row.campaign_name">
                                    {{scope.row.campaign_name}}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            :filters="[
                                { text: 'scheduled', value: 'schedule' },
                                { text: 'repeat', value: 'repeat' }
                            ]"
                            column-key="campaign_type"
                            prop="campaign_type"
                            label="Type"
                            width="150">            
                            <template slot-scope="scope">
                                <div v-if="scope.row.campaign_type == 'schedule'">
                                    <el-tooltip
                                        content="This campaign will run on a specific date"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                        <i class="fas fa-calendar-alt text-info"></i>  
                                    </el-tooltip>
                                    &nbsp; By Date
                                </div>
                                <div v-else>
                                    <el-tooltip
                                        content="This campaign is recurring"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                        <i class="fa fa-redo text-info"></i>
                                    </el-tooltip>
                                    &nbsp; Reccuring
                                </div>
                                
                                
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            :width="70"
                            prop="campaign_last_status"
                            label="">
                            <template slot-scope="scope">
                                <el-tooltip
                                        v-if="scope.row.campaign_last_status == 0"
                                        content="This campaign is canceled"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                            <i class="fa fa-times-circle text-danger"></i> 
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else-if="scope.row.campaign_last_status == 1"
                                        content="This campaign is set to run"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                        <i class="fa fa-check-circle text-primary"></i> 
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else-if="scope.row.campaign_last_status == 2"
                                        content="This campaign is running"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                            <i class="fa fa-play-circle text-success"></i> 
                                    </el-tooltip>
                                    <el-tooltip
                                        v-else-if="scope.row.campaign_last_status == 9"
                                        content="An error occurred while running the campaign, please contact support"
                                        effect="light"
                                        :open-delay="300"
                                        placement="top">
                                            <i class="fa fa-exclamation-circle text-danger"></i> 
                                    </el-tooltip>
                                    <!--// status 3 is Expierd -->
                                    <el-tooltip
                                    v-else
                                    content="This campaign has expired"
                                    effect="light"
                                    :open-delay="300"
                                    placement="top">
                                        <i class="fa fa-clock text-warning"></i> 
                                    </el-tooltip>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            column-key="campaign_date"
                            prop="campaign_date"
                            label="scheduled"
                            :filters="scheduledFilters"
                            width="140">
                            <template slot-scope="scope">
                                <span v-if="scope.row.campaign_type == 'schedule'">
                                    {{ 
                                        parseDateShort(scope.row.campaign_date)
                                    }}
                                </span>
                                <span v-else>
                                    {{ scope.row.campaign_recurring_type }}ly
                                </span>
                                
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            prop="campaign_date"
                            label="Day"
                            width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.campaign_type == 'schedule'">
                                        {{ parseDateShort(scope.row.campaign_date, 'day') 
                                        }}
                                    </span>
                                    <span v-else>
                                        <span v-if="scope.row.campaign_day_of_week">
                                            {{ parseReccuringDay(scope.row) }}
                                        </span>
                                        <span v-else-if="scope.row.campaign_day_of_month">
                                            {{ scope.row.campaign_day_of_month }}
                                        </span>
                                        <span v-else>
                                            -every-
                                        </span>
                                        
                                    </span>
                                </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            prop="campaign_date"
                            label="Month"
                            width="100">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.campaign_type == 'schedule'">
                                        {{ 
                                            parseDateShort(scope.row.campaign_date, 'month') 
                                            }}
                                    </span>
                                    <span v-else>
                                        <span v-if="scope.row.campaign_recurring_type =='month'">
                                            <span v-if="scope.row.campaign_month">
                                                {{ parseReccuringMonth(scope.row.campaign_month) }}
                                            </span>
                                            <span v-else>
                                                -every-
                                            </span>
                                            
                                        </span>
                                        <span v-else>
                                            -every-
                                        </span>
                                    </span>
                                </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            prop="campaign_tz"
                            column-key="campaign_tz"
                            label="Time Zone"
                            :filters="timzeZones"
                            :width="100">
                            <template slot-scope="scope">
                                <i class="fa fa-globe" aria-hidden="true"></i> &nbsp; {{scope.row.campaign_tz}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="campaign_hr"
                            label="Hour"
                            :width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.campaign_hr">
                                    {{scope.row.campaign_hr && scope.row.campaign_hr.toString().padStart(2,0)}}
                                </span>
                                <span v-else>
                                    -every-
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="campaign_mn"
                            label="Minute"
                            :width="70">
                            <template slot-scope="scope">
                                 {{scope.row.campaign_mn && scope.row.campaign_mn.toString().padStart(2,0)}}
                            </template>
                        </el-table-column>                       
                        <el-table-column
                            :width="100"
                            prop="campaign_to_send"
                            label="To Send">
                        </el-table-column>
                        <!--
                        <el-table-column
                            :width="150"
                            prop="campaign_total_contacts"
                            label="Total Contacts">
                        </el-table-column>
                        -->
                        <el-table-column
                            :width="100"
                            prop="campaign_total_sent"
                            label="Sent">
                            <template slot-scope="scope">
                                {{scope.row.campaign_total_sent || 0}}
                            </template>
                        </el-table-column>
                        <!--
                        <el-table-column
                            :width="250"
                            prop="filters"
                            label="Contact filters">
                            <template slot-scope="scope">
                                <span v-if="scope.row.filters && scope.row.filters.length">
                                    <el-tag
                                        v-for="(item, index) in scope.row.filters" :key="index"
                                        :closable="false"
                                        :close-transition="false"
                                        class="filter-tags">
                                        {{item.filter_key}} {{item.filter_action}} {{item.filter_value}}
                                    </el-tag>
                                </span>
                                <div v-else>
                                    <p>No contact filters</p>
                                </div>
                            </template>
                        </el-table-column>
                        -->
                        <el-table-column
                            sortable
                            prop="createdAt"
                            label="Created"
                            :width="90">
                            <template slot-scope="scope">
                                {{
                                    parseDateShort(scope.row.createdAt)
                                    }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            prop="updatedAt"
                            label="Updated"
                            :width="90">
                            <template slot-scope="scope">
                                {{
                                    parseDateShort(scope.row.updatedAt)
                                    }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="Options"
                            width="170">
                            <template #header>
                                Options
                            </template>
                            <template slot-scope="scope">
                                <div class="btn-group">
                                    <base-button 
                                        icon
                                        size="sm"
                                        class="btn-link"
                                        :loading="loading"
                                        @click="scope.row.campaign_last_status = 0; action('stop',scope.row)" 
                                        simple type="danger">
                                        <i v-if="!loading" class="fa fa-stop"></i>
                                    </base-button>
                                    <base-button 
                                        icon
                                        size="sm"
                                        class="btn-link"
                                        :loading="loading"
                                        @click="scope.row.campaign_last_status = 1; action('start',scope.row)" 
                                        simple type="success">
                                        <i v-if="!loading" class="fa fa-play"></i>
                                    </base-button>
                                   
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="d-flex justify-content-center">
                        <base-pagination 
                            type="info"
                            :pageCount="pageCount" 
                            :perPage="perPage"
                            :total="total"
                            :value="value"
                            :pagesToDisplay="pagesToDisplay"
                            @input="pageChange">
                            
                        </base-pagination>
                    </div>    
                </div>
                <div class="d-flex justify-content-center" style="height:250px" v-else>
                    <div style="font-size: 36px; color:white; padding-top:150px;">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import {Table, TableColumn, Button, Pagination, Select, Option, Tag} from 'element-ui'
import { BasePagination } from 'src/components'
import MoreDetails from './MoreDetails.vue'
import TableFilter from './TableFilter.vue'
const numericDayToName = require('../../New/json/numericDayToName.json')
const numericMonthToName = require('../../New/json/numericMonthToName.json')

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Pagination.name]: Pagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Tag.name]: Tag,
        TableFilter,
        BasePagination,
        MoreDetails
    },
    props:{
        tblData: {
            type: Array,
            default: () => { return [] }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        
        'tblData': {
            handler: function(nv){
                this.parseData()
            },
            deep: true
        },
    },
    data() {
        return {
            searchTerm: null,
            paginatedData: [],
            numericMonthToName: numericMonthToName,
            numericDayToName: numericDayToName,
            pageCount: 0,
            perPage: 5,
            total: 0,
            value: 1,
            pagesToDisplay: 5,
            scheduledFilters: [],
            columnFiltered: {},
            showCanceledCampaigns: false,
            showExpiredCampaigns: false,
            timzeZones: [
                {
                    value: 'CET',
                    text: 'Central European Time'
                },
                {
                    value: 'EET',
                    text: 'Eastern European Time'
                },
                {
                    value: 'WET',
                    text: 'Western European Time'
                },
                {
                    value: 'JST',
                    text: 'Japan Standard Time'
                },
                {
                    value: 'CST',
                    text: 'China Standard Time'
                },
                {
                    value: 'EST',
                    text: 'Eastern Standard Time (US)'
                },
                {
                    value: 'PST',
                    text: 'Pacific Standard Time (US)'
                },
                {
                    value: 'GMT',
                    text: 'GMT'
                },
                {
                    value: 'UTC',
                    text: 'UTC'
                }
            ]
        }
    },
    filters: {
        padStart: (number) => {
            return number.toString().padStart(2, 0);
        }
    }, 
    methods: {
        parseData(sortProp = null, sortOrder = null){

            
            const filtered = this.filterData()            
            let temp = filtered ? filtered : this.tblData
            
            temp = temp.filter(row =>
                this.showCanceledCampaigns && row.campaign_last_status == 0 || 
                this.showExpiredCampaigns && row.campaign_last_status == 3 || 
                ( row.campaign_last_status != 0 && row.campaign_last_status != 3 ) )
            

            this.total = temp.length
            this.pageCount = Math.ceil(this.total / this.perPage)

            this.paginatedData = [...temp].sort((a,b)=>{

                if(sortProp) return sortOrder == "descending" ? b[sortProp] - a[sortProp] : a[sortProp] - b[sortProp]

                if(Number(a.campaign_last_status) != Number(b.campaign_last_status)){
                    return Number(a.campaign_last_status) - Number(b.campaign_last_status) 
                }

                return Number(b.campaign_id) - Number(a.campaign_id) 

            }).slice( ( this.value - 1 )  * this.perPage, this.value * this.perPage)
            
            this.scheduledFiltersMap(temp)


        },
        pageChange(page){
            this.value = page
            this.parseData()
        },
        toggleExpired(value){
            this.showExpiredCampaigns = value
            this.parseData()
        },
        toggleCanceled(value){
            this.showCanceledCampaigns = value
            this.parseData()
        },
        onSortChange(sort){
            this.parseData(sort.prop, sort.order)
        },
        filterData(){
            const filterKeys = Object.keys( this.columnFiltered ).filter( key => this.columnFiltered[key] )
            if(!filterKeys.length) return

            let data = [...this.tblData]
            for( const i in filterKeys ) {
                let key = filterKeys[i]
                const values =  this.columnFiltered[key]//.map(item => item.toLowerCase()).join(' ')

                console.log(values)
                data = data.filter(row => {
                    let realKey = key
                    // Make this better
                    if(row.campaign_type == 'repeat'){
                        if(key == 'campaign_date'){
                            realKey = 'campaign_recurring_type'
                        }
                    }
                    return row[realKey] && values.find( value => row[realKey] && row[realKey].toLowerCase().includes( value.toLowerCase() ))  
                     
                } )
            }

            return data
            
        },
        onFilterChange(filters){
            const key = Object.keys(filters)[0]
            this.columnFiltered[key] = filters[key][0] ? filters[key] : false
            this.parseData()
        },
        scheduledFiltersMap(temp){
            const tableData = temp
            const values = tableData.map(item => {
                if(item.campaign_type == 'schedule') 
                    return this.parseDateShort(item.campaign_date)

                return item.campaign_recurring_type
            })
            this.scheduledFilters =  [...new Set(values)].map(date=>{ return  { text: date, value: date } })

        },
        search(){
            const searchTerm = this.searchTerm
            const data = [...this.tblData].filter( obj =>
                
                Object.values(obj).some(value =>                    
                    typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    typeof value === 'number' && value.toString().includes(searchTerm)
                )
            )

            this.paginatedData = [...data].slice( ( this.value - 1 )  * this.perPage, this.value * this.perPage)
          
        },
        
        toggleCollapse(row) {
            this.$set(row, 'expanded', !row.expanded);
        },
        action(action, row){
            this.$emit('action', { action: action, camp: { campaign_id: row.campaign_id, id: row.id } })
        },
        parseReccuringMonth(numericMonth){
            return this.numericMonthToName[`${numericMonth}`].label
        },
        parseReccuringDay(data){
           return this.numericDayToName[data.campaign_day_of_week].label
        },
        parseDateShort(dateString, specific){
            if(!dateString) return
            const date = new Date(dateString)      
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getYear() + 1900
            if(specific == 'day') return day
            if(specific == 'month') return this.numericMonthToName[month].label
            return `${year}-${month.toString().padStart(2,0)}-${day.toString().padStart(2,0)}`
        },
    }
}
</script>
<style scoped>
.expanding-section {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
</style>